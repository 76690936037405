import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
    selector: 'app-video-player',
    templateUrl: './video-player.component.html',
    styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {
    @Input() public videoSrc: string;
    @Input() public youtubeUrl: string;
    public sanitizedYoutubeUrl: SafeResourceUrl;

    constructor(
        public modal: NgbActiveModal,
        private readonly sanitizer: DomSanitizer
    ) {}

    public ngOnInit(): void {
        if (this.youtubeUrl) {
            this.sanitizedYoutubeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.youtubeUrl);
        }
    }
}
