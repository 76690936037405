import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, map, startWith, tap } from 'rxjs';
import { GeneralQuery } from '@app-core/general-store/general.query';

@Component({
    selector: 'app-field-wrapper',
    templateUrl: './field-wrapper.component.html',
    styleUrls: ['./field-wrapper.component.scss']
})
export class FieldWrapperComponent extends FieldWrapper implements OnInit, OnDestroy {
    @ViewChild('fieldComponent', {read: ViewContainerRef, static: true}) public fieldComponent: ViewContainerRef;

    private readonly subscriptions = new Subscription();

    public imputed$: Observable<boolean>;
    public imputedPropertyName = '';
    public imputable = false;
    public imputedText: string;

    constructor(
        public translateService: TranslateService,
        private readonly generalQuery: GeneralQuery
    ) { 
        super();
    }

    public ngOnInit(): void {
        this.imputedPropertyName = `${(this.field.key as string).substring(0, (this.field.key as string).length - 5)}Imputed`;
        this.imputable = this.formControl.parent ? this.formControl.parent.value[this.imputedPropertyName] !== undefined : false;

        if (this.formControl.parent) {
            this.imputed$ = this.formControl.parent.valueChanges
                .pipe(
                    startWith(this.formControl.parent.value),
                    map(model => model[this.imputedPropertyName])
                );
        }

        this.setImputedText();

        this.subscriptions.add(
            this.translateService.onLangChange.pipe(
                tap(() => {
                    this.setImputedText();
                })
            ).subscribe()
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private setImputedText(): void {
        if (this.generalQuery.getFeatures().calculatedImputations) {
            this.imputedText = this.translateService.instant('FIELDS.IMPUTED_PERSONALIZED');
        }
        else {
            this.imputedText = this.translateService.instant('FIELDS.IMPUTED');
        }
    }
}
