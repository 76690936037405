import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { GeneralQuery } from '@app-core/general-store/general.query';
import { isAuthenticatedUser } from '@app-core/user-store/user.model';
import { UserQuery } from '@app-core/user-store/user.query';
import { ContactService } from '@app-features/contact/contact.service';
import { Site } from '@app-shared/models/site-configuration-enum';
import { combineQueries } from '@datorama/akita';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { ContactDto, ContactSubjects, GDPRTopic, SendStatus } from './contact.model';

interface ContactForm {
    name: FormControl<string>;
    emailAddress: FormControl<string>;
    confirmEmail: FormControl<string>;
    subject: FormControl<string>;
    gdprTopic: FormControl<string>;
    message: FormControl<string>;
}

@Component({
    selector: 'cvrm-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactComponent {
    public readonly site$: Observable<string> = this.generalQuery.selectSite();
    public status = new BehaviorSubject<SendStatus>(SendStatus.New);
    
    public Site = Site;
    public ContactSubjects = ContactSubjects;
    public GDPRTopic = GDPRTopic;
    public SendStatus = SendStatus;

    public readonly hideMedicalInquiriesChoice$ = combineQueries([
        this.userQuery.select(),
        this.generalQuery.selectFeatures()
    ]).pipe(
        map(([user, features]) => features.requiresAuthentication?.gracePeriod === true || isAuthenticatedUser(user.type)),
        map(show => !show)
    );

    public readonly hideGDPRInquiriesChoice$ = combineQueries([
        this.userQuery.select(),
        this.generalQuery.selectFeatures()
    ]).pipe(
        map(([user, features]) => features.requiresAuthentication != null && isAuthenticatedUser(user.type)),
        map(show => !show)
    );

    public showError = false;
    public contactForm = this.formBuilder.nonNullable.group<ContactForm>({
        name: this.formBuilder.nonNullable.control('', [Validators.required]),
        emailAddress: this.formBuilder.nonNullable.control('', [Validators.required, Validators.email]),
        confirmEmail: this.formBuilder.nonNullable.control('', [Validators.required, Validators.email, EmailValidator('emailAddress')]),
        subject: this.formBuilder.nonNullable.control('', [Validators.required]),
        gdprTopic: this.formBuilder.nonNullable.control(''),
        message: this.formBuilder.nonNullable.control('', [Validators.required])
    });

    public showGDPRTopic$ = this.contactForm.controls.subject.valueChanges.pipe(
        map(subject => subject === ContactSubjects.GDPRInquiries),
        tap(isGDPRInquiries => {
            if (isGDPRInquiries) {
                this.contactForm.controls.gdprTopic.setValidators([Validators.required]);
            } else {
                this.contactForm.controls.gdprTopic.removeValidators([Validators.required]);
            }
        })
    );

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly contactService: ContactService,
        private readonly userQuery: UserQuery,
        private readonly generalQuery: GeneralQuery
    ) { }

    public submit(): void {
        if (this.contactForm.status === 'INVALID') {
            this.showError = true;
        } else {
            const contactDto: Partial<ContactDto> = {
                ...this.contactForm.value,
                lang: this.userQuery.getLang(),
                url: window.location.href
            } ;
            
            this.status.next(SendStatus.Sending);
            this.contactService.send(contactDto).subscribe({
                next: () => this.status.next(SendStatus.Success),
                error: () => this.status.next(SendStatus.Error)
            });
        }
    }

    public errorKey(): string {
        const confirmError = Object.keys(this.contactForm.get('confirmEmail').errors || {})[0];

        return confirmError || (this.contactForm.status === 'INVALID' ? 'required' : null);
    }
}

const EmailValidator = (confirmEmailInput: string): any => {
    let confirmEmailControl: UntypedFormControl;
    let emailControl: UntypedFormControl;

    return (control: UntypedFormControl) => {
        if (!control.parent) {
            return null;
        }

        if (!confirmEmailControl) {
            confirmEmailControl = control;
            emailControl = control.parent.get(confirmEmailInput) as UntypedFormControl;
            emailControl.valueChanges.subscribe(() => {
                confirmEmailControl.updateValueAndValidity();
            });
        }

        if (emailControl.value.toLocaleLowerCase() !== confirmEmailControl.value.toLocaleLowerCase()) {
            return {
                notMatch: true,
            };
        }

        return null;
    };
};
