import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralQuery } from '@app-core/general-store/general.query';
import { isAuthenticatedUser } from '@app-core/user-store/user.model';
import { UserQuery } from '@app-core/user-store/user.query';
import { Calculator, HidableField, HttpError, Options } from '@app-features/calculators/calculator.model';
import { CalculatorQuery } from '@app-features/calculators/calculator.query';
import { CalculatorService } from '@app-features/calculators/calculator.service';
import { PatientDataField, PatientModel } from '@app-features/calculators/patient-data/patient-data.model';
import { PatientDataQuery } from '@app-features/calculators/patient-data/patient-data.query';
import { PatientDataService } from '@app-features/calculators/patient-data/patient-data.service';
import { NotificationComponent } from '@app-shared/components/error-notification/error-notification.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { BolNotificationService } from '@ortec/bolster/notification';
import { cache } from '@ortec/soca-web-ui';
import { Observable, combineLatest, debounceTime, delay, filter, finalize, first, map, switchMap, tap } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'cvrm-calculator-form',
    templateUrl: './calculator-form.component.html',
    styleUrls: ['./calculator-form.component.scss'],
})
export class CalculatorFormComponent implements OnInit, OnDestroy { 
    private calculatorName = '';
    private previousHidableFields: Array<HidableField> = null;
    private readonly calculatorName$: Observable<string> = this.route.params.pipe(
        map(params => params.calculator), cache());

    public lang$: Observable<string>;
    public calculator$: Observable<Calculator>;
    public form = new UntypedFormGroup({});
    public model: PatientModel = {};
    public options: FormlyFormOptions;
    public fields: Array<FormlyFieldConfig> = [];
    public isShowDescription = false;
    public isShowGenericInvalidMessage = false;
    public loadingData = false;
    public isShowInfo = false;

    public isPrefillFeatureOn$ = this.patientDataQuery.selectPrefillFeature();  
    public patientDataOverriden$: Observable<boolean>;

    constructor(
        private readonly calculatorService: CalculatorService,
        private readonly calculatorQuery: CalculatorQuery,
        private readonly patientDataService: PatientDataService,
        private readonly patientDataQuery: PatientDataQuery,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly translateService: TranslateService,
        private readonly bolNotificationService: BolNotificationService,
        private readonly userQuery: UserQuery,
        private readonly generalQuery: GeneralQuery
    ) { }

    @HostListener('window:click', ['$event']) public clickOutsideListener(): void {
        this.hideInfo();
    }
  
    public ngOnInit(): void {
        this.lang$ = this.userQuery.selectLang();

        this.calculator$ = this.calculatorName$.pipe(
            tap(calculatorName => {
                this.calculatorService.setActiveCalculator(calculatorName);
                this.calculatorName = calculatorName;

                // this is necessary to ensure that the form holds the correct controls when switching calculators
                const controlNames = Object.keys(this.form.controls);
                controlNames.forEach(x => this.form.removeControl(x));
            }),
            switchMap(() => this.calculatorQuery.selectActive()),
            tap(calculator => {
                if (calculator == null) {
                    this.router.navigateByUrl('/page-not-found');
                }
            }),
            filter(calculator => calculator != null),
            tap(calculator => {
                this.calculatorService.toggleFormDisable(false);
                this.fields = this.calculatorService
                    .createIntakeForm(calculator.fields)
                    .filter(filterGroup => filterGroup.key !== 'treatment');

                // unfreezing the model so the model can be altered via the form
                this.model = JSON.parse(JSON.stringify(calculator.model));

                // LEAVE THIS!!! without this, the evaluation of expressions will not work because the formState is missing
                this.options = {
                    formState: {
                        formModel: this.model,
                    },
                };
            }),
        );

        if (this.generalQuery.getFeatures().prefillPatientData &&
            this.patientDataQuery.hasPatientDataRequest()) {

            this.patientDataOverriden$ = this.calculator$.pipe(
                switchMap(calculator => this.patientDataQuery.selectDataForCalculator(calculator.id)),
                map(data => !this.dataContainsOverride(data))
            );

            this.loading(true);

            combineLatest([
                this.userQuery.selectUserType(),
                this.calculator$
            ]).pipe(
                filter(([userType, ]) => isAuthenticatedUser(userType)),
                first(),
                switchMap(([, calculator]) => this.patientDataService.getPatientData(calculator.id, this.fields).pipe(
                    finalize(() => this.loading(false))
                )),
                untilDestroyed(this)
            ).subscribe({
                next: patientModel => setTimeout(() => this.form.patchValue(patientModel), 0),
                error: error => this.handleDataLoadError(error)
            });

            this.form.valueChanges.pipe(
                delay(0),
                debounceTime(150),
                map(() => this.patientDataQuery.getDataForCalculator(this.calculatorName)),
                tap((patientDataFields) => {
                    const patchForm = (fieldsToPatch: Array<HidableField>): void => {
                        const model = getUpdatedModel(fieldsToPatch, patientDataFields, this.form.value);
                        this.form.patchValue(model);
                    };

                    setTimeout(() => {
                        this.previousHidableFields = patchHidableFields(
                            patientDataFields, this.fields, this.previousHidableFields, patchForm);
                    } , 0);
                }),
                tap((patientDataFields) => this.patientDataService.storePatientDataOverrides(this.calculatorName, this.form, patientDataFields)),
                untilDestroyed(this)
            ).subscribe();
        }
    }

    public ngOnDestroy(): void {
        const url = this.router.url;
        if (!url.includes('calculators/results')) {
            this.calculatorService.setActiveCalculator('');
        }
    }

    public submit(): void {
        this.validateMultipleChoiceOptions(this.fields);

        if (this.form.status === 'VALID') {
            this.calculatorService.setIntakeResult(
                { ...this.model },
                getHidableFields(this.fields)
            );
            this.router.navigate(['..', 'results', this.calculatorName], {
                relativeTo: this.route,
            }); 
        } else {
            this.isShowGenericInvalidMessage = true;
            this.scrollToFirstError();
        }
    }

    public toggleInfo(): void {
        const isShown = this.isShowInfo;
        setTimeout(() => {
            if(isShown) {
                this.hideInfo();
            } else {
                this.showInfo();
            }
        });
    }

    public showInfo(): void {
        this.isShowInfo = true;
    }

    public hideInfo(): void {
        this.isShowInfo = false;
    }

    public toDescription(event: Event): void {
        event.preventDefault();
        this.calculatorService.setIntakeResult(
            { ...this.model },
            getHidableFields(this.fields)
        );
        this.router.navigate(['calculators', 'description', this.calculatorName]);
    }

    public reloadOriginalData(): void {
        const patientModel = this.patientDataQuery.getOriginalPatientModel(this.calculatorName, this.fields);
        this.patchFormValue(patientModel);
    }

    public patchFormValue(patientModel: PatientModel): void {
        setTimeout(() => this.form.patchValue(patientModel), 0);
    }

    private loading(toggle: boolean): void {
        this.calculatorService.toggleFormDisable(toggle);
        this.loadingData = toggle;
    }

    private validateMultipleChoiceOptions(fields: Array<FormlyFieldConfig>): void {
        for (const categoryKey of Object.keys(this.form.controls)) {
            const category = this.form.controls[categoryKey] as FormGroup;

            for (const groupKey of Object.keys(category.controls)) {
                const key = groupKey.replace('Group', '');

                const props = fields
                    .find(x => x.key === categoryKey).fieldGroup
                    .find(x => x.key === groupKey).fieldGroup
                    .find(x => x.key === key + 'Field').fieldGroup
                    .find(x => x.key === key).props;

                const groupValue = ((this.form.value[categoryKey] || {})[groupKey] || {})[key + 'Field'];
                if (props.options != null && groupValue != null && typeof groupValue !== 'boolean') {
                    const fieldValue = groupValue[key];

                    // if field is a single select choice
                    if (fieldValue != null && !Array.isArray(fieldValue)) {
                        const optionValues = (props.options as Array<Options>).map(x => x.value);

                        if (!optionValues.includes(fieldValue)) {
                            groupValue[key] = null;
                            this.form.patchValue(this.form.value);
                        }
                    }
                }
            }
        }
    }

    private scrollToFirstError(): void {
        const errorFields = this.fields
            .reduce((fieldGroups, field) => {
                fieldGroups = fieldGroups.concat(...field.fieldGroup);

                return fieldGroups;
            }, [])
            .filter((field) => field.formControl.status === 'INVALID');

        if (errorFields.length > 0) {
            this.scroll(errorFields[0].key);
        }
    }
    
    private scroll(fieldGroupKey: string): void {
        const yOffset = 60; // nav bar height 56 + 4 for padding
        const y =
      document
          .querySelector('#' + this.getElementId(fieldGroupKey))
          .getBoundingClientRect().top + window.pageYOffset - yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
    }

    private getElementId(fieldGroupKey: string): string {
        return fieldGroupKey.replace('Group', '');
    }

    private dataContainsOverride(data: Record<string, PatientDataField>): boolean {
        const hidableFields = getHidableFields(this.fields);
        for (const [key, field] of Object.entries(data ?? {})) {
            const fieldIsHidden = hidableFields.find(f => f.id === key)?.hide;
            if (!fieldIsHidden && (field.overriddenValue !== undefined || field.overriddenUnit !== undefined || field.imputed)) return true;
        }

        return false;
    }

    private handleDataLoadError(httpErrorResponse: HttpErrorResponse): void {
        const error = httpErrorResponse.error as HttpError;
        const errorMessage = this.translateService.instant(
            'CALCULATORS.DATA_LOAD_ERROR'
        );

        const input = {
            component: NotificationComponent,
            data: { 
                PrimaryErrorMessage: errorMessage, 
                SecondaryErrorMessage: error.innerErrorMessage,
                StatusCode: error.status
            }
        };

        this.bolNotificationService.negative(input, 'error', '', 
            { 
                duration: 5000,
            });

        this.patientDataService.clearStore();
    }
}

export const patchHidableFields = (
    patientDataFields: Record<string, PatientDataField>,
    fields: Array<FormlyFieldConfig>,
    previousHidableFields: Array<HidableField>,
    patchForm: (fieldsToPatch: Array<HidableField>) => void): Array<HidableField> => {
    const hidableFields = getHidableFields(fields);
    const prevHidableFields = previousHidableFields ?? hidableFields.map(x => ({ id: x.id, hide: true }));
    
    const fieldsToPatch = getFieldsToPatch(hidableFields, prevHidableFields, patientDataFields);
    if (fieldsToPatch.length > 0) {
        patchForm(fieldsToPatch);
    }

    return hidableFields;
};

export const getHidableFields = (fields: Array<FormlyFieldConfig>): Array<HidableField> => {
    return fields
        .reduce((f, group) => f.concat(group.fieldGroup), [])
        .map((field) => {
            const { key, hide } = field;
            const id = key.substr(0, key.length - 'group'.length);

            return { id, hide };
        })
        .filter((field) => field.hide !== undefined);
};

export const getUpdatedModel = (
    fieldsToPatch: Array<HidableField>,
    patientDataFields: Record<string, PatientDataField>,
    model: PatientModel): PatientModel => {
    fieldsToPatch.forEach(field => {
        const categoryKey = Object.keys(model).find(x => Object.keys(model[x]).includes(`${field.id}Group`));
        const patientDataField = patientDataFields[field.id];
            
        const patientFields = model[categoryKey][`${field.id}Group`][`${field.id}Field`];

        if (typeof patientFields != 'boolean') {
            patientFields[field.id] = patientDataField.overriddenValue != null && patientDataField.overriddenValue !== ''
                ? patientDataField.overriddenValue
                : patientDataField.originalValue;

            if (patientDataFields[field.id].originalUnit != null) {
                patientFields[`${field.id}Unit`] = patientDataField.overriddenUnit ?? patientDataField.originalUnit;
            }
        }
    });

    return model;
};

const getFieldsToPatch = (
    hidableFields: Array<HidableField>, 
    previousHidableFields: Array<HidableField>, 
    patientDataFields: Record<string, PatientDataField>): Array<HidableField> => {
    return hidableFields
        .filter(field => isJustRevealed(previousHidableFields, field))
        .filter(field => patientDataFields[field.id] != null);
};

const isJustRevealed = (previousHiddenFields: Array<HidableField>, field: HidableField): boolean => {
    const isPreviouslyHidden = previousHiddenFields.find(x => x.id === field.id)?.hide ?? true;

    return isPreviouslyHidden && !field.hide;
};