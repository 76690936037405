<div class="fixed-container">
  <div class="error">
    <div class="text">
      <h2 translate>ERROR.SOMETHING_WENT_WRONG</h2>
      <h3 translate>ERROR.TRY_AGAIN_LATER</h3>
      <div class="button-container">
        <a (click)="refresh()" class="btn-cta" translate>ERROR.REFRESH_BUTTON</a>
      </div>
    </div>
    <div class="image">
      <img src="/assets/errorImage.jpg">
    </div>
  </div>
</div>