import { Subscription } from 'rxjs';

import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'validation-message',
    template: `{{ errorMessage }}`
})
export class ValidationMessageComponent implements OnInit, OnChanges, OnDestroy {
    @Input() public field: FormlyFieldConfig;
    @Input() public form: UntypedFormGroup | UntypedFormArray;

    private readonly subscriptions = new Subscription();
    private readonly messages: Record<string, string> = {
        required: 'VALIDATIONS.REQUIRED',
        min: 'VALIDATIONS.MIN',
        max: 'VALIDATIONS.MAX'
    };

    public errorMessage = '';

    constructor(
        private readonly translateService: TranslateService
    ) { }

    public ngOnInit(): void {
        this.subscriptions.add(
            this.translateService.onLangChange.subscribe(() => {
                this.setErrorMessage();
            })
        );
        this.subscriptions.add(
            this.field.formControl.valueChanges.subscribe(() => {
                this.setErrorMessage();
            })
        );

        this.form = this.form?.parent?.parent?.parent;
        this.subscriptions.add(
            this.form?.statusChanges?.subscribe((_x) => {
                // Immediately calling setErrorMessage() will cause the error to show with outdated data.
                // If we instead wait 100ms, it works correctly as the data is updated.
                setTimeout(() => this.setErrorMessage(), 100);
            })
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public ngOnChanges(): void {
        this.setErrorMessage();
    }

    private setErrorMessage(): void {
        const fieldForm = this.field.formControl;
        if (fieldForm.errors) {
            Object.entries(fieldForm.errors).forEach(([error, value]) => {
                const message = value.message ? `${value.message}` : (this.messages[error] || '-');
                this.errorMessage = this.translateService.instant(message, this.field.props);
            });
        }
    }
}
