import { Pipe, PipeTransform } from '@angular/core';
import { UserType, isAuthenticatedUser } from './user.model';

@Pipe({name: 'isAuthenticatedUser', standalone: true})
export class IsAuthenticatedUserPipe implements PipeTransform {
    public transform(userType: UserType): boolean {
        return isAuthenticatedUser(userType);
    }
}

@Pipe({name: 'isLoginUser', standalone: true})
export class IsLoginUserPipe implements PipeTransform {
    public transform(userType: UserType): boolean {
        return userType === UserType.Login;
    }
}

@Pipe({name: 'isConnectUser', standalone: true})
export class IsConnectUserPipe implements PipeTransform {
    public transform(userType: UserType): boolean {
        return userType === UserType.Connect;
    }
}

@Pipe({name: 'isVisitorUser', standalone: true})
export class IsVisitorUserPipe implements PipeTransform {
    public transform(userType: UserType): boolean {
        return userType === UserType.Visitor;
    }
}