import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableAkitaProdMode } from '@datorama/akita';

import { AppModule } from 'app/app.module';
import { environment } from 'environments/environment';
import { EnvironmentName } from 'environments/environment.config';

if (environment.name === EnvironmentName.Production) {
    enableProdMode();
    enableAkitaProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
