import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

export interface ContentState {
    nl: Record<string, Content>;
    en: Record<string, Content>;
    cookieConsent: boolean;
}

export interface Content {
    data?: SafeHtml;
    state: 'loaded' | 'loading' | 'not found';
}

@Injectable({
    providedIn: 'root'
})
@StoreConfig({name: 'content'})
export class ContentStore extends Store<ContentState> {
    constructor() {
        super({
            nl: {},
            en: {},
            cookieConsent: null,
        });
    }

    /**
     * The store contains rendered content that is rendered according to the
     * consequences of (not) having consent to display third party media.
     * If there is a change in given consent, the stored content became invalid
     * and should be cleared.
     */
    public clearContentWhenCookieConsentChanged(cookieConsent: boolean): void {
        this.update(state => {
            return state.cookieConsent === cookieConsent ? state : {
                cookieConsent,
                nl: {},
                en: {},
            };
        });
    }

    public updateContent(content: SafeHtml, document: string,  lang: string): void {
        this.update({[lang]: {[document]: {data: content, state: 'loaded'}}});
    }

    public updateLoading(document: string, lang: string): void {
        this.update({[lang]: {[document]: {state: 'loading'}}});
    }

    public updateNotFound(document: string, lang: string):  void {
        this.update({[lang]: {[document]: {state: 'not found'}}});
    }
}