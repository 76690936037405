<div class="form-check form-switch" [ngClass]="{'can-be-disabled': props.canBeDisabled}" id="{{field.key}}" [attr.data-cy]="field.key + 'Component'">
  <fa-icon icon="ban" *ngIf="props.canBeDisabled" (click)="props.onToggleDisable(props)"
    [ngClass]="{'input-disabled': props.disabled}" class="switch-disable-icon"></fa-icon>
  <input 
    type="checkbox" 
    role="switch" 
    class="form-check-input"
    [ngClass]="changedClass$ | async"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [class.is-invalid]="showError"
    [indeterminate]="props.indeterminate && formControl.value===null"
    [attr.data-cy]="field.key + 'Field'"
    [attr.data-cy-value]="this.formControl.value">

  <label class="form-check-label" [ngClass]="changedClass$ | async" [for]="id">
    <div [hidden]="props.hideSwitchLabel"> {{ props.label }}</div>
  </label>
</div>
<div *ngIf="showError" class="invalid-feedback">
  <validation-message [field]="field" [form]="form"></validation-message>
</div>