import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { registeredUserGuard } from '@app-core/guard/registered-user.guard copy';
import { visitorGuard } from '@app-core/guard/visitor.guard';
import { ContentModule } from '@app-features/content/content.module';
import { DisableComponent } from '@app-shared/components/disable-component/disable.component';
import { IconsModule } from '@app-shared/icons/icons.module';
import { SharedModule } from '@app-shared/shared.module';
import { BolNotificationModule } from '@ortec/bolster/notification';
import { NgChartsModule } from 'ng2-charts';
import { CalculatorAscvdComponent } from './calculator-ascvd/calculator-ascvd.component';
import { CalculatorAssistChoiceComponent } from './calculator-assist-choice/calculator-assist-choice.component';
import { CalculatorDescriptionComponent } from './calculator-description/calculator-description.component';
import { CalculatorFormComponent } from './calculator-form/calculator-form.component';
import { calculatorGuard } from './calculator-guards/calculator.guard';
import { CalculatorTermsComponent } from './calculator-terms/calculator-terms.component';
import { calculatorTermsGuard } from './calculator-terms/calculator-terms.guard';
import { CalculatorsOverviewComponent } from './calculators-overview/calculators-overview.component';
import { LabelTileComponent } from './calculators-overview/label-tile/label-tile.component';
import { SelectionTileComponent } from './calculators-overview/selection-tile/selection-tile.component';
import { CalculatorComponentsModule } from './components/calculator-components.module';
import { ExpectancyGraphComponent } from './components/expectancy-graph/expectancy-graph.component';
import { calculatorPatientDataGuard } from './patient-data/calculator-patient-data.guard';
import { ResultsComponent } from './results/results.component';

@NgModule({
    imports: [
        DisableComponent,
        BrowserModule,
        BrowserAnimationsModule,
        NgChartsModule,
        ContentModule,
        RouterModule.forChild([
            {
                path: 'calculators',
                // Always leave the CalculatorPatientDataGuard as the first guard. It always returns true but makes sure patient
                // data from the url is stored and not lost when processing other guards.
                canActivateChild: [calculatorPatientDataGuard, visitorGuard, registeredUserGuard, calculatorTermsGuard],
                children: [
                    {
                        path: '',
                        component: CalculatorsOverviewComponent,
                        data: {
                            title: 'NAV.CALCULATORS'
                        },
                        canActivate: [calculatorGuard]
                    },
                    {
                        // for navigation in the menu, redirect 'calculators/calculators' to 'calculators'
                        path: 'calculators',
                        redirectTo: '',
                        pathMatch: 'full'
                    },
                    {
                        path: 'assist',
                        component: CalculatorAssistChoiceComponent,
                        canActivate: [calculatorGuard]
                    },
                    {
                        path: 'ascvdScore',
                        component: CalculatorAscvdComponent,
                        canActivate: [calculatorGuard]
                    },
                    { path: ':calculator', component: CalculatorFormComponent },
                    { path: 'results/:calculator', component: ResultsComponent },
                    { path: 'description/:calculator', component: CalculatorDescriptionComponent },
                ]
            },
            {
                path: 'accept-terms',
                component: CalculatorTermsComponent
            },
            {
                path: 'view-terms',
                component: CalculatorTermsComponent
            },
            {   // Unclear why this is here, it is filtered out in app.initializer.ts mergeRouteConfigWithMenuConfig()
                path: 'manual',
                children: [
                    {
                        path: 'vtePredict',
                        redirectTo: '/calculators/description/vtePredict',
                        pathMatch: 'full',
                    },
                ],
            },
        ]),
        ReactiveFormsModule,
        CalculatorComponentsModule,
        SharedModule,
        IconsModule,
        MatProgressSpinnerModule,
        BolNotificationModule,
    ],
    providers: [DatePipe],
    declarations: [
        CalculatorAscvdComponent,
        CalculatorAssistChoiceComponent,
        CalculatorDescriptionComponent,
        CalculatorFormComponent,
        CalculatorsOverviewComponent,
        CalculatorTermsComponent,
        ExpectancyGraphComponent,
        ResultsComponent,
        SelectionTileComponent,
        LabelTileComponent,
    ]
})
export class CalculatorsModule {}
