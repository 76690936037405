import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { GeneralQuery } from '@app-core/general-store/general.query';
import { BaseRisk } from '@app-features/calculators/calculator.model';
import { ChartLabels, DEFAULT_CHART_OPTIONS, getMultilineText, updateBarData } from '@app-features/calculators/components/chart';
import { Site } from '@app-shared/models/site-configuration-enum';
import { TranslateService } from '@ngx-translate/core';
import { ChartConfiguration, ChartOptions, ChartType } from 'chart.js';
import { first } from 'rxjs';

export interface ResultTenYearsStatisticsLabels {
    currentRiskInfo: string;
    treatmentIncreaseInfo: string;
    treatmentReductionInfo: string;
}

@Component({
    selector: 'cvrm-result-ten-years-statistics',
    templateUrl: './result-ten-years-statistics.component.html',
    styleUrls: ['./result-ten-years-statistics.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResultTenYearsStatisticsComponent implements OnChanges {
    @Input() public risks: BaseRisk;
    @Input() public type: string;
    @Input() private readonly labels: ChartLabels = {};
    @Input() private readonly labelsOverrides : ResultTenYearsStatisticsLabels;

    private limitMaxXScale: number = 30; 
    
    public readonly chartType: ChartType = 'bar';

    public options: ChartOptions<'bar'> = {
        ...DEFAULT_CHART_OPTIONS,
        indexAxis: 'y',
        scales: {
            x: {
                beginAtZero: true,
                max: this.limitMaxXScale,
                stacked: true,
                title: {display: true, text: 'Percentage'},
            },
            y: { stacked: true },
        },
        plugins: {
            title: {
                display: true,
                text: '',
            },
            tooltip: { enabled: false }
        },
    };

    public data: ChartConfiguration<'bar'>['data'];
    public absNumbersNeededToTreat: string | number;
    public absTreatmentBenefit: number;
    public NNT_TITLE: string;
    public NNH_TITLE: string;
    public CURRENT_RISK_INFO: string;
    public TREATMENT_REDUCTION_INFO: string;
    public TREATMENT_BENEFIT_INFO: string;


    constructor(private readonly translateService: TranslateService, private readonly generalQuery: GeneralQuery) { 
        // This code needs to be refactored to have an Observable for treatment options. 
        // Once we have that, we can combine it with site$ and update data accordingly (anytime either of them updates).
        // For now, we will read the first value and keep using it. This works because the query is guaranteed in app.initializer.ts to have been done already.       
        this.generalQuery.selectSite().pipe(first()).subscribe(site => { 
            this.limitMaxXScale = site !== Site.VtePredict ? 30 : 50;            
        });
    }

    public ngOnChanges(): void { 
        this.NNT_TITLE = this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_TREAT_10');
        this.NNH_TITLE = this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_HARM_10');
        if (this.type === 'LIFETIME') {
            this.NNT_TITLE = this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_TREAT_LIFE');
            this.NNH_TITLE = this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_HARM_LIFE');
        } else if (this.type === 'TWO' || this.type === "TWO_SECONDARY") {
            this.NNT_TITLE = this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_TREAT_2');
            this.NNH_TITLE = this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_HARM_2');
        } else if (this.type === 'FIVE' || this.type === "FIVE_SECONDARY" || this.type === 'FIVE_HEARTFAILURE' || this.type === 'FIVE_VTE' || this.type === 'FIVE_BLEEDING') {
            this.NNT_TITLE = this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_TREAT_5');
            this.NNH_TITLE = this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_HARM_5');
        } else if (this.type === 'ONE_VTE' || this.type === 'ONE_BLEEDING') {
            this.NNT_TITLE = this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_TREAT_1');
            this.NNH_TITLE = this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_HARM_1');
        }

        if (this.risks) {
            if (this.labelsOverrides != null) {
                this.CURRENT_RISK_INFO = this.labelsOverrides.currentRiskInfo.replace('TYPE', this.type);
                this.TREATMENT_REDUCTION_INFO = (this.risks.treatmentBenefit >= 0 ? this.labelsOverrides.treatmentReductionInfo : this.labelsOverrides.treatmentIncreaseInfo).replace('TYPE', this.type);
            } else {
                this.CURRENT_RISK_INFO = `RESULTS.${this.type}_CURRENT_RISK_INFO`;
                this.TREATMENT_REDUCTION_INFO = `RESULTS.${this.type}_TREATMENT_${ this.risks.treatmentBenefit >= 0 ? 'REDUCTION' : 'INCREASE'}_INFO`;
            }
            this.TREATMENT_BENEFIT_INFO = `RESULTS.${this.type}_TREATMENT_${this.risks.treatmentBenefit >= 0 ? 'NNT' : 'NNH'}_INFO`;
            

            const newOptions = JSON.parse(JSON.stringify(this.options)) as ChartOptions<'bar'>;
            newOptions.scales.x.max = this.getMaxXScale(this.risks.risk, this.risks.riskWithTreatment);
            newOptions.plugins.title.text = getMultilineText(this.labels.title);
            this.options = newOptions;

            this.data = updateBarData(this.data, this.risks);

            this.absNumbersNeededToTreat = Math.abs(this.risks.treatmentBenefitFactor);
            this.absTreatmentBenefit = Math.abs(this.risks.treatmentBenefit);
        }
    }

    private getMaxXScale(risk: number, riskWithTreatment: number): number {
        const totalRisk = Math.max(risk, riskWithTreatment);
        return Math.max(Math.floor(totalRisk / 10) * 10 + (totalRisk % 10 > 0 ? 10 : 0), this.limitMaxXScale);
    }
}