<div class="flex">
  <div class="flex-item">
    <div>
      <canvas class="large-chart" 
        baseChart 
        [type]="type" 
        [data]="data"
        [options]="options" 
        [legend]="false">
      </canvas>
    </div>
    <div fxLayout="row" class="statistics">
      <div class="statistics__box">
        <div class="value" data-cy="statisticsAgeTreatmentStartValue">{{ improvement.ageTreatmentStart }}</div>
        <div class="label"><span translate data-cy="statisticsAgeTreatmentStartLabel">CALCULATORS.AGE_TREATMENT_START</span>
          <cvrm-more-info text="RESULTS.START_AGE_INFO"></cvrm-more-info>
        </div>
      </div>
      <div class="statistics__box">
        <div class="value" data-cy="statisticsAgeExpectancyDiseaseFreeValue">{{ improvement.diseaseFreeAge }}</div>
        <div class="label">
          <span translate data-cy="statisticsAgeExpectancyDiseaseFreeLabel">{{ AGE_EXPECTANCY_DISEASE_FREE }}</span>
          <cvrm-more-info [text]="CVD_FREE_LIFE_EXPECTANCY_INFO"></cvrm-more-info>
        </div>
      </div>
      <div class="statistics__box">
        <div class="value" data-cy="statisticsAdditionalYearsValue"
          [ngClass]="{addition: improvement.lifetimeBenefit > 0, reduced: improvement.lifetimeBenefit < 0}">
          {{improvement.lifetimeBenefit }}</div>
        <div class="label">
          <span [translate]="ADDITIONAL_YEARS_TITLE" data-cy="statisticsAdditionalYearsLabel"></span>
          <cvrm-more-info [text]="ADDITIONAL_YEARS_INFO"></cvrm-more-info>
        </div>
      </div>
    </div>
    
    <div class="warning" *ngIf="improvement.inaccurateResults">
      <fa-icon icon="exclamation-triangle"></fa-icon>
      <span translate>RESULTS.WARNING_LIFE_EXPECTANCY</span>
      <a routerLink="/faq" fragment="warning" translate>RESULTS.WARNING_LIFE_EXPECTANCY_FAQ</a>
    </div>
  </div>
</div>
