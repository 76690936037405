import { inject } from "@angular/core";
import { CanActivateChildFn, Router } from "@angular/router";
import { GeneralQuery } from "@app-core/general-store/general.query";
import { map } from "rxjs";

export const isDefaultSite: CanActivateChildFn = () => {
    const router = inject(Router);
    return inject(GeneralQuery).selectFeatures().pipe(
        map(features => features?.requiresAuthentication),
        map(requireAuth => requireAuth != null ? true : router.createUrlTree(['/']))
    );
};