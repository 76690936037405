import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgChartsModule } from 'ng2-charts';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { SharedModule } from '@app-shared/shared.module';
import { RouterModule } from '@angular/router';
import { SituationTableComponent } from '@app-features/calculators/components/situation-table/situation-table.component';
import { SituationTablePrintComponent } from '@app-features/calculators/components/situation-table-print/situation-table-print.component';
import { IntendedTreatmentFormComponent } from '@app-features/calculators/components/intended-treatment-form/intended-treatment-form.component';
import { CurrentSituationOverviewComponent } from '@app-features/calculators/components/current-situation-overview/current-situation-overview.component';
import { ResultTenYearsStatisticsComponent } from '@app-features/calculators/components/result-ten-years-statistics/result-ten-years-statistics.component';
import { DiseaseRiskGraphComponent } from '@app-features/calculators/components/disease-risk-graph/disease-risk-graph.component';
import { RiskRangeComponent } from '@app-features/calculators/components/risk-range/risk-range.component';
import { CopyResultComponent } from '@app-features/calculators/components/copy-result/copy-result.component';
import { SnackbarComponent } from '@app-features/calculators/components/snackbar/snackbar.component';
import { MoreInfoComponent } from '@app-features/calculators/components/more-info/more-info.component';
import { TestPageComponent } from '@app-features/calculators/components/test-page/test-page.component';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,

        NgChartsModule,
        ReactiveFormsModule,
        FormlyModule.forRoot({
            extras: {
                checkExpressionOn: 'changeDetectionCheck',
                lazyRender: false,
                resetFieldOnHide: false
            },
        }),
        FormlyBootstrapModule,
        SharedModule,
        RouterModule.forChild([{
            path: 'chartjs-test-page',
            component: TestPageComponent,
        }]),
    ],
    declarations: [
        CopyResultComponent,
        CurrentSituationOverviewComponent,
        DiseaseRiskGraphComponent,
        IntendedTreatmentFormComponent,
        MoreInfoComponent,
        ResultTenYearsStatisticsComponent,
        RiskRangeComponent,
        SituationTableComponent,
        SituationTablePrintComponent,
        SnackbarComponent,
        TestPageComponent,
    ],
    exports: [
        CopyResultComponent,
        CurrentSituationOverviewComponent,
        DiseaseRiskGraphComponent,
        IntendedTreatmentFormComponent,
        MoreInfoComponent,
        ResultTenYearsStatisticsComponent,
        RiskRangeComponent,
        SituationTableComponent,
        SituationTablePrintComponent,
        SnackbarComponent,
    ]
})
export class CalculatorComponentsModule { }
