<div>
  <canvas class="small-chart" 
    baseChart 
    type="bar" 
    [data]="horizontalData"
    [options]="horizontalOptions" 
    [legend]="false"
  ></canvas>
</div>
<div>
  <canvas class="small-chart" 
    baseChart 
    type="bar" 
    [data]="mortalityData"
    [options]="horizontalOptions" 
    [legend]="false"
  ></canvas>
</div>
<div>
  <canvas class="small-chart" 
    baseChart 
    type="line" 
    [data]="lineChartData"
    [options]="lineChartOptions" 
    [legend]="false"
  ></canvas>
</div>
<button (click)="change()">verander</button>
