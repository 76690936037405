<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<cvrm-page-wave-background color="blue">
	<div class="contact">
		<h1 translate>CONTACT.TITLE</h1>
		<h2 translate>
			<ng-container *ngIf="(site$ | async) as site">
				<ng-container [ngSwitch]="site">
					<ng-container *ngSwitchCase="Site.Default">
						CONTACT.SUB_TITLE
					</ng-container>
					<ng-container *ngSwitchCase="Site.VtePredict">
						CONTACT.VTE_PREDICT_SUB_TITLE
					</ng-container>
					<ng-container *ngSwitchCase="Site.LifeHf">
						CONTACT.LIFE_HF_SUB_TITLE
					</ng-container>
				</ng-container>
			</ng-container>
		</h2>
	
		<div [ngSwitch]="status | async">
			<form [formGroup]="contactForm" (submit)="submit()" *ngSwitchCase="SendStatus.New">
				<div>
					<label translate>CONTACT.NAME</label>
					<input formControlName="name" [placeholder]="'CONTACT.NAME' | translate" />
				</div>
				<div>
					<label translate>CONTACT.EMAIL</label>
					<input type="email" formControlName="emailAddress" [placeholder]="'CONTACT.EMAIL' | translate" />
				</div>
				<div>
					<label translate>CONTACT.CONFIRM_EMAIL</label>
					<input type="email" formControlName="confirmEmail" [placeholder]="'CONTACT.CONFIRM_EMAIL' | translate" />
				</div>
				<div>
					<label translate>CONTACT.SUBJECT</label>
					<select name="subject" formControlName="subject" id="subject">
						<option value="" disabled selected hidden translate>CONTACT.SUBJECT</option> 
						<ng-container *ngIf="(site$ | async) !== Site.VtePredict; else vte_predict">           
							<option [value]="ContactSubjects.MedicalInquiries" [hidden]="hideMedicalInquiriesChoice$ | async" translate>CONTACT.SUBJECTS.MEDICAL</option>
							<option [value]="ContactSubjects.CommercialInquiry" translate>CONTACT.SUBJECTS.COMMERCIAL</option>
							<option [value]="ContactSubjects.GDPRInquiries" [hidden]="hideGDPRInquiriesChoice$ | async" translate>CONTACT.SUBJECTS.GDPR</option>
							<option [value]="ContactSubjects.OtherQuestions" translate>CONTACT.SUBJECTS.OTHER</option>
						</ng-container>
						<ng-template #vte_predict>
							<option [value]="ContactSubjects.VTEPredict" translate>CONTACT.SUBJECTS.VTE_PREDICT</option>
						</ng-template>
					</select>
				</div>
				<div *ngIf="showGDPRTopic$ | async">
					<label translate>CONTACT.GDPR</label>
					<select name="gdprTopic" formControlName="gdprTopic" id="gdprTopic">
						<option value="" disabled selected hidden translate>CONTACT.GDPR</option>
						<option [value]="GDPRTopic.CorrectMyData" translate>CONTACT.GDPR_SUBJECTS.CORRECT</option>
						<option [value]="GDPRTopic.DeleteMyData" translate>CONTACT.GDPR_SUBJECTS.DELETE</option>
						<option [value]="GDPRTopic.FreezeMyData" translate>CONTACT.GDPR_SUBJECTS.FREEZE</option>
						<option [value]="GDPRTopic.ExportMyData" translate>CONTACT.GDPR_SUBJECTS.EXPORT</option>
						<option [value]="GDPRTopic.StopProcessingMyData" translate>CONTACT.GDPR_SUBJECTS.STOP</option>
						<option [value]="GDPRTopic.OtherMatters" translate>CONTACT.GDPR_SUBJECTS.OTHER</option>
					</select>
				</div>
				<div>
					<label translate>CONTACT.MESSAGE</label>
					<textarea formControlName="message" [placeholder]="'CONTACT.MESSAGE_PLACEHOLDER' | translate"></textarea>
				</div>
				<div *ngIf="showError && contactForm.status === 'INVALID'" [ngSwitch]="errorKey()" class="validation">
					<span *ngSwitchCase="'required'" translate>CONTACT.REQUIRED_VALIDATION</span>
					<span *ngSwitchCase="'email'" translate>CONTACT.EMAIL_VALIDATION</span>
					<span *ngSwitchCase="'notMatch'" translate>CONTACT.EMAIL_MATCH_VALIDATION</span>
				</div>
				<div>
					<button type="submit" class="btn" translate>CONTACT.SUBMIT</button>
				</div>
			</form>
			<section class="message">
				<div *ngSwitchCase="SendStatus.Sending">
					<span translate>CONTACT.SENDING</span>
				</div>
				<div *ngSwitchCase="SendStatus.Success">
					<span translate>CONTACT.SUCCESS</span>
				</div>
				<div *ngSwitchCase="SendStatus.Error">
					<span translate>CONTACT.ERROR</span>
				</div>
			</section>
			
		</div>
	</div>

	
</cvrm-page-wave-background>
<cvrm-footer-wave-background color="blue"></cvrm-footer-wave-background>