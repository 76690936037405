import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subscription, Observable, debounceTime, tap, map } from 'rxjs';
import { AbstractControl } from '@angular/forms';

const SUFFIX = 'Future';

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss']
})
export class SelectComponent extends FieldType implements OnInit, OnDestroy {
    public defaultOptions = {
        props: { options: [] as Array<unknown> },
    };
    public originalValue: string = null;
    public subscriptions = new Subscription();
    public changedClass$: Observable<string>;

    public ngOnInit(): void {
        if (this.formControl.value === undefined) {
            this.formControl.setValue(null);
        }
        this.originalValue = this.formControl.value;

        this.subscriptions.add(this.getParentValueChange(this.formControl)
            .pipe(
                debounceTime(100),
                tap(() => {
                    const options = this.props.options as Array<any>;
                    if (!options.map(x => x.value).includes(this.formControl.value) && this.formControl.value !== null) {
                        this.formControl.setValue(null);
                    }

                    // formly sometimes fails to correctly update the (displayed) selected option when parent value changes
                    // e.g. upon clicking the reset button in Future treatment section (BUG 38242)
                    // here we help it a bit
                    this.formControl.setValue(this.formControl.value, { emitEvent: false });
                })
            ).subscribe());

        this.changedClass$ = this.formControl
            .valueChanges
            .pipe(map(() => (this.field.key as string).endsWith(SUFFIX) && this.originalValue !== this.formControl.value ? 'changed' : ''));
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public getOptionClass(value: string): string {
        if ((this.field.key as string).endsWith(SUFFIX)) {
            return this.originalValue === value ? 'original' : 'changed';
        }

        return '';
    }

    public showNullOption(): boolean {
        const options = this.props.options as Array<any>;

        return this.formControl.value === null && !options.find(option => option.value === null);
    }

    private getParentValueChange(control: AbstractControl): Observable<unknown> {
        return control.parent ? this.getParentValueChange(control.parent) : control.valueChanges;
    }
}
