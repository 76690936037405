<ng-container [ngSwitch]="lang">
    <div *ngSwitchCase="Lang.Dutch" class="home-banner dutch">
        <h3>Welkom op de U-Prevent site ontwikkeld door ORTEC</h3>
        <p>Geachte bezoeker, </p>
        <p>
            De U-Prevent-site is opnieuw gebouwd en geoptimaliseerd door ORTEC voor persoonlijk cardiovasculair risicobeheer. 
            De site is <a routerLink="/about/mdr">CE-gemarkeerd</a> volgens de MDD en de MDR-certificering wordt voorbereid. De eerste 
            pilot-site werd gebouwd door UMC Utrecht. Momenteel is het gebruik van de calculatoren op onze site gratis.
            Het ontwikkelen en onderhouden van een MDR-gecertificeerd hulpmiddel voor klinische beslissingsondersteuning brengt aanzienlijke kosten met zich mee.
            Om de duurzaamheid ervan te garanderen, ontwikkelt het U-Prevent-team een U-Prevent Premium-versie, waarvoor kosten in rekening zullen worden gebracht.
        </p>
        <p>
            Het U-Prevent team ontwikkelt extra <a routerLink="/about/services">diensten</a> voor betalende gebruikers door
            optimalisatie van datamanagement.
            Een van onze projecten is medische-data-uitwisseling volgens de
            <a href="https://eur-lex.europa.eu/legal-content/NL/TXT/HTML/?uri=CELEX:32016R0679&from=EN"
                target="_blank">AVG</a> en <a href="https://www.go-fair.org/fair-principles/" target="_blank">FAIR</a>.
            Hiermee wordt het mogelijk om U-Prevent geautoriseerd op te starten met data vanuit het patiënten
            informatiesysteem. Voor meer informatie, neem <a routerLink="/contact">contact</a> op.
        </p>
        <p>
            Wij wijzen u er graag op dat ORTEC ook een App ontwikkeld heeft voor de persoonlijke berekening van het cardiovasculaire risico.
            Ga naar onze <a routerLink="/about/use-ortec-app">App</a> pagina voor meer informatie.
        </p>
    
        <p><a routerLink="/about/u-prevent">Het U-Prevent team.</a></p>
    </div>

    <div *ngSwitchDefault class="home-banner english">
        <h3>Welcome to the U-Prevent site powered by ORTEC</h3>
        <p>Dear visitor, </p>
        <p>
            The U-Prevent site has been recreated and optimized by ORTEC for personalized cardiovascular risk management. 
            The site is <a routerLink="/about/mdr">CE marked</a> according to the MDD and marking for MDR compliancy is in progress. 
            The pilot site was built by UMC Utrecht. Currently, the calculators on our site are free to use.
            Development and maintenance of an MDR-certified clinical decision support tool entails significant costs.
            To ensure its sustainability, the U-Prevent team is developing a U-Prevent Premium version, for which a fee will apply.
        </p>
        <p>
            The U-Prevent team develops extra <a routerLink="/about/services">services</a> for premium users, optimization of data
            management, e.g. by <a href="https://www.go-fair.org/fair-principles/" target="_blank">FAIR</a> and
            <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32016R0679&from=EN"
                target="_blank">GDPR</a>-compliant data exchange from electronic health records. This will allow to initiate
            U-Prevent in an authorized way with data from a patient information system. Please <a routerLink="/contact">contact
                us</a> for more information.
        </p>
        <p>
            We would like to inform you that ORTEC has also an App developed for personalized cardiovascular risk management. 
            Have a look at our <a routerLink="/about/use-ortec-app">App</a> page for more information.
        </p>
    
        <p><a routerLink="/about/u-prevent">The U-Prevent team.</a></p>
    </div>
</ng-container>