<div id="{{field.key}}" class="button-select">
    <div #overflowGroup class="button-select__group button-select__overflow-check">
      <label *ngFor="let option of props.options" class="button-select__option">
        <div class="button-select__text" [translate]="option.label"></div>
      </label>
    </div>
  <div class="button-select__group" [ngClass]="{'button-select__group--small': overflowing$ | async}" [formGroup]="internalFields">
    <label
      *ngFor="let option of props.options; let i = index;"
      class="button-select__option"
      [ngClass]="{'button-select__option--small': overflowing$ | async, 'button-select__option--wide': (overflowing$ | async) === false}">
      <input type="radio"
             *ngIf="!isMultiSelect"
             [value]="option.id"
             [name]="field.key"
             [formControl]="formControl"
             [formlyAttributes]="field"
             [class.is-invalid]="showError"
             class="button-select__radio"/>

      <input type="checkbox"
             *ngIf="isMultiSelect"
             [id]="field.key + id + '_' + i"
             [formControlName]="option.value"
             [formlyAttributes]="field"
             [value]="option.id"
             class="button-select__checkbox">
      <div class="button-select__text" [ngClass]="option.cssClassName" [attr.data-cy]="field.key + option.value + 'Label'" [translate]="option.label"></div>
    </label>
  </div>
  <div *ngIf="showError" class="invalid-feedback">
    <validation-message [field]="field"></validation-message>
  </div>
</div>