<div class="pin-top">
	<header class="header" data-cy="header">
		<span class="sidebar-toggle">
			<button (click)="toggleSideBar()" class="sidebar-toggle__button">
			<fa-icon icon="bars"></fa-icon>
			</button>
		</span>
	
		<span class="header__title">{{pageTitle}}</span>
		
		<div class="header__logo">
			<ng-container [ngSwitch]="site">
                <a *ngSwitchCase="Site.Default" routerLink="/">
                    <img class="header__logo-image" src="/assets/logo_uprevent_green2.png"/>
                </a>
                <a *ngSwitchCase="Site.VtePredict" routerLink="/">
                    <img class="header__logo-image" src="/assets/VTEpredict.png"/>
                </a>
				<a *ngSwitchCase="Site.LifeHf" routerLink="/">
                    <img class="header__logo-image" src="/assets/logo-lifehf.png"/>
                </a>
				<a *ngSwitchCase="Site.Unknown">
                    <img class="header__logo-image" src="/assets/logo_uprevent_green2.png"/>
                </a>
            </ng-container>
		</div>
		
		<div class="header__menu" [ngClass]="{'header__menu--opened': opened}">
			<ul class="header__menu-list" *ngIf="(menu$ | async) as menu">
				<li class="header__menu-item header__menu-home">
					<div *ngIf="!isClosedTab()" [routerLink]="['/']" routerLinkActive="header__menu-item--active"
						[routerLinkActiveOptions]="{exact: showLifeHfStartPage}" (click)="toggleSideBar()">
					<span [translate]="'NAV.HOME'"></span>
					</div>
				</li>
				<li *ngFor="let item of menu" class="header__menu-item"
					[ngClass]="{'header__menu-item--active-border': isRouteActive(item.path)}">
					<div *ngIf="!item.children && !isClosedTab()" [routerLink]="item.path" routerLinkActive="header__menu-item--active"
						(click)="toggleSideBar()" [ngClass]="{'no-click': showLifeHfStartPage}">
					<span [translate]="item.data.label" ></span>
					</div>
					<div *ngIf="item.children && !isClosedTab()" (click)="toggleSubMenu($event, item.data.label)" [attr.data-cy]="'header_' + item.data.label"
						[ngClass]="{'header__menu-item--active': isRouteActive(item.path), 'no-click': showLifeHfStartPage}">
					<span [translate]="item.data.label"></span>
					<ul *ngIf="item.data.label === activeLabel" class="header__sub-menu">
						<li *ngFor="let child of item.children" class="header__sub-menu-item" [ngClass]="{'no-click': showLifeHfStartPage}"
							[routerLink]="[item.path, child.path]" (click)="toggleSideBar()"
							routerLinkActive="header__menu-item--active" [attr.data-cy]="'header_item_' + child.data.label">
						<span [translate]="child.data.label"></span>
						</li>
					</ul>
					</div>
				</li>
			</ul>
		</div>

		<ng-container *ngIf="(user.type | isLoginUser)">
			<div class="main-header-container">
				<button mat-button [matMenuTriggerFor]="userMenu" menuButtonHighlight data-cy="userProfileMenu" class="user-menu-button menu-button">
					<div data-cy="userName" class="name-holder">
						{{ user.professionalFullName ?? user.email }}
					</div>
					<span class="material-icons"> expand_more </span>
					<span class="material-icons avatar-container"> account_circle </span>
				</button>
		
				<mat-menu #userMenu="matMenu" backdropClass="user-menu" xPosition="before">
					<a class="menu-button" [routerLink]="['user-profile']">
						<button data-cy="goToUserProfile" mat-menu-item translate="USER_PROFILE.PROFILE"></button>
					</a>
					<button data-cy="logOut" mat-menu-item (click)="logOut()"><span translate>USER_PROFILE.LOG_OUT</span></button>
				</mat-menu>
			</div>
		</ng-container>
	
		<ng-container *ngIf="(user.type | isConnectUser)">
			<div class="main-header-container">
				<button mat-button data-cy="userProfileMenu" class="user-menu-button menu-button">
					<div data-cy="userName" class="name-holder">
						{{ user.organizationName }}
					</div>
					<span class="material-icons avatar-container"> account_circle </span>
				</button>
			</div>
		</ng-container>

		<div class="header__language-switch">
			<button mat-button class="header__button" menuButtonHighlight [matMenuTriggerFor]="menu" data-cy="languageSwitch">
				{{ user.preferredLanguage | uppercase}}
			</button>
	
			<mat-menu #menu="matMenu" class="bol-menu">
				<button mat-menu-item *ngFor="let lang of availableLanguages" 
					[attr.data-cy]="'languageSwitch' + (lang | uppercase)"
					(click)="onSelectLanguage(lang)"
					[innerHtml]="lang | uppercase">
				</button>
			</mat-menu>
		</div>

		<div *ngIf="opened" class="page-cover" (click)="toggleSideBar()"></div>
	</header>

	<div class="call-out">
		<bol-callout *ngIf="!isMdrCertified"
			type="error" icon="warning" class="call-out__warning"> 
			{{'NOT_MDR_CERTIFIED_WARNING' | translate}}
		</bol-callout>
	</div>
</div>