import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralQuery } from '@app-core/general-store/general.query';
import { UserService } from '@app-core/user-store/user.service';
import { CalculatorService } from '@app-features/calculators/calculator.service';
import { CalculatorStore } from '@app-features/calculators/calculator.store';
import { BroadcastService } from '@ortec/soca-web-ui';
import { first } from 'rxjs';
import { AuthorizationService, BroadcastTypes } from './authorization.service';
import { IdleTimeoutService } from './idle-timeout.service';

@Injectable({
    providedIn: 'root'
})
export class TabsService {

    constructor(
        private readonly authorizationService: AuthorizationService,
        private readonly broadcastService: BroadcastService,
        private readonly calculatorService: CalculatorService,
        private readonly calculatorStore: CalculatorStore,
        private readonly userService: UserService,
        private readonly router: Router,
        private readonly generalQuery: GeneralQuery,
        private readonly idleTimeoutService: IdleTimeoutService
    ) { }

    public closeCurrentTabIfAlsoOnResultPage(): void {
        if (this.authorizationService.isAuthenticated() &&
            this.isResultsUrl() &&
            this.generalQuery.getFeatures().preventMultipleTabs) {
                
            this.calculatorService.setActiveCalculator('');
            this.router.navigateByUrl('/tab-closed');
        }
    }

    public broadcastResultPageOpened(): void {
        if (this.isResultsUrl() && this.generalQuery.getFeatures().preventMultipleTabs) {
            this.broadcastService.publish({ type: BroadcastTypes.ResultTabOpen, payload: '' });
        }
    }

    public authenticateUser(): void {        
        this.userService.getLoginUser().pipe(
            first()
        ).subscribe({
            next: user => this.userService.updateUserToLogin(user),
            error: () => window.location.reload()
        });

        // to improve user experience when logging in user to all tabs 
        // we also reroute tabs that are on the logout page back to homepage 
        if (window.location.pathname === '/logout') {
            this.router.navigateByUrl('/');
        }
    }

    public rescheduleRefreshToken(): void {
        this.authorizationService.tokenWasRefreshedInOtherTab();
    }

    public tokenIsRefreshing(): void { 
        this.authorizationService.tokenIsRefreshingInOtherTab();
    }

    public endUserSession(): void {
        this.idleTimeoutService.stop();
        this.userService.updateUserToVisitor();
        this.authorizationService.clearUserSession();
        this.router.navigateByUrl('/logout');
    }

    public isResultsUrl(): boolean {
        const urlWithoutParms = this.router.url.split('?')[0];
        const urlSegments = urlWithoutParms.split('/').filter(segment => segment);
        const availableCalculators = this.calculatorStore.getValue().ids;
        const isResultsUrl = urlSegments[0] === 'calculators' && urlSegments[1] === 'results' &&  availableCalculators.includes(urlSegments[2]);

        return isResultsUrl;
    }

    public isCalculatorUrl(): boolean {
        const urlWithoutParms = this.router.url.split('?')[0];
        const urlSegments = urlWithoutParms.split('/').filter(segment => segment);
        const availableCalculators = this.calculatorStore.getValue().ids;
        const isCalculatorFormUrl = urlSegments[0] === 'calculators' && availableCalculators.includes(urlSegments[1]);

        return isCalculatorFormUrl;
    }
}