import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { UserQuery } from '@app-core/user-store/user.query';
import { CalculatorService } from '@app-features/calculators/calculator.service';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'cvrm-calculator-ascvd',
    templateUrl: './calculator-ascvd.component.html',
    styleUrls: ['./calculator-ascvd.component.scss']
})
export class CalculatorAscvdComponent implements OnInit, OnDestroy {
    private langSubscription: Subscription;
    private readonly formDefinition = [{
        id: 'region',
        type: 'select',
        label: 'FIELDS.GEOGRAPHIC_REGION',
        category: 'choice',
        options: [
            {
                label: 'FIELDS.GEO_EUROPE_LOW_RISK',
                value: 'scoreEULR'
            },
            {
                label: 'FIELDS.GEO_EUROPE_HIGH_RISK',
                value: 'scoreEUHR'
            },
            {
                label: 'FIELDS.GEO_NL',
                value: 'scoreNL'
            },
            {
                label: 'FIELDS.GEO_NORTH_AMERICA',
                value: 'ascvd'
            },
        ]
    }];
	
    public form = new UntypedFormGroup({});
    public model = {};
    public options: FormlyFormOptions;
    public fields: Array<FormlyFieldConfig> = [];
    public calculator = {
        color: 'teal',
        title: ''
    };

    constructor(
        private readonly calculatorService: CalculatorService,
        private readonly userQuery: UserQuery,
        private readonly router: Router,
    ) { }

    public ngOnInit(): void {
        this.calculatorService.toggleFormDisable(false);
        this.langSubscription = this.userQuery.selectLang().subscribe(() => {
            this.fields = this.calculatorService.createIntakeForm(this.formDefinition);
        });

    }

    public ngOnDestroy(): void {
        this.langSubscription.unsubscribe();
    }

    public submit(model: any): void {
        if (this.formDefinition[0].options
            .map(option => option.value)
            .find(value => model.choice.regionGroup.regionField.region === value)) {
            this.router.navigate([`/calculators/${model.choice.regionGroup.regionField.region}`]);
        }
    }

    public toDescription(event: Event): void {
        event.preventDefault();
        this.router.navigate(['calculators', 'description', 'ascvdScore']);
    }
}
