import { TranslateService } from '@ngx-translate/core';
import { ValueIndicator } from '@app-core/smart-risk-score/models';
import { Calculator, FieldConfig } from '@app-features/calculators/calculator.model';
import { Value } from '@app-features/calculators//patient-data/patient-data.model';

const categoryBlackList = ['treatment'];
const typeBlackList = ['unitSelector', 'metaSelector', 'hidden'];

export type Patient = Array<Array<Array<IntakeFieldConfig>>>;

export interface IntakeFieldConfig extends FieldConfig {
    name: string | Array<string>;
    value?: Value;
    unit?: string; 
    shortLabel?: string;
    imputed?: boolean;
    imputedLowerBound?: number;
    imputedUpperBound?: number;
    valueIndicator?: ValueIndicator;
}

export class FormattedIntake {
    private readonly columnCount = 3;
    private readonly initialColumns = new Array(this.columnCount).fill('').map(() => []);
    private readonly dynamicHiddenFields: Record<string, boolean>;
    private readonly measureResults: Array<FieldConfig> = [];
    private readonly model;
    private readonly imputations;
    private readonly normalRanges;
    private readonly translateService;

    constructor(
        state: Calculator,
        translateService: TranslateService) {
        this.measureResults = state.fields;
        this.model = state.model;
        this.imputations = state.imputations || [];
        this.normalRanges = state.normalRanges;
        this.translateService = translateService;
        this.dynamicHiddenFields = (state.hiddenFields || []).reduce((fieldMap: Record<string, boolean>, field) => {
            fieldMap[field.id] = field.hide;

            return fieldMap;
        }, {});
    }

    public getColumnResults(): Patient {
        return this.measureResults
            .filter(this.unallowedFields)
            .map(this.setTranslatedLabels)
            .map(this.setValues)
            .map(this.setImputations)
            .map(this.setUnits)
            .map(this.setRanges)
            .reduce(groupFieldsByCategory, [])
            .reduce(this.divideGroupsOverColumns, this.initialColumns);
    }

    private readonly unallowedFields = (field: FieldConfig): boolean => {
        return filterFieldsByCategory(field) &&
            filterFieldsByType(field) &&
            !this.dynamicHiddenFields[field.id];
    };

    private readonly setTranslatedLabels = (field: FieldConfig): IntakeFieldConfig => ({ ...field, name: this.translateService.instant(field.label || '-') });

    private readonly setValues = (field: IntakeFieldConfig): IntakeFieldConfig => {
        const extractedValues = this.extractValueFromModel(field);
        const booleanTransformedValues = setBooleanValue(extractedValues);
        const selectTransformedValues = this.setSelectValues(booleanTransformedValues);

        return this.setMultiSelectValues(selectTransformedValues);
    };

    private readonly setSelectValues = (field: IntakeFieldConfig): IntakeFieldConfig => {
        if (field.type === 'select') {
            const selectedOption = field.options.find(option => option.value === field.value) || {};
            const value = this.translateService.instant(selectedOption.label || '-');
            field.shortLabel = selectedOption.shortLabel;

            return { ...field, value };
        } else {
            return field;
        }
    };

    private readonly setMultiSelectValues = (field: IntakeFieldConfig): IntakeFieldConfig => {
        if (field.type === 'multi-select') {
            const name = [field.name as string]
                .concat(field.options
                    .map(option => this.translateService.instant(option.label || '-')));

            const value = ['']
                .concat(field.options
                    .map(option => ((field.value as Array<unknown>).includes(option.value)) ? '+' : '-'));

            return { ...field, name, value };
        } else {
            return field;
        }
    };

    private readonly setImputations = (field: IntakeFieldConfig): IntakeFieldConfig => {
        const imputation = (this.imputations || []).find(imp => imp.name === field.id);
        const value = imputation ? imputation.value : field.value;
        const imputedUnit = imputation && imputation.imputedUnit ? this.translateService.instant(imputation.imputedUnit) : null;
        const imputedLowerBound = imputation && imputation.bounds ? imputation.bounds.lowerBound ? imputation.bounds.lowerBound : null : null;
        const imputedUpperBound = imputation && imputation.bounds ? imputation.bounds.upperBound ? imputation.bounds.upperBound : null : null;

        return { ...field, value, imputed: !!imputation, imputedUnit, imputedLowerBound, imputedUpperBound };
    };

    private readonly setUnits = (field: IntakeFieldConfig): IntakeFieldConfig => {
        if (field.units) {
            if (!field.imputed) {
                const category = `${field.category}${field.unitSelector || ''}`;
                const categoryMatch = this.model[category] || {};
                const groupMatch = categoryMatch[`${field.id}Group`] || {};
                const fieldMatch = groupMatch[`${field.id}Field`] || {};
                const unitMatch = typeof fieldMatch !== 'boolean' 
                    ? fieldMatch[`${field.id}Unit`] ?? {}
                    : {};

                const selectedUnit = field.units.find(u => u.value === unitMatch);
                const unit = this.translateService.instant((selectedUnit || { label: 'unknown' }).label);

                return { ...field, unit };
            }
        }

        return field;
    };

    private readonly setRanges = (field: IntakeFieldConfig): IntakeFieldConfig => {
        const normalRange = (this.normalRanges || {})[field.id];
        let valueIndicator = ValueIndicator.Normal;
        if (normalRange) {
            if (normalRange.min !== undefined && normalRange.min > field.value) {
                valueIndicator = ValueIndicator.TooLow;
            }
            if (normalRange.max !== undefined && normalRange.max < field.value) {
                valueIndicator = ValueIndicator.TooHigh;
            }
        }

        return { ...field, valueIndicator };
    };

    private readonly divideGroupsOverColumns = (columns: Patient, group: Array<IntakeFieldConfig>): Patient => {
        const shortestColumnIndex = getSmallestColumn(columns);
        columns[shortestColumnIndex].push(group);

        return columns;
    };

    private readonly extractValueFromModel = (field: IntakeFieldConfig): IntakeFieldConfig => {
        const category = `${field.category}${field.unitSelector || ''}`;
        const categoryMatch = this.model[category] || {};
        const groupMatch = categoryMatch[`${field.id}Group`] || {};
        const fieldMatch = groupMatch[`${field.id}Field`] || {};

        const value = typeof fieldMatch !== 'boolean' 
            ? fieldMatch[field.id] ?? null
            : null;

        return { ...field, value };
    };
}

const setBooleanValue = (field: IntakeFieldConfig): IntakeFieldConfig => {
    const value = field.type === 'boolean' ? (field.value === true ? '+' : '-') : field.value;

    return { ...field, value };
};

const filterFieldsByType = (field: FieldConfig): boolean => {
    return !typeBlackList.includes(field.type);
};

const filterFieldsByCategory = (field: FieldConfig): boolean => {
    return !categoryBlackList.includes(field.category);
};

const groupFieldsByCategory = (groups: Array<Array<IntakeFieldConfig>>, field: IntakeFieldConfig): Array<Array<IntakeFieldConfig>> => {
    const fieldGroup = groups.find(group => group[0].category === field.category);
    if (fieldGroup) {
        fieldGroup.push(field);
    } else {
        groups.push([field]);
    }

    return groups;
};

const getSmallestColumn = (columns: Patient): number => {
    const columnLengths = columns
        .map((column) => {
            if (column.length === 0) {
                return 0;
            } else {

                return (column)
                    .map((columnGroup) => columnGroup.length)
                    .reduce((total, count) => total + count);
            }
        });

    const shortestLength = columnLengths
        .map(x => x)
        .sort((a, b) => a - b)[0];

    return columnLengths.findIndex(c => c === shortestLength);
};
