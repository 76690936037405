<div *ngIf="userType$ | async as userType">
  <div class="modal-body">
    <ng-container *ngIf="userType | isLoginUser; else session">
      <span translate>IDLETIMEOUT.REGISTERED.WARNING_TEXT_MINUTES</span>
      {{ timeOutTimer$ | async}}
      <span translate>IDLETIMEOUT.MINUTES</span>
      <span translate>IDLETIMEOUT.REGISTERED.WARNING_TEXT_SELECTION</span>
    </ng-container>

    <ng-template #session>
      <span translate>IDLETIMEOUT.SESSION.WARNING_TEXT_MINUTES</span>
      {{ timeOutTimer$ | async}}
      <span translate>IDLETIMEOUT.MINUTES</span>
      <span translate>IDLETIMEOUT.SESSION.WARNING_TEXT_SELECTION</span>
    </ng-template>

  </div>
  <div class="modal-footer">
    <div class="col-md-6">
      <button type="button" (click)="logOut()" class="btn modal-button modal-button__close">
        <ng-container *ngIf="userType | isLoginUser; else sessionYesBtn">
          <span translate>IDLETIMEOUT.REGISTERED.LOGOFFBUTTON_TEXT</span>
        </ng-container>

        <ng-template #sessionYesBtn>
          <span translate>IDLETIMEOUT.SESSION.LOGOFFBUTTON_TEXT</span>
        </ng-template>
      </button>
    </div>
    <div class="col-md-6">
      <button type="button" (click)="stay()" class="btn modal-button modal-button__continue">
          <span translate>IDLETIMEOUT.CONTINUEBUTTON_TEXT</span>
      </button>
    </div>
  </div>
</div>
