<div class="home-banner english" *ngIf="(lang$|async) ==='en'">
    <h3 translate>CALCULATOR_OVERVIEW.VTE_PREDICT.NAME</h3>
    <p>
        After the initial anticoagulant treatment for venous thromboembolism, a decision must be made to stop anticoagulation 
        or to continue indefinitely by weighing risk of recurrence and risk of bleeding, while considering a patient's preference 
        (<a target="_blank" href="https://journal.chestnet.org/article/S0012-3692(21)01506-3/fulltext">Stevens 2021</a>, 
        <a target="_blank" href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7556153/">Ortel 2020</a>, 
        <a target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/31504429/">Konstantinides 2020</a>). 
        For this purpose, the VTE-PREDICT risk score was developed. VTE-PREDICT (unpublished results) can be used for individual 
        patients with deep venous thrombosis or pulmonary embolism who completed the initial anticoagulant treatment of 3-12 months.
    </p>
    <p>
        VTE-PREDICT consists of two models:
    </p>
    <ol>
        <li>one for the prediction of risk of recurrent venous thromboembolism, and</li>
        <li>one for the prediction of clinically relevant bleeding (i.e., clinically relevant, non-major bleeding and major bleeding according
            to the ISTH criteria) (<a target="_blank"href="https://onlinelibrary.wiley.com/doi/10.1111/jth.13140">Kaatz 2015</a>).</li>
    </ol>
    <p>
        Both models can be used to predict 1- and 5-years risks using a total of 14 readily available clinical characteristics.
    </p>
    <b translate>CALCULATOR_OVERVIEW.VTE_PREDICT.NO_CANCER</b>
    <p>
        In the development of the VTE-PREDICT model, patients with active cancer were excluded. Active cancer is defined as cancer diagnosed, 
        treated or metastasized in the 6 months prior to the index event, excluding non-melanoma skin cancer.
    </p>
    <p>
        <b>
            Please note that this calculator version website is not yet MDR-approved, thus not intended for medical use.
        </b>
    </p>
</div>

<div class="home-banner dutch" *ngIf="(lang$|async) === 'nl'">
    <h3 translate>CALCULATOR_OVERVIEW.VTE_PREDICT.NAME</h3>
    <p>
        De behandeling van veneuze trombo-embolie (VTE; longembolie en diep veneuze trombose) bestaat uit 3 maanden orale antistolling. 
        Daarna moet een keuze worden gemaakt om te stoppen of om langdurig door te behandelen door het risico op een recidief VTE zorgvuldig
        af te wegen tegen de kans op een bloeding, waarbij ook de voorkeuren van de patiënt erg belangrijk zijn 
        (<a target="_blank" href="https://journal.chestnet.org/article/S0012-3692(21)01506-3/fulltext">Stevens 2021</a>, 
        <a target="_blank" href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7556153/">Ortel 2020</a>, 
        <a target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/31504429/">Konstantinides 2020</a>). 
        VTE-PREDICT (referentie nog niet beschikbaar) is ontwikkeld om deze risico's te voorspellen voor individuele patiënten. De calculator 
        kan worden gebruikt voor patiënten met diep veneuze trombose of longembolie die een initiële antistollingsbehandeling van ten minste 
        3 tot 12 maanden hebben afgerond.
    </p>
    <p>
        VTE-PREDICT bestaat uit 2 modellen:
    </p>
    <ol>
        <li>één voor het voorspellen van recidief VTE, en</li>
        <li>één voor klinisch relevante bloedingen (“major” en “clinically relevant, non-major” bloedingen, volgens de ISTH definitie)
            (<a target="_blank"href="https://onlinelibrary.wiley.com/doi/10.1111/jth.13140">Kaatz 2015</a>).</li>
    </ol>
    <p>
        De modellen kunnen worden gebruikt om risico's in een periode van 1 en 5 jaar te voorspellen aan de hand van 14 klinische voorspellers. 
    </p>
    <b translate>CALCULATOR_OVERVIEW.VTE_PREDICT.NO_CANCER</b>
    <p>
        De VTE-PREDICT-calculator kan niet worden gebruikt voor patiënten met een actieve maligniteit. 
        Een actieve maligniteit is gedefinieerd als: een maligniteit die is gediagnosticeerd of behandeld in de 6 maanden voorafgaand aan de 
        longembolie of diep veneuze trombose, of een gemetastaseerde maligniteit, uitgezonderd basaalcelcarcinoom en plaveiselcelcarcinoom van 
        de huid.
    </p>
    <p>
        <b>
            Deze risico score versie van de website is nog niet CE-gemarkeerd volgens de MDR en derhalve niet bedoeld voor medisch gebruik.
        </b>
    </p>
</div>