/* eslint-disable complexity */
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { GeneralQuery } from '@app-core/general-store/general.query';
import { UserQuery } from '@app-core/user-store/user.query';
import { QueryEntity } from '@datorama/akita';
import { TranslateService } from '@ngx-translate/core';
import { Observable, combineLatest, map } from 'rxjs';
import { Calculator, FieldConfig } from './calculator.model';
import { CalculatorStore, CalculatorsState } from './calculator.store';
import { FormattedIntake, IntakeFieldConfig } from './model/formatted-intake.model';

@Injectable({ providedIn: 'root' })
export class CalculatorQuery extends QueryEntity<CalculatorsState> {
    constructor(
        protected calculatorStore: CalculatorStore,
        private readonly generalQuery: GeneralQuery,
        private readonly userQuery: UserQuery,
        private readonly translateService: TranslateService,
        private readonly datePipe: DatePipe
    ) {
        super(calculatorStore);
    }

    public selectFormFields(): Observable<Array<FieldConfig>> {
        return this.selectActive().pipe(map((calculator) => calculator.fields));
    }

    public selectIntake(): Observable<Array<Array<Array<IntakeFieldConfig>>>> {
        return combineLatest([this.userQuery.selectLang(), this.selectActive()]).pipe(
            map(([, calculator]: [string, Calculator]) => calculator),
            map((state) =>
                new FormattedIntake(
                    state,
                    this.translateService).getColumnResults())
        );
    }

    public selectClipboardResult(): Observable<Array<any>> {
        const buildNr = this.generalQuery.getAppVersion();

        return combineLatest([this.userQuery.selectLang(), this.selectActive()]).pipe(
            map(([, calculator]: [string, Calculator]) => calculator),
            map(() => {
                let result: Array<any> = [];
                result.push([this.translateService.instant('CLIPBOARD.OUTPUT')]);
                result.push([this.translateService.instant('CLIPBOARD.DATE'), this.datePipe.transform(new Date(), 'dd/MM/yyyy')]);
                result.push([
                    this.translateService.instant('CLIPBOARD.CALCULATOR_USED'),
                    this.translateService.instant(this.getActive().title),
                ]);
                result.push([this.translateService.instant('CLIPBOARD.CALCULATOR_VERSION'), this.getActive().version]);
                result.push([this.translateService.instant('CLIPBOARD.WEBSITE_VERSION'), buildNr]);
                result.push([]);
                result.push([]);
                result.push([this.translateService.instant('CLIPBOARD.PERSONAL_RISK_PROFILE'), '']);
                const allCategories = this.getActive()
                    .fields.map((field) => field.category)
                    .filter((category, index, self) => self.indexOf(category) === index)
                    .filter((category) => category !== 'current' && category !== 'treatment');

                allCategories.forEach((category) => {
                    result = result.concat(this.getClipboardKeyValue(category));
                });

                const currentTreatment = this.getClipboardKeyValue('current');
                if (currentTreatment.length > 0) {
                    result.push([]);
                    result.push([this.translateService.instant('CLIPBOARD.CURRENT_TREATMENT'), '']);
                    result = result.concat(currentTreatment);
                }
                result.push([]);
                result.push([this.translateService.instant('CLIPBOARD.FUTURE_TREATMENT'), '']);
                result = result.concat(this.getClipboardKeyValue('treatment'));

                result.push([]);
                result.push([]);
                result = result.concat(this.getClipboardPredictions());

                return result.map((row) => row.join(': '));
            })
        );
    }

    private getClipboardKeyValue(categoryName: string): Array<[string, string]> {
        const state = this.getActive();
        const fields = state.fields
            .filter((field) => field.category.startsWith(categoryName))
            .filter((field) => field.type !== 'unitSelector' && field.type !== 'hidden');

        return (fields || [])
            .map(field => {
                let name = this.translateService.instant(field.label || '-');

                const category = `${field.category}${field.unitSelector || ''}`;

                const fieldGroup = ((state.model[category] || {})[`${field.id}Group`] || {})[`${field.id}Field`];
                let value = (state.model[category] || {})[`${field.id}Group`] && typeof fieldGroup !== 'boolean'
                    ? fieldGroup[field.id]
                    : null;

                let imputed = false;
                let unit: string = null;

                const imputation = (state.imputations || []).find((imp) => imp.name === field.id);

                if (field.type === 'boolean') {
                    value = value === true ? this.translateService.instant('FIELDS.YES') : this.translateService.instant('FIELDS.NO');
                }
                if (field.type === 'select') {
                    const selectedOption = field.options.find((option) => option.value === value) || {};
                    value = this.translateService.instant(selectedOption.label || '-');
                }
                if (field.type === 'multi-select') {
                    name = [name].concat(field.options.map((option) => this.translateService.instant(option.label || '-')));

                    value = [''].concat(
                        field.options.map((option) =>
                            (value as Array<unknown>).includes(option.value)
                                ? this.translateService.instant('FIELDS.YES')
                                : this.translateService.instant('FIELDS.NO')
                        )
                    );
                }
                if (field.units) {
                    if (!imputed && state.model[category] && typeof fieldGroup !== 'boolean') {
                        const selectedUnitValue = fieldGroup[`${field.id}Unit`];
                        const selectedUnit = field.units.find((u) => u.value === selectedUnitValue);
                        unit = this.translateService.instant((selectedUnit || { label: 'unknown' }).label);
                    } else {
                        unit = this.translateService.instant(field.units[0].label || 'unknown');
                    }
                    value = `${value} ${unit}`;
                }
                if (imputation) {
                    imputed = true;
                    if (imputation.imputedUnit && imputation.imputedUnit !== '') {
                        value = `${imputation.value} ${this.translateService.instant(
                            imputation.imputedUnit,
                            '-'
                        )} ${this.translateService.instant('CLIPBOARD.POPULATION_MEAN')}`;
                    } else {
                        value = `${imputation.value} ${this.translateService.instant('CLIPBOARD.POPULATION_MEAN')}`;
                    }
                }

                return [name, value];
            })
            .reduce((result, row) => {
                if (Array.isArray(row[0])) {
                    row[0].forEach((key, index) => {
                        const prefixSpace = index === 0 ? '' : ' ';
                        result.push([`${prefixSpace}${key}`, row[1][index]]);
                    });
                } else {
                    result.push(row);
                }

                return result;
            }, []);
    }

    private getClipboardPredictions(): Array<any> {
        const state = this.getActive();

        const riskKeys = Object.keys(state.risks);
        if (riskKeys.length === 0) {
            return [[]];
        } else {
            const result = [];
            result.push([this.translateService.instant('CLIPBOARD.PREDICTIONS'), '']);

            if (state.risks.lifeExpectancy) {
                result.push([]);
                result.push([this.translateService.instant('lifeExpectancy'), '']);
                result.push([this.translateService.instant('CALCULATORS.LIFETIME_TITLE')]);
                if (state.risks.lifeExpectancy.inaccurateResults) {
                    result.push([`(${this.translateService.instant('RESULTS.WARNING_LIFE_EXPECTANCY')})`]);
                }
                result.push([
                    this.translateService.instant('CALCULATORS.AGE_TREATMENT_START'),
                    state.risks.lifeExpectancy.ageTreatmentStart,
                ]);
                result.push([
                    this.translateService.instant('CALCULATORS.AGE_EXPECTANCY_DISEASE_FREE'),
                    state.risks.lifeExpectancy.diseaseFreeAge,
                ]);
                if (state.risks.lifeExpectancy.lifetimeBenefit) {
                    if (state.risks.lifeExpectancy.lifetimeBenefit < 0) {
                        result.push([
                            this.translateService.instant('CALCULATORS.YEARS_LOSS_DISEASE_FREE'),
                            state.risks.lifeExpectancy.lifetimeBenefit,
                        ]);
                    } else {
                        result.push([
                            this.translateService.instant('CALCULATORS.YEARS_GAIN_DISEASE_FREE'),
                            state.risks.lifeExpectancy.lifetimeBenefit,
                        ]);
                    }
                }
            }

            if (state.risks.lifeExpectancySecondary) {
                result.push([]);
                result.push([this.translateService.instant('lifeExpectancySecondary'), '']);
                result.push([this.translateService.instant('CALCULATORS.LIFETIME_DISEASE_FREE_SECONDARY_CHANCE')]);
                if (state.risks.lifeExpectancySecondary.inaccurateResults) {
                    result.push([`(${this.translateService.instant('RESULTS.WARNING_LIFE_EXPECTANCY')})`]);
                }
                result.push([
                    this.translateService.instant('CALCULATORS.AGE_TREATMENT_START'),
                    state.risks.lifeExpectancySecondary.ageTreatmentStart,
                ]);
                result.push([
                    this.translateService.instant('CALCULATORS.AGE_EXPECTANCY_DISEASE_FREE'),
                    state.risks.lifeExpectancySecondary.diseaseFreeAge,
                ]);
                if (state.risks.lifeExpectancySecondary.lifetimeBenefit) {
                    if (state.risks.lifeExpectancySecondary.lifetimeBenefit < 0) {
                        result.push([
                            this.translateService.instant('CALCULATORS.YEARS_LOSS_DISEASE_FREE'),
                            state.risks.lifeExpectancySecondary.lifetimeBenefit,
                        ]);
                    } else {
                        result.push([
                            this.translateService.instant('CALCULATORS.YEARS_GAIN_DISEASE_FREE'),
                            state.risks.lifeExpectancySecondary.lifetimeBenefit,
                        ]);
                    }
                }
            }

            if (state.risks.twoYearRisk) {
                result.push([]);
                result.push([this.translateService.instant('twoYearRisk'), '']);
                result.push([this.translateService.instant('CALCULATORS.TWO_YEAR_TREATMENT_TITLE')]);
                result.push([this.translateService.instant('CALCULATORS.CURRENT_RISK'), `${state.risks.twoYearRisk.risk}%`]);
                if (state.risks.twoYearRisk.treatmentBenefit < 0) {
                    result.push([
                        this.translateService.instant('CALCULATORS.INCREASED_RISK'),
                        `${Math.abs(state.risks.twoYearRisk.treatmentBenefit)}%`,
                    ]);
                    if (state.risks.twoYearRisk.treatmentBenefitFactor) {
                        result.push([
                            this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_HARM_5'),
                            `${Math.abs(state.risks.twoYearRisk.treatmentBenefitFactor)}`,
                        ]);
                    }
                } else {
                    result.push([
                        this.translateService.instant('CALCULATORS.REDUCED_RISK'),
                        `${state.risks.twoYearRisk.treatmentBenefit}%`,
                    ]);
                    if (state.risks.twoYearRisk.treatmentBenefitFactor) {
                        result.push([
                            this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_TREAT_5'),
                            `${state.risks.twoYearRisk.treatmentBenefitFactor}`,
                        ]);
                    }
                }
            }

            if (state.risks.fiveYearRisk) {
                result.push([]);
                result.push([this.translateService.instant('fiveYearRisk'), '']);
                result.push([this.translateService.instant('CALCULATORS.FIVE_YEAR_TREATMENT_TITLE')]);
                result.push([this.translateService.instant('CALCULATORS.CURRENT_RISK'), `${state.risks.fiveYearRisk.risk}%`]);
                if (state.risks.fiveYearRisk.treatmentBenefit < 0) {
                    result.push([
                        this.translateService.instant('CALCULATORS.INCREASED_RISK'),
                        `${Math.abs(state.risks.fiveYearRisk.treatmentBenefit)}%`,
                    ]);
                    if (state.risks.fiveYearRisk.treatmentBenefitFactor) {
                        result.push([
                            this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_HARM_5'),
                            `${Math.abs(state.risks.fiveYearRisk.treatmentBenefitFactor)}`,
                        ]);
                    }
                } else {
                    result.push([
                        this.translateService.instant('CALCULATORS.REDUCED_RISK'),
                        `${state.risks.fiveYearRisk.treatmentBenefit}%`,
                    ]);
                    if (state.risks.fiveYearRisk.treatmentBenefitFactor) {
                        result.push([
                            this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_TREAT_5'),
                            `${state.risks.fiveYearRisk.treatmentBenefitFactor}`,
                        ]);
                    }
                }
            }

            if (state.risks.twoYearRiskSecondary) {
                result.push([]);
                result.push([this.translateService.instant('twoYearRiskSecondary'), '']);
                result.push([this.translateService.instant('CALCULATORS.TWO_YEAR_SECONDARY_TREATMENT_TITLE')]);
                result.push([this.translateService.instant('CALCULATORS.CURRENT_RISK'), `${state.risks.twoYearRiskSecondary.risk}%`]);
                if (state.risks.twoYearRiskSecondary.treatmentBenefit < 0) {
                    result.push([
                        this.translateService.instant('CALCULATORS.INCREASED_RISK'),
                        `${Math.abs(state.risks.twoYearRiskSecondary.treatmentBenefit)}%`,
                    ]);
                    if (state.risks.twoYearRiskSecondary.treatmentBenefitFactor) {
                        result.push([
                            this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_HARM_5'),
                            `${Math.abs(state.risks.twoYearRiskSecondary.treatmentBenefitFactor)}`,
                        ]);
                    }
                } else {
                    result.push([
                        this.translateService.instant('CALCULATORS.REDUCED_RISK'),
                        `${state.risks.twoYearRiskSecondary.treatmentBenefit}%`,
                    ]);
                    if (state.risks.twoYearRiskSecondary.treatmentBenefitFactor) {
                        result.push([
                            this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_TREAT_5'),
                            `${state.risks.twoYearRiskSecondary.treatmentBenefitFactor}`,
                        ]);
                    }
                }
            }

            if (state.risks.fiveYearRiskSecondary) {
                result.push([]);
                result.push([this.translateService.instant('fiveYearRiskSecondary'), '']);
                result.push([this.translateService.instant('CALCULATORS.FIVE_YEAR_SECONDARY_TREATMENT_TITLE')]);
                result.push([this.translateService.instant('CALCULATORS.CURRENT_RISK'), `${state.risks.fiveYearRiskSecondary.risk}%`]);
                if (state.risks.fiveYearRiskSecondary.treatmentBenefit < 0) {
                    result.push([
                        this.translateService.instant('CALCULATORS.INCREASED_RISK'),
                        `${Math.abs(state.risks.fiveYearRiskSecondary.treatmentBenefit)}%`,
                    ]);
                    if (state.risks.fiveYearRiskSecondary.treatmentBenefitFactor) {
                        result.push([
                            this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_HARM_5'),
                            `${Math.abs(state.risks.fiveYearRiskSecondary.treatmentBenefitFactor)}`,
                        ]);
                    }
                } else {
                    result.push([
                        this.translateService.instant('CALCULATORS.REDUCED_RISK'),
                        `${state.risks.fiveYearRiskSecondary.treatmentBenefit}%`,
                    ]);
                    if (state.risks.fiveYearRiskSecondary.treatmentBenefitFactor) {
                        result.push([
                            this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_TREAT_5'),
                            `${state.risks.fiveYearRiskSecondary.treatmentBenefitFactor}`,
                        ]);
                    }
                }
            }

            if (state.risks.tenYearRisk) {
                result.push([]);
                result.push([this.translateService.instant('tenYearRisk'), '']);
                result.push([this.translateService.instant('CALCULATORS.TEN_YEAR_TREATMENT_TITLE')]);
                result.push([this.translateService.instant('CALCULATORS.CURRENT_RISK'), `${state.risks.tenYearRisk.risk}%`]);
                if (state.risks.tenYearRisk.treatmentBenefit < 0) {
                    result.push([
                        this.translateService.instant('CALCULATORS.INCREASED_RISK'),
                        `${Math.abs(state.risks.tenYearRisk.treatmentBenefit)}%`,
                    ]);
                    if (state.risks.tenYearRisk.treatmentBenefitFactor) {
                        result.push([
                            this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_HARM_10'),
                            `${Math.abs(state.risks.tenYearRisk.treatmentBenefitFactor)}`,
                        ]);
                    }
                } else {
                    result.push([
                        this.translateService.instant('CALCULATORS.REDUCED_RISK'),
                        `${state.risks.tenYearRisk.treatmentBenefit}%`,
                    ]);
                    if (state.risks.tenYearRisk.treatmentBenefitFactor) {
                        result.push([
                            this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_TREAT_10'),
                            `${state.risks.tenYearRisk.treatmentBenefitFactor}`,
                        ]);
                    }
                }
            }

            if (state.risks.fiveYearRiskWithHeartFailure) {
                result.push([]);
                result.push([this.translateService.instant('fiveYearRiskWithHeartFailure'), '']);
                result.push([this.translateService.instant('CALCULATORS.FIVE_YEAR_HEARTFAILURE_TREATMENT_TITLE')]);
                result.push([
                    this.translateService.instant('CALCULATORS.CURRENT_RISK'),
                    `${state.risks.fiveYearRiskWithHeartFailure.risk}%`,
                ]);
                if (state.risks.fiveYearRiskWithHeartFailure.treatmentBenefit < 0) {
                    result.push([
                        this.translateService.instant('CALCULATORS.INCREASED_RISK'),
                        `${Math.abs(state.risks.fiveYearRiskWithHeartFailure.treatmentBenefit)}%`,
                    ]);
                    if (state.risks.fiveYearRiskWithHeartFailure.treatmentBenefitFactor) {
                        result.push([
                            this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_HARM_5'),
                            `${Math.abs(state.risks.fiveYearRiskWithHeartFailure.treatmentBenefitFactor)}`,
                        ]);
                    }
                } else {
                    result.push([
                        this.translateService.instant('CALCULATORS.REDUCED_RISK'),
                        `${state.risks.fiveYearRiskWithHeartFailure.treatmentBenefit}%`,
                    ]);
                    if (state.risks.fiveYearRiskWithHeartFailure.treatmentBenefitFactor) {
                        result.push([
                            this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_TREAT_5'),
                            `${state.risks.fiveYearRiskWithHeartFailure.treatmentBenefitFactor}`,
                        ]);
                    }
                }
            }

            if (state.risks.tenYearRiskWithHeartFailure) {
                result.push([]);
                result.push([this.translateService.instant('tenYearRiskWithHeartFailure'), '']);
                result.push([this.translateService.instant('CALCULATORS.TEN_YEAR_HEARTFAILURE_TREATMENT_TITLE')]);
                result.push([
                    this.translateService.instant('CALCULATORS.CURRENT_RISK'),
                    `${state.risks.tenYearRiskWithHeartFailure.risk}%`,
                ]);
                if (state.risks.tenYearRiskWithHeartFailure.treatmentBenefit < 0) {
                    result.push([
                        this.translateService.instant('CALCULATORS.INCREASED_RISK'),
                        `${Math.abs(state.risks.tenYearRiskWithHeartFailure.treatmentBenefit)}%`,
                    ]);
                    if (state.risks.tenYearRiskWithHeartFailure.treatmentBenefitFactor) {
                        result.push([
                            this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_HARM_10'),
                            `${Math.abs(state.risks.tenYearRiskWithHeartFailure.treatmentBenefitFactor)}`,
                        ]);
                    }
                } else {
                    result.push([
                        this.translateService.instant('CALCULATORS.REDUCED_RISK'),
                        `${state.risks.tenYearRiskWithHeartFailure.treatmentBenefit}%`,
                    ]);
                    if (state.risks.tenYearRiskWithHeartFailure.treatmentBenefitFactor) {
                        result.push([
                            this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_TREAT_10'),
                            `${state.risks.tenYearRiskWithHeartFailure.treatmentBenefitFactor}`,
                        ]);
                    }
                }
            }

            if (state.risks.mortalityRisk) {
                result.push([]);
                result.push([this.translateService.instant('mortalityRisk'), '']);
                result.push([this.translateService.instant('CALCULATORS.MORTALITY_RISK_TITLE')]);
                result.push([this.translateService.instant('CALCULATORS.CURRENT_RISK'), `${state.risks.mortalityRisk.risk}%`]);
                if (state.risks.mortalityRisk.treatmentBenefit < 0) {
                    result.push([
                        this.translateService.instant('CALCULATORS.INCREASED_RISK'),
                        `${Math.abs(state.risks.mortalityRisk.treatmentBenefit)}%`,
                    ]);
                    if (state.risks.mortalityRisk.treatmentBenefitFactor) {
                        result.push([
                            this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_HARM_10'),
                            `${Math.abs(state.risks.mortalityRisk.treatmentBenefitFactor)}`,
                        ]);
                    }
                } else {
                    result.push([
                        this.translateService.instant('CALCULATORS.REDUCED_RISK'),
                        `${state.risks.mortalityRisk.treatmentBenefit}%`,
                    ]);
                    if (state.risks.mortalityRisk.treatmentBenefitFactor) {
                        result.push([
                            this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_TREAT_10'),
                            `${state.risks.mortalityRisk.treatmentBenefitFactor}`,
                        ]);
                    }
                }
            }

            if (state.risks.lifetimeRisk) {
                result.push([]);
                result.push([this.translateService.instant('lifetimeRisk'), '']);
                result.push([this.translateService.instant('CALCULATORS.LIFETIME_TITLE')]);
                result.push([this.translateService.instant('CALCULATORS.CURRENT_RISK'), `${state.risks.lifetimeRisk.risk}%`]);
                if (state.risks.lifetimeRisk.treatmentBenefit < 0) {
                    result.push([
                        this.translateService.instant('CALCULATORS.INCREASED_RISK'),
                        `${Math.abs(state.risks.lifetimeRisk.treatmentBenefit)}%`,
                    ]);
                    if (state.risks.lifetimeRisk.treatmentBenefitFactor) {
                        result.push([
                            this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_HARM_LIFE'),
                            `${Math.abs(state.risks.lifetimeRisk.treatmentBenefitFactor)}`,
                        ]);
                    }
                } else {
                    result.push([
                        this.translateService.instant('CALCULATORS.REDUCED_RISK'),
                        `${state.risks.lifetimeRisk.treatmentBenefit}%`,
                    ]);
                    if (state.risks.lifetimeRisk.treatmentBenefitFactor) {
                        result.push([
                            this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_TREAT_LIFE'),
                            `${state.risks.lifetimeRisk.treatmentBenefitFactor}`,
                        ]);
                    }
                }
            }
            if (state.risks.diseaseRisk) {
                let increaseOrDecreaseRiskText = 'CALCULATORS.REDUCED_RISK';
                let numbersToTreatOrHarmText = 'CALCULATORS.NUMBERS_NEEDED_TO_TREAT_LIFE';
                if (state.risks.diseaseRisk.lowRisk.risk < state.risks.diseaseRisk.lowRisk.riskWithTreatment) {
                    increaseOrDecreaseRiskText = 'CALCULATORS.INCREASED_RISK';
                    numbersToTreatOrHarmText = 'CACLULATORS.NUMBERS_NEEDED_TO_HARM_LIFE';
                }

                result.push([]);
                result.push([this.translateService.instant('diseaseRisk'), '']);
                result.push([this.translateService.instant('CALCULATORS.DISEASE_LOW_RISK_TITLE')]);
                result.push([this.translateService.instant('CALCULATORS.CURRENT_RISK'), `${state.risks.diseaseRisk.lowRisk.risk}%`]);
                result.push([
                    this.translateService.instant(increaseOrDecreaseRiskText),
                    `${state.risks.diseaseRisk.lowRisk.treatmentBenefit}%`,
                ]);
                if (state.risks.diseaseRisk.lowRisk.treatmentBenefitFactor) {
                    result.push([
                        this.translateService.instant(numbersToTreatOrHarmText),
                        `${state.risks.diseaseRisk.lowRisk.treatmentBenefitFactor}`,
                    ]);
                }

                result.push([this.translateService.instant('CALCULATORS.DISEASE_HIGH_RISK_TITLE')]);
                result.push([this.translateService.instant('CALCULATORS.CURRENT_RISK'), `${state.risks.diseaseRisk.highRisk.risk}%`]);
                result.push([
                    this.translateService.instant(increaseOrDecreaseRiskText),
                    `${state.risks.diseaseRisk.highRisk.treatmentBenefit}%`,
                ]);
                if (state.risks.diseaseRisk.highRisk.treatmentBenefitFactor) {
                    result.push([
                        this.translateService.instant(numbersToTreatOrHarmText),
                        `${state.risks.diseaseRisk.highRisk.treatmentBenefitFactor}`,
                    ]);
                }
            }

            if(state.risks.oneYearVTERecurrenceRisk) {
                result.push([]);
                result.push([this.translateService.instant('oneYearRisk'), '']);
                result.push([this.translateService.instant('CALCULATORS.ONE_YEAR_VTE_RECURRENCE_TREATMENT_TITLE')]);
                result.push([
                    this.translateService.instant('CALCULATORS.CURRENT_RISK'),
                    `${state.risks.oneYearVTERecurrenceRisk.risk}%`,
                ]);
                if (state.risks.oneYearBleedingRisk.treatmentBenefit < 0) {
                    result.push([
                        this.translateService.instant('CALCULATORS.INCREASED_RISK'),
                        `${Math.abs(state.risks.oneYearVTERecurrenceRisk.treatmentBenefit)}%`,
                    ]);
                    if (state.risks.oneYearVTERecurrenceRisk.treatmentBenefitFactor) {
                        result.push([
                            this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_HARM_1'),
                            `${Math.abs(state.risks.oneYearVTERecurrenceRisk.treatmentBenefitFactor)}`,
                        ]);
                    }
                } else {
                    result.push([
                        this.translateService.instant('CALCULATORS.REDUCED_RISK'),
                        `${state.risks.oneYearVTERecurrenceRisk.treatmentBenefit}%`,
                    ]);
                    if (state.risks.oneYearVTERecurrenceRisk.treatmentBenefitFactor) {
                        result.push([
                            this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_TREAT_1'),
                            `${state.risks.oneYearVTERecurrenceRisk.treatmentBenefitFactor}`,
                        ]);
                    }
                }
            }

            if(state.risks.oneYearBleedingRisk) {
                result.push([]);
                result.push([this.translateService.instant('oneYearBleedingRisk'), '']);
                result.push([this.translateService.instant('CALCULATORS.ONE_YEAR_BLEEDING_REATMENT_TITLE')]);
                result.push([
                    this.translateService.instant('CALCULATORS.CURRENT_RISK'),
                    `${state.risks.oneYearBleedingRisk.risk}%`,
                ]);
                if (state.risks.oneYearBleedingRisk.treatmentBenefit < 0) {
                    result.push([
                        this.translateService.instant('CALCULATORS.INCREASED_RISK'),
                        `${Math.abs(state.risks.oneYearBleedingRisk.treatmentBenefit)}%`,
                    ]);
                    if (state.risks.oneYearBleedingRisk.treatmentBenefitFactor) {
                        result.push([
                            this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_HARM_1'),
                            `${Math.abs(state.risks.oneYearBleedingRisk.treatmentBenefitFactor)}`,
                        ]);
                    }
                } else {
                    result.push([
                        this.translateService.instant('CALCULATORS.REDUCED_RISK'),
                        `${state.risks.oneYearBleedingRisk.treatmentBenefit}%`,
                    ]);
                    if (state.risks.oneYearBleedingRisk.treatmentBenefitFactor) {
                        result.push([
                            this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_TREAT_1'),
                            `${state.risks.oneYearBleedingRisk.treatmentBenefitFactor}`,
                        ]);
                    }
                }
            }

            if(state.risks.fiveYearVTERecurrenceRisk) {
                result.push([]);
                result.push([this.translateService.instant('fiveYearRisk'), '']);
                result.push([this.translateService.instant('CALCULATORS.FIVE_YEAR_VTE_RECURRENCE_TREATMENT_TITLE')]);
                result.push([
                    this.translateService.instant('CALCULATORS.CURRENT_RISK'),
                    `${state.risks.fiveYearVTERecurrenceRisk.risk}%`,
                ]);
                if (state.risks.fiveYearBleedingRisk.treatmentBenefit < 0) {
                    result.push([
                        this.translateService.instant('CALCULATORS.INCREASED_RISK'),
                        `${Math.abs(state.risks.fiveYearVTERecurrenceRisk.treatmentBenefit)}%`,
                    ]);
                    if (state.risks.fiveYearVTERecurrenceRisk.treatmentBenefitFactor) {
                        result.push([
                            this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_HARM_5'),
                            `${Math.abs(state.risks.fiveYearVTERecurrenceRisk.treatmentBenefitFactor)}`,
                        ]);
                    }
                } else {
                    result.push([
                        this.translateService.instant('CALCULATORS.REDUCED_RISK'),
                        `${state.risks.fiveYearVTERecurrenceRisk.treatmentBenefit}%`,
                    ]);
                    if (state.risks.fiveYearVTERecurrenceRisk.treatmentBenefitFactor) {
                        result.push([
                            this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_TREAT_5'),
                            `${state.risks.fiveYearVTERecurrenceRisk.treatmentBenefitFactor}`,
                        ]);
                    }
                }
            }

            if(state.risks.fiveYearBleedingRisk) {
                result.push([]);
                result.push([this.translateService.instant('fiveYearBleedingRisk'), '']);
                result.push([this.translateService.instant('CALCULATORS.FIVE_YEAR_BLEEDING_TREATMENT_TITLE')]);
                result.push([
                    this.translateService.instant('CALCULATORS.CURRENT_RISK'),
                    `${state.risks.fiveYearBleedingRisk.risk}%`,
                ]);
                if (state.risks.fiveYearBleedingRisk.treatmentBenefit < 0) {
                    result.push([
                        this.translateService.instant('CALCULATORS.INCREASED_RISK'),
                        `${Math.abs(state.risks.fiveYearBleedingRisk.treatmentBenefit)}%`,
                    ]);
                    if (state.risks.fiveYearBleedingRisk.treatmentBenefitFactor) {
                        result.push([
                            this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_HARM_5'),
                            `${Math.abs(state.risks.fiveYearBleedingRisk.treatmentBenefitFactor)}`,
                        ]);
                    }
                } else {
                    result.push([
                        this.translateService.instant('CALCULATORS.REDUCED_RISK'),
                        `${state.risks.fiveYearBleedingRisk.treatmentBenefit}%`,
                    ]);
                    if (state.risks.fiveYearBleedingRisk.treatmentBenefitFactor) {
                        result.push([
                            this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_TREAT_5'),
                            `${state.risks.fiveYearBleedingRisk.treatmentBenefitFactor}`,
                        ]);
                    }
                }
            }
            
            return result;
        }
    }
}
