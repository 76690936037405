import { Component, Input } from '@angular/core';

@Component({
    selector: 'cvrm-label-tile',
    templateUrl: './label-tile.component.html',
    styleUrls: ['./label-tile.component.scss']
})
export class LabelTileComponent {
    @Input() public color: 'purple' | 'teal' | 'darkblue' | 'pinkpurple' | 'disabled' | 'blue';
    @Input() public label = '';
    @Input() public sublabel = '';
    @Input() public moreInfo: string;
}
