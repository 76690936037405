import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralQuery } from '@app-core/general-store/general.query';

@Component({
    selector: 'app-error-component',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent {
    constructor(
        private readonly generalQuery: GeneralQuery,
        private readonly router: Router
    ) {
    }
    
    public refresh(): void {
        window.location.replace('/');
    }
}
