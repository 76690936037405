<div class="fixed-container">
  <div class="error">
    <div class="text">
      <h2 translate>404.PAGE_NOT_FOUND</h2>
      <h3 translate>404.CALL_TO_ACTION</h3>
      <div class="button-container">
        <a routerLink="/" class="btn-cta" translate>404.HOME_BUTTON</a>
        <a *ngIf="(site$ | async) === Site.VtePredict" routerLink="/calculators/vtePredict" class="btn-cta" translate>404.CALCULATOR_BUTTON</a>
        <a *ngIf="(site$ | async) !== Site.VtePredict" routerLink="/calculators" class="btn-cta" translate>404.CALCULATOR_BUTTON</a>
      </div>
    </div>
    <div class="image">
      <img src="/assets/404Image.jpg">
    </div>
  </div>
</div>