import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { UserService } from '@app-core/user-store/user.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'cvrm-cookie-consent-modal',
    templateUrl: './cookie-consent-modal.component.html',
    styleUrls: ['./cookie-consent-modal.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
    ]
})
export class CookieConsentModalComponent {

    constructor(
        private readonly modal: NgbActiveModal,
        private readonly userService: UserService
    ) { }

    public agree(): void {
        this.userService.updateCookieConsent(true);
        this.modal.dismiss();
    }

    public decline(): void {
        this.modal.dismiss();
    }
}
