import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { ChartConfiguration, ChartOptions, ChartType } from 'chart.js';
import { TranslateService } from '@ngx-translate/core';
import { BaseRisk, DiseaseRisk } from '@app-features/calculators/calculator.model';
import { ChartLabels, DEFAULT_CHART_OPTIONS, getMultilineText, updateBarData } from '@app-features/calculators/components/chart';

@Component({
    selector: 'cvrm-disease-risk-graph',
    templateUrl: './disease-risk-graph.component.html',
    styleUrls: ['./disease-risk-graph.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiseaseRiskGraphComponent implements OnChanges {
    @Input() public improvement: DiseaseRisk;
    @Input() public labels: ChartLabels = {};

    public readonly chartType: ChartType = 'bar';
    public status = 'neutral';
    public highestMax = 30;

    public lowRiskOptions: ChartOptions<'bar'>;
    public highRiskOptions: ChartOptions<'bar'>;

    public lowRiskData: ChartConfiguration<'bar'>['data'];
    public highRiskData: ChartConfiguration<'bar'>['data'];

    public lowRiskAbsNumbersNeededToTreat: number;
    public lowRiskAbsTreatmentBenefit: number;

    public highRiskAbsNumbersNeededToTreat: number;
    public highRiskAbsTreatmentBenefit: number;

    public TREATMENT_REDUCTION_INFO = '';
    public TREATMENT_NNT_INFO = '';

    public LOW_NNT_TITLE: string;
    public LOW_NNH_TITLE: string;
    public HIGH_NNT_TITLE: string;
    public HIGH_NNH_TITLE: string;
    public LOW_CURRENT_RISK_INFO: string;
    public LOW_TREATMENT_REDUCTION_INFO: string;
    public LOW_TREATMENT_BENEFIT_INFO: string;
    public HIGH_CURRENT_RISK_INFO: string;
    public HIGH_TREATMENT_REDUCTION_INFO: string;
    public HIGH_TREATMENT_BENEFIT_INFO: string;

    constructor(private readonly translateService: TranslateService) {}

    public ngOnChanges(): void {
        if (this.improvement) {
            this.LOW_CURRENT_RISK_INFO = `RESULTS.DISEASE_LOW_CURRENT_RISK_INFO`;
            this.LOW_TREATMENT_REDUCTION_INFO = `RESULTS.DISEASE_LOW_TREATMENT_${this.improvement.lowRisk.treatmentBenefit >= 0 ? 'REDUCTION' : 'INCREASE'}_INFO`;
            this.LOW_TREATMENT_BENEFIT_INFO = `RESULTS.DISEASE_LOW_TREATMENT_${this.improvement.lowRisk.treatmentBenefit >= 0 ? 'NNT' : 'NNH'}_INFO`;

            this.HIGH_CURRENT_RISK_INFO = `RESULTS.DISEASE_HIGH_CURRENT_RISK_INFO`;
            this.HIGH_TREATMENT_REDUCTION_INFO = `RESULTS.DISEASE_HIGH_TREATMENT_${this.improvement.highRisk.treatmentBenefit >= 0 ? 'REDUCTION' : 'INCREASE'}_INFO`;
            this.HIGH_TREATMENT_BENEFIT_INFO = `RESULTS.DISEASE_HIGH_TREATMENT_${this.improvement.highRisk.treatmentBenefit >= 0 ? 'NNT' : 'NNH'}_INFO`;

            this.lowRiskData = updateBarData(this.lowRiskData, this.improvement.lowRisk);
            this.highRiskData = updateBarData(this.highRiskData, this.improvement.highRisk);

            this.calculateMax(this.improvement.lowRisk);
            this.calculateMax(this.improvement.highRisk);

            this.lowRiskAbsNumbersNeededToTreat = !this.improvement.lowRisk.treatmentBenefitFactor ? null : Math.abs(this.improvement.lowRisk.treatmentBenefitFactor);
            this.lowRiskAbsTreatmentBenefit = Math.abs(this.improvement.lowRisk.treatmentBenefit);

            this.highRiskAbsNumbersNeededToTreat = !this.improvement.highRisk.treatmentBenefitFactor  ? null : Math.abs(this.improvement.highRisk.treatmentBenefitFactor);
            this.highRiskAbsTreatmentBenefit = Math.abs(this.improvement.highRisk.treatmentBenefit);

            this.LOW_NNT_TITLE = this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_TREAT_10');
            this.LOW_NNH_TITLE = this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_HARM_10');

            this.HIGH_NNT_TITLE = this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_TREAT_10');
            this.HIGH_NNH_TITLE = this.translateService.instant('CALCULATORS.NUMBERS_NEEDED_TO_HARM_10');

            this.lowRiskOptions = this.getOptions('low');
            this.highRiskOptions = this.getOptions('high');
        }
    }

    private calculateMax(risk: BaseRisk): number {
        const max = Math.max(risk.risk, risk.riskWithTreatment, this.highestMax);
        const nextTen = max % 10 > 0 ? ((Math.ceil(max / 10) * 10)) : max;

        this.highestMax = nextTen;

        return nextTen;
    }

    private getOptions(level: 'high' | 'low'): ChartOptions<'bar'> {
        const title = level === 'high' ? this.translateService.instant('CALCULATORS.DISEASE_HIGH_RISK_TITLE') :
            this.translateService.instant('CALCULATORS.DISEASE_LOW_RISK_TITLE');

        const max = this.calculateMax(level === 'high' ? this.improvement.highRisk : this.improvement.lowRisk);

        return {
            ...DEFAULT_CHART_OPTIONS,
            indexAxis: 'y',
            scales: {
                x: {
                    beginAtZero: true,
                    max,
                    stacked: true,
                    title: { display: true, text: 'Percentage' }
                },
                y: {stacked: true}
            },
            plugins: {
                title: {
                    display: true,
                    text: getMultilineText(title)
                },
                tooltip: { enabled: false }
            }
        };
    }
}
