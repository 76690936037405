import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbModalModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

@Component({
    selector: 'app-disable-component',
    templateUrl: './disable.component.html',
    styleUrls: ['./disable.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        NgbModalModule
    ]
})
export class DisableComponent {
    @Input() public readonly disable = false;
    @Input() public readonly roundBorder = false;
    @Output() public interacted = new EventEmitter();

    public inFocus = false;

    public toggleBlurOnHover(): void {
        if (this.disable) {
            this.inFocus = true;
        }
    }

    public toggleBlurOutHover(): void {
        if (this.disable) {
            this.inFocus = false;
        }
    }

    public clicked(): void {
        if (this.disable) {
            this.interacted.emit();
        }
    }
}