<div class="fixed-container">
    <div class="error">
        <div class="text">
            <h2 translate>TAB-CLOSED.MESSAGE</h2>
            <h2 translate>TAB-CLOSED.CALL_TO_ACTION</h2>
        </div>
        <div class="image">
            <img src="/assets/errorImage.jpg">
        </div>
    </div>
</div>