<div *ngIf="(calculator$ | async) as calculator">
  <cvrm-page-wave-background [color]="calculator.color">
    <div class="header">
      <h1>
        <span translate [attr.data-cy]="'calculatorHeader'">CALCULATORS.PERSONAL_RISK_PROFILE</span>
        <a href="#" (click)="toDescription($event)" class="description-link">
          <fa-icon icon="info-circle"></fa-icon>
        </a>

      </h1>
      <h3 [translate]="calculator.title" class="header__sub-title"></h3>
      <mat-spinner *ngIf="loadingData" diameter="130" mode="indeterminate"></mat-spinner>
    </div>
    <div class="disabled-notice" *ngIf="!fields || fields.length === 0" translate>
      <p translate>CALCULATORS.DISABLED_1</p>
      <p translate>CALCULATORS.DISABLED_2</p>
      <p translate>CALCULATORS.DISABLED_3</p>
      <p translate>CALCULATORS.DISABLED_4</p>
    </div>

    <ng-container *ngIf="lang$ | async as lang">
      <h5 *ngIf="calculator.id === 'elderlyScore'">
        <ng-container *ngIf="lang === 'en'">
          Please note that the Elderly risk score has been updated by the SCORE2-OP <a routerLink="/calculators/score2OP">calculator​</a>.
        </ng-container>
  
        <ng-container *ngIf="lang === 'nl'">
          De Ouderen risicoscore is opgevolgd door de SCORE2-OP <a routerLink="/calculators/score2OP">calculator​</a>.
        </ng-container>
      </h5>
  
      <h5 *ngIf="calculator.id === 'scoreEUHR' || calculator.id === 'scoreEULR' || calculator.id === 'scoreNL'">
        <ng-container *ngIf="lang === 'en'">
          Please note that the SCORE calculator has been updated by the SCORE2 <a routerLink="/calculators/score2">calculator​</a>.
        </ng-container>
  
        <ng-container *ngIf="lang === 'nl'">
          We willen u er graag op attenderen dat de SCORE calculator is opgevolgd door de SCORE2 <a routerLink="/calculators/score2">calculator​</a>.
        </ng-container>
      </h5>
    </ng-container>


    <div *ngIf="calculator.id === 'dialModel'" translate="NOTES.PERSONALRISK_DIALMODEL">
    </div>

    <!-- NOTE: Temporary fix, should be made into a configurable form field in the future -->
    <div class="form-image__container" *ngIf="calculator.id.includes('score2')">
      <img class="form-image__image" src="./../../../../assets/image_score2_risk_regions.PNG">
    </div>

    <div *ngIf="isPrefillFeatureOn$ | async" class="resync">
      <span [translate]="'RESYNC_ALL.TOOLTIP'" class="info__tooltip" [ngClass]="{active: isShowInfo}"></span>
      <button type="button" class="btn" data-cy="reload-all-btn" [disabled]="patientDataOverriden$ | async" (click)="reloadOriginalData()">
        <fa-icon icon="redo"></fa-icon> {{'RESYNC_ALL.BUTTON' | translate}}
      </button>
      <span class="info" (click)="toggleInfo()">
        <fa-icon icon="info-circle"></fa-icon>
      </span>
    </div>

    <div *ngIf="fields && fields.length !== 0">
      <form *ngIf="!isShowDescription" [formGroup]="form" (ngSubmit)="submit()">
        <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options"></formly-form>

        <div class="submit">
          <span></span>
          <span class="submit__area">
            <div class="submit__error" *ngIf="isShowGenericInvalidMessage && form.status==='INVALID'"
              translate="CALCULATORS.MISSING_DATA"></div>
            <button type="submit" class="btn btn-purple" data-cy="calculateButton" translate>CALCULATORS.SUBMIT</button>
          </span>
        </div>

      </form>
    </div>
  </cvrm-page-wave-background>
  <cvrm-footer-wave-background [color]="calculator.color"></cvrm-footer-wave-background>
</div>