import { ActivatedRouteSnapshot, CanActivateChildFn, RouterStateSnapshot } from "@angular/router";

const SURVEY_URL = "https://landing.imgzine.com/432/a/335177042";
const SCIEN_CREW_URL = "https://www.sciencrew.com/c/12046/a/335076537?title=Personalized_lifetime_prediction_of_survival_and_treatment_benef";
const SPONSOR_URL = "https://www.sciencrew.com/c/12046/a/335120317?title=LIFEHF_pharmaceutical_sponsorship";

export const toSurveyGuard: CanActivateChildFn = () => {
    window.open(SURVEY_URL, "_blank");
    return false;
};

export const toScienCrewGuard: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    if (state.url === '/about/life-hf') {
        window.open(SCIEN_CREW_URL, "_blank");

        return false;
    }

    if (state.url === '/about/sponsors') {
        window.open(SPONSOR_URL, "_blank");

        return false;
    }

    return true;
};