import { inject } from '@angular/core';
import { CanActivateChildFn, Router } from '@angular/router';
import { UserType } from '@app-core/user-store/user.model';
import { UserQuery } from '@app-core/user-store/user.query';
import { map } from 'rxjs';

export const registeredUserGuard: CanActivateChildFn = () => {
    const user$ = inject(UserQuery).select();
    const router = inject(Router);
    return user$.pipe(
        map(user => user.type === UserType.Login && user.country == null
            ? router.createUrlTree(['/user-profile'])
            : true)
    );
};