<div [hidden]="field?.props.hidden" class="group">

  <label [attr.for]="id"  class="group__label"  [ngClass]="{'label-clickable': field.type === 'switch'}" >
    <span *ngIf="isShowDescription" [innerHTML]="description" class="description__tooltip" data-cy="description-tooltip"></span>
    <span [translate]="wrapperLabel"></span>

    <span class="description" *ngIf="description">
      <fa-icon icon="info-circle" (click)="toggleDescription()"></fa-icon>
    </span>
  </label>

  <div class="group__fields">
    <ng-template #fieldComponent style="display: flex"></ng-template>
  </div>
</div>
