import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, Router, RouterStateSnapshot } from '@angular/router';
import { GeneralQuery } from '@app-core/general-store/general.query';
import { UserQuery } from '@app-core/user-store/user.query';
import { combineLatest, filter, map } from 'rxjs';

export const calculatorTermsGuard: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const userQuery = inject(UserQuery);
    const router = inject(Router);
    const generalQuery = inject(GeneralQuery);
    const urlTree = state.url.split('?');

    return combineLatest([
        generalQuery.selectFeatures(),
        userQuery.select()
    ]).pipe(
        filter(([features, user]) => features.requiresAuthentication == null || user.type != null),
        map(([, user]) => user.agreeTermsConditions 
            ? true 
            : router.createUrlTree(['/accept-terms'], { queryParams: { returnUrl: urlTree[0] }}))
    );
};