<div>
  <cvrm-page-wave-background [color]="calculator.color">
    <div class="header">
      <h1 translate="CALCULATOR_OVERVIEW.ASSIST_TITLE"></h1>
      <h3 [translate]="calculator.title" class="header__sub-title"></h3>
    </div>

    <mat-spinner *ngIf="loadingData" diameter="130" mode="indeterminate"></mat-spinner>
    <form *ngIf="!loadingData" [formGroup]="form" (ngSubmit)="updateRecommended(model)">
      <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options"
        (modelChange)="updateRecommended($event)"></formly-form>
      <div class="selector-helper">
        <a [routerLink]="recommendedCalculator.routerLink" *ngIf="recommendedCalculator">
          <cvrm-selection-tile [color]="recommendedCalculator.color" [label]="recommendedCalculator.label">
          </cvrm-selection-tile>
        </a>
        <div *ngIf="recommendedCalculator">
        <div *ngIf="recommendedCalculator.routerLink === '/calculators/dialModel'">
          {{'NOTES.PERSONALRISK_DIALMODEL_ASSIST' | translate}}
          <a routerLink="/calculators/dialModel">({{"CALCULATOR_OVERVIEW.LIFE_CVD_MODEL" | translate}})</a>
        </div>
      </div>
      </div>
    </form>
  </cvrm-page-wave-background>
  <cvrm-footer-wave-background [color]="calculator.color"></cvrm-footer-wave-background>
</div>
