import { Injectable } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { finalize, first, Observable } from "rxjs";
import { DialogComponent, DialogConfig, ResponseType } from "./dialog.component";

@Injectable({
    providedIn: 'root'
})
export class DialogService {

    constructor(private readonly modalService: NgbModal) {
    }

    public openAnnouncementModal(announcementModalConfig: DialogConfig): NgbModalRef {
        const modalRef = this.modalService.open(DialogComponent, { 
            windowClass: 'modal-z-index-correction',
            centered: true,
            animation: false,
            backdrop: 'static'
        });
    
        modalRef.componentInstance.config = { ...modalRef.componentInstance.config, ...announcementModalConfig };
        return modalRef;
    }
    
    public awaitResponse(modalRef: NgbModalRef): Observable<ResponseType> {
        return modalRef.componentInstance.response.pipe(first(), finalize(() => modalRef.close()));
    }
}