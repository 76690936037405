import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IsLoginUserPipe } from '@app-core/user-store/user.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ConsentComponent } from './components/consent-component/consent.component';
import { DividerComponent } from './components/divider/divider.component';
import { NotificationComponent } from './components/error-notification/error-notification.component';
import { FooterWaveBackgroundComponent } from './components/footer-wave-background/footer-wave-background.component';
import { IdleTimeoutModalComponent } from './components/idle-timeout-modal/idle-timeout-modal.component';
import { PageWaveBackgroundComponent } from './components/page-wave-background/page-wave-background.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { FormsModule } from './forms/forms.module';
import { ButtonSelectTestFormComponent } from './test-pages/button-select-test-form/button-select-test-form.component';
import { TestPagesComponent } from './test-pages/test-pages.component';

@NgModule({
    declarations: [
        NotificationComponent,
        ButtonSelectTestFormComponent,
        PageWaveBackgroundComponent,
        FooterWaveBackgroundComponent,
        VideoPlayerComponent,
        DividerComponent,
        TestPagesComponent,
        ConsentComponent,
        IdleTimeoutModalComponent,
    ],
    imports: [
        FormsModule,
        CommonModule,
        TranslateModule,
        RouterModule.forChild([{
            path: 'form-component-test-page',
            component: TestPagesComponent,
        }]),
        IsLoginUserPipe
    ],
    exports: [
        NotificationComponent,
        PageWaveBackgroundComponent,
        FooterWaveBackgroundComponent,
        TranslateModule,
        FormsModule,
        ConsentComponent,
        DividerComponent,
    ]
})
export class SharedModule { }
