import { HttpClient } from '@angular/common/http';
import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GeneralQuery } from '@app-core/general-store/general.query';
import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify';
import { MarkdownService } from 'ngx-markdown';
import { map } from 'rxjs';
import { ContentQuery } from './content.query';
import { ContentStore } from './content.store';

@Injectable({ providedIn: 'root' })
export class ContentService {
    constructor(
        private readonly markdownService: MarkdownService,
        private readonly sanitizer: DomSanitizer,
        private readonly http: HttpClient,
        private readonly generalQuery: GeneralQuery,
        private readonly contentStore: ContentStore,
        private readonly contentQuery: ContentQuery,
        private readonly dompurifySanitizer: NgDompurifySanitizer
    ) {}

    public fetchContent(fullPath: string, lang: string, cookieConsent: boolean): void {
        this.contentStore.clearContentWhenCookieConsentChanged(cookieConsent);

        if (this.contentQuery.isNotLoaded(fullPath, lang)) {
            this.contentStore.updateLoading(fullPath, lang);

            const resourceUrl = this.generalQuery.getResourceUrl(fullPath);
            this.http
                .get(`${resourceUrl}/${lang}/${fullPath}`, { responseType: 'text' })
                .pipe(
                    map((response) => this.compile(response, resourceUrl)),
                    map((response) => this.dompurifySanitizer.sanitize(SecurityContext.HTML, response)),
                    map((response) => this.sanitizer.bypassSecurityTrustHtml(response))
                )
                .subscribe({
                    next: (response) => this.contentStore.updateContent(response, fullPath, lang),
                    error: () => this.contentStore.updateNotFound(fullPath, lang)
                });
        }
    }

    public compile(content: string, resourceUrl: string): string {
        this.markdownService.renderer.link = linkRenderer;
        this.markdownService.renderer.blockquote = blockQuoteRenderer;
        this.markdownService.renderer.image = mediaRenderer(resourceUrl);

        return this.markdownService.parse(content);
    }
}

/**
 *  matching patterns like "<p>className; rest of the text</p>"
 *  so the className: can be added to the surrounding div as class name
 *
 *  in this case the outcome of the pattern will be:
 *  "<div class="className"><p>rest of the text</p></div>"
 *
 *  when the first word is not ended with a semicolon, it is no match and the original content
 *  will just be surrounded by <div></div>
 */
const blockQuoteRenderer = (text: string): string => {
    const textMatch = text.match(/(\<p\>)(\w+)(;\s?)(.*)/);

    if (textMatch) {
        text = text.replace(textMatch[2] + textMatch[3], '');
    }
    const className = textMatch ? ` class="_host-${textMatch[2]}"` : '';

    return `<div${className}>${text}</div>`;
};

const mediaRenderer = (resourceUrl: string) => {
    return (href: string, title: string, text: string) => {
        const absHref = href.startsWith('/') ? `${resourceUrl}${href}` : href;

        return `<img src="${absHref}" title="" text="${text}"/>`;
    };
};

const linkRenderer = (href: string, title: string, text: string): string => {
    if (href.startsWith('#')) {
        const name = href.substring(1);
    
        return `<a name="${name}">${text}</a>`;
    }
    
    return `<a href="${href}" role="link" tabindex="0" target="_blank" rel="nofollow noopener noreferrer">${text}</a>`;
};
