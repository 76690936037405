import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { GeneralQuery } from '@app-core/general-store/general.query';
import { Country, Lang, SupportedLanguage, userHasCompletedRegistration } from '@app-core/user-store/user.model';
import { UserQuery } from '@app-core/user-store/user.query';
import { UserService } from '@app-core/user-store/user.service';
import { HttpError } from '@app-features/calculators/calculator.model';
import { ButtonType, DialogConfig, ResponseType } from '@app-shared/components/dialog-component/dialog.component';
import { DialogService } from '@app-shared/components/dialog-component/dialog.service';
import { NotificationComponent } from '@app-shared/components/error-notification/error-notification.component';
import { Site } from '@app-shared/models/site-configuration-enum';
import { AuthorizationService } from '@app-shared/services/authorization.service';
import { getHomeUrl } from '@app-shared/services/url.service';
import { SharedModule } from '@app-shared/shared.module';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { BolCalloutModule } from '@ortec/bolster/callout';
import { BolNotificationService } from '@ortec/bolster/notification';
import { BolSpinnerModule } from '@ortec/bolster/spinner';
import { cache } from '@ortec/soca-web-ui';
import { environment } from 'environments/environment';
import { BehaviorSubject, combineLatest, delay, first, map, Observable, switchMap } from 'rxjs';

const requiresAuth = environment.requiresAuthenticationConfig;
interface UserProfile {
    registrationNumber: string;
    professionalFullName: string;
    email: string;
    country: Country,
    preferredLanguage: Lang;
    emailNotificationConsentGiven: boolean;
    privacyStatement: boolean;
}

interface UserProfileForm {
    registrationNumber: FormControl<string>;
    professionalFullName: FormControl<string>;
    email: FormControl;
    country: FormControl<Country>;
    preferredLanguage: FormControl<Lang>;
    emailNotificationConsentGiven: FormControl<boolean>;
    privacyStatement: FormControl<boolean>;
}

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        SharedModule,
        TranslateModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,

        BolCalloutModule,
        BolSpinnerModule,
         
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatTooltipModule,
        MatSelectModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatCheckboxModule
    ]
})
@UntilDestroy()
export class UserProfileComponent implements OnInit {
    private readonly allowedLangForCountries = this.generalQuery.getAllowedLangForCountries();
    private readonly supportedLanguages = Object.values(SupportedLanguage).map(lang => lang.toString());
    private availableCountries = Object.values(Country).map(country => ({ label: country, value: country }));
    public saveInProgress$ = new BehaviorSubject<boolean>(false);
    public saveIsFinalizing$ = new BehaviorSubject<boolean>(false);

    public FORM_IS_INVALID = false;
    
    public user$ = this.userService.getLoginUser().pipe(
        switchMap(() => this.userQuery.selectLoginUser()),
        cache()
    );

    public hasCompletedRegistration$: Observable<boolean> = this.user$.pipe(
        map(userHasCompletedRegistration)
    );

    public readonly form = this.formBuilder.group<UserProfileForm>({
        registrationNumber: this.formBuilder.nonNullable.control(''),
        professionalFullName: this.formBuilder.nonNullable.control('', { validators: [Validators.required, this.whiteSpaceValidator()] }),
        email: this.formBuilder.nonNullable.control({value: '', disabled: true}, { validators: Validators.required }),
        country: this.formBuilder.nonNullable.control(null, { validators: Validators.required }),
        preferredLanguage: this.formBuilder.nonNullable.control({value: null}, { validators: Validators.required }),
        emailNotificationConsentGiven:  this.formBuilder.control(false),
        privacyStatement: this.formBuilder.control(true)
    });
    public countryOptions = this.availableCountries;
    public langOptions: Array<{ label: string, value: Lang }> = [];

    public unsupportedLanguage$ = this.form.controls.preferredLanguage.valueChanges.pipe(
        map(lang => !this.supportedLanguages.includes(lang))
    );

    public noLanguageSupport$ = this.form.controls.country.valueChanges.pipe(
        map(country => !this.hasAnySupportedLanguage(country))
    );

    public restOfTheWorldWarning$ = this.form.controls.country.valueChanges.pipe(
        map(country => country === Country.RestOfTheWorld)
    );

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly userQuery: UserQuery,
        private readonly generalQuery: GeneralQuery,
        private readonly userService: UserService,
        private readonly bolNotificationService: BolNotificationService,
        private readonly dialogService: DialogService,
        private readonly authorizationService: AuthorizationService) {
    }
        
    public ngOnInit(): void {
        this.registerFieldInteractions();
        
        this.user$.pipe(
            delay(0), // this was added to ensure that the this.form.controls.<property> emits at the end so that the observables are triggered (this needs a better solution)
            untilDestroyed(this)
        ).subscribe(user => {
            this.form.patchValue({
                ...user,
                emailNotificationConsentGiven: user.emailNotificationConsent?.given ?? false,
                privacyStatement: true
            } as UserProfile);
        });
    }

    public downloadInformation(): void {
        this.userService.getLoginUser().pipe(first()).subscribe(user => {
            const blob = new Blob([JSON.stringify(user)], { type: 'application/json' });
            const blobUrl = URL.createObjectURL(blob);
                    
            const link = document.createElement("a");
            link.href = blobUrl;
            link.download = `${this.form.value.professionalFullName ?? this.form.value.email}-${new Date().toLocaleDateString()}.json`;
            link.click();
        
            URL.revokeObjectURL(blobUrl);
            link.remove();  
        });
    }

    public updateEmail(): void {
        this.user$.pipe(first()).subscribe(user => {
            this.authorizationService.clearUserSession();
            const redirectUrl = getHomeUrl().replace('://', '%3A%2F%2F') + '/authenticate';
            window.location.href = `${requiresAuth.b2cAuthorizeUrl}?p=B2C_1A_ChangeEmail&client_id=${requiresAuth.b2cClientId}&nonce=defaultNonce&redirect_uri=${redirectUrl}&login_hint=${user.email}&scope=openid&response_type=code&prompt=login`;
        });
    }

    public deleteAccount(): void {
        const input: DialogConfig = {
            bodyText: 'USER_PROFILE.DELETE.TITLE',
            agreeBtn: {
                text: 'USER_PROFILE.DELETE.AGREE',
                type: ButtonType.Danger
            } ,
            declineBtn: {
                text: 'USER_PROFILE.DELETE.DISAGREE',
                type: ButtonType.Positive
            },
            site: Site.Default,
        };

        const modalRef = this.dialogService.openAnnouncementModal(input);

        this.dialogService.awaitResponse(modalRef).subscribe(response => {
            if (response === ResponseType.Positive) {
                this.rerouteToB2CDeleteAccount();
            }
        });
    }

    public submit(): void {
        if (this.form.valid) {
            this.saveInProgress$.next(true);
            this.userService
                .updateUserProfile(this.form.value)
                .subscribe({
                    next: () => {
                        this.saveIsFinalizing$.next(true);
                        setTimeout(() => { 
                            this.saveInProgress$.next(false);
                            this.saveIsFinalizing$.next(false);
                        }, 2000); 
                    },
                    error: error => {
                        this.error(error);
                        this.saveInProgress$.next(false);
                    }
                });
        } else {
            this.FORM_IS_INVALID = true;
        }
    }

    public filterCountryList($event: string): void {
        this.countryOptions = this.availableCountries.filter(country => country.label.toLowerCase().includes($event.toLowerCase()));
    }

    private registerFieldInteractions(): void {
        combineLatest([
            this.form.controls.country.valueChanges,
            this.user$
        ]).pipe(
            untilDestroyed(this)
        ).subscribe(([country, user]) => {
            if (country !== user.country) {
                this.form.patchValue({ preferredLanguage: this.findFirstSupportedLanguage(country) ?? this.allowedLangForCountries[country][0] });
            } else {
                this.form.patchValue({ preferredLanguage: user.preferredLanguage });
            }

            this.langOptions = this.allowedLangForCountries[country].map(lang => ({ label: getKeyNameForLang(lang), value: lang }));
        });

        this.form.controls.privacyStatement.valueChanges.pipe(
            untilDestroyed(this)
        ).subscribe(ps => {
            if (!ps) {
                const input: DialogConfig = {
                    bodyText: 'USER_PROFILE.PRIVACY_STATEMENT.UNCHECK.BODY',
                    agreeBtn: {
                        text: 'USER_PROFILE.PRIVACY_STATEMENT.UNCHECK.AGREE',
                        type: ButtonType.Positive
                    } ,
                    declineBtn: {
                        text: 'USER_PROFILE.PRIVACY_STATEMENT.UNCHECK.DISAGREE',
                        type: ButtonType.Danger
                    },
                    site: Site.Default
                };

                this.openDeleteAccountConfirmationModal(input);
            }
        });
    }

    private findFirstSupportedLanguage(country: Country): Lang {
        return this.allowedLangForCountries[country].find(lang => this.supportedLanguages.includes(lang));
    }

    private hasAnySupportedLanguage(country: Country): boolean {
        return this.allowedLangForCountries[country].some(lang => this.supportedLanguages.includes(lang));
    }

    private error(httpErrorResponse: HttpErrorResponse): void {
        const error = httpErrorResponse.error as HttpError;

        const input = {
            component: NotificationComponent,
            data: { 
                PrimaryErrorMessage: "UPDATE FAILED", 
                SecondaryErrorMessage: error.innerErrorMessage,
                StatusCode: error.status
            }
        };

        this.bolNotificationService.negative(input, 'error', '',  { duration: 5000 });
    }

    private whiteSpaceValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return control.value.trim() === "" ? { invalidName: { value: control.value } } : null;
        };
    }

    private openDeleteAccountConfirmationModal(input: DialogConfig): void {
        const modalRef = this.dialogService.openAnnouncementModal(input);

        this.dialogService.awaitResponse(modalRef).subscribe(response => {
            switch (response) {
                case ResponseType.Positive:
                case ResponseType.Cancel:
                    this.form.patchValue({ privacyStatement: true });
                    break;
                case ResponseType.Negative:
                    this.rerouteToB2CDeleteAccount();
                    break;
            }
        });
    }

    private rerouteToB2CDeleteAccount(): void {
        this.userService
            .getLoginUser()
            .pipe(
                map((user) => {
                    this.authorizationService.deleteUser().subscribe();
                    this.authorizationService.clearUserSession();
                    
                    const redirectUrl = getHomeUrl().replace('://', '%3A%2F%2F');
                    window.location.href = `${requiresAuth.b2cAuthorizeUrl}?p=B2C_1A_DELETE_MY_ACCOUNT&client_id=${requiresAuth.b2cClientId}&nonce=defaultNonce&redirect_uri=${redirectUrl}&login_hint=${user.email}&scope=openid&response_type=code&prompt=login`;
                })
            )
            .subscribe();
    }
}

const getKeyNameForLang = (lang: Lang): string => {
    return Object.keys(Lang)[Object.values(Lang).indexOf(lang)];
};