import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn } from '@angular/router';
import { of } from 'rxjs';
import { PatientDataService } from './patient-data.service';

export const calculatorPatientDataGuard: CanActivateChildFn = (route: ActivatedRouteSnapshot) => {
    const patientDataService = inject(PatientDataService);
    patientDataService.storePatientRequestIfPresent(route);

    return of(true);
};