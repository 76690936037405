import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { GeneralQuery } from '@app-core/general-store/general.query';
import { Lang, UserState } from '@app-core/user-store/user.model';
import { UserService } from '@app-core/user-store/user.service';
import { Site } from '@app-shared/models/site-configuration-enum';
import { AuthorizationService } from '@app-shared/services/authorization.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Observable, combineLatest, filter, map, switchMap } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    @Input() user: UserState;
    @Input() isMdrCertified: boolean;
    @Input() site: Site;
    @Input() availableLanguages: Array<Lang>;
    @Input() showLifeHfStartPage: boolean;

    public menu$ = this.generalQuery.selectMenu();

    public Site = Site;
    public pageTitle = '';
    public activeLabel: string;
    public opened = false;

    constructor(
        public readonly translate: TranslateService,
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        private readonly translateService: TranslateService,
        private readonly generalQuery: GeneralQuery,
        private readonly userService: UserService,
        private readonly authorizationService: AuthorizationService,
    ) { }

    @HostListener('window:click', ['$event']) public clickOutsideListener(): void {
        this.activeLabel = '';
    }

    public ngOnInit(): void {
        this.initPageTitle().pipe(
            untilDestroyed(this)
        ).subscribe(pageTitle => {
            this.pageTitle = pageTitle;
        });
    }

    public logOut(): void {
        this.authorizationService.logout();
    }

    public onSelectLanguage(language: string): void {
        this.userService.updateLang(language);
    }

    public toggleSideBar(): void {
        this.opened = !this.opened;
    }

    public toggleSubMenu(event: MouseEvent, label: string): void {
        event.stopPropagation();
        this.activeLabel = this.activeLabel === label ? '' : label;
    }

    // Alternative for routerLinkActive, as routerLink would be needed, but there is no way to disable the navigation
    // in Angular 8. Once this is possible, replace with routerLinkActive in the template. See OLCUPREV-606.
    public isRouteActive(rootPath: string): boolean {
        if (!this.activatedRoute.snapshot.firstChild) {
            return false;
        }

        return rootPath === this.activatedRoute.snapshot.firstChild.routeConfig.path;
    }

    public isClosedTab(): boolean {
        return this.router.url === '/tab-closed';
    }

    /**
     * In mobile view, the header component shows the page title. This is defined in
     * the route configuration via the data.title property. If not defined, the title becomes
     * U-prevent
     *
     * The page title changes when the user navigates to another page or when he changes the language.
     * Both changes should lead to get a new translation for the page title.
     */
    private initPageTitle(): Observable<string> {
        const langChange$ = this.translateService.onLangChange;
        const routeChange$ = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),    // only continue when the navigation cycle is completed
                map(() => this.activatedRoute), // pass in the activated route to get the data
                map(route => getPageTitleKey(route.snapshot)),      // get the page title key from the snapshot
                map(pageTitleKey => pageTitleKey || 'U-Prevent'),   // return the page title key if exists, or return the default
            );

        return combineLatest([langChange$, routeChange$]) // translate the page title key when language or route changes
            .pipe(
                switchMap(result => this.translateService.get(result[1]))
            );
    }
}

/**
 * this function returns the page title key recursively. When a route is nested as a child route, the data may be
 * several levels deep.
 */
const getPageTitleKey = (route: ActivatedRouteSnapshot): string => {
    return route.firstChild ? getPageTitleKey(route.firstChild) : route.data.title;
};
