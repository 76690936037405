<ng-container [ngSwitch]="lang">   
    <div *ngSwitchCase="Lang.Dutch" class="home-banner dutch">
        <h3>U-Prevent Updates</h3>
        <p>Geachte bezoeker,</p>
        <p>
            Updates van U-Prevent (versie-informatie) kunt u vinden onder <a routerLink="/about/update">Update</a>.
            U wordt geadviseerd de nieuwe versie-informatie door te nemen voor het gebruik van U-Prevent.
        </p>
        <p><a routerLink="/about/u-prevent">Het U-Prevent team</a></p>
    </div>

    <div *ngSwitchDefault class="home-banner english">
        <h3>U-Prevent Updates</h3>
        <p>Dear visitor,</p>
        <p>
            U-Prevent updates (release notes) can be found on the website under <a routerLink="/about/update">Update</a>.
            Please refer to the new release notes before using U-Prevent.  
        </p>
        <p><a routerLink="/about/u-prevent">The U-Prevent team</a> </p>
    </div>
</ng-container>