<img src="../../../../assets/careful.png" class="careful" />
<h1 translate>TOS.HEADER</h1>

<div *ngIf="(lang$ | async) === 'nl'" class="content">
    <p>Belangrijk: lees deze gebruiksvoorwaarden zorgvuldig door voordat u gebruik maakt van de U-Prevent calculator
        software (de "Tool"). Het gebruik van de Tool impliceert dat u deze gebruiksvoorwaarden hebt geaccepteerd.</p>

    <h2>SLECHTS INFORMATIEF</h2>
    <p>De Tool en U-Prevent website zijn bedoeld ter informatie en naslag. Wij garanderen echter niet dat de Tool
        geschikt is voor welk doel dan ook. De tool is nadrukkelijk niet bedoeld om een diagnose te stellen en u kunt er
        geen aanbevelingen aan ontlenen voor het starten of stoppen van behandeling. De tool kan ook niet gebruikt
        worden als vervanging voor een professioneel medisch advies door een gekwalificeerde hulpverlener.</p>
    <p>Consulteer altijd uw behandelend arts indien u zorgen of vragen hebt over uw gezondheid of behandeling. Wacht
        nooit met het inwinnen van advies door een professionele medische hulpverlener en negeer dit advies nooit
        vanwege informatie op de U-Prevent website. </p>

    <h2>AANSPRAKELIJKHEID</h2>
    <p>Het gebruik van de Tool en U-Prevent website geschiedt op eigen risico. Hoewel de Tool en U-Prevent website met
        uiterste zorg zijn samengesteld en ontwikkeld, kunnen hierbij onbedoeld toch fouten zijn gemaakt. ORTEC aanvaart
        hiervoor geen enkele aansprakelijkheid tegenover wie dan ook.</p>
    <p>ORTEC geeft geen garanties met betrekking tot de Tool en U-Prevent in welke vorm dan ook, waaronder garanties
        over beschikbaarheid, kwaliteit, geschiktheid, compatibiliteit, veiligheid, nauwkeurigheid, volledigheid of
        impliciete garanties die voortvloeien uit de handelwijze of het gebruik. </p>
    <p>Daarnaast garandeert ORTEC niet dat de Tool en U-Prevent website aan uw eisen zullen voldoen, ononderbroken, up
        to date, veilig of foutvrij zullen zijn, dat defecten worden gecorrigeerd of dat de Tool of de server van de
        U-Prevent website vrij zijn van virussen of fouten. ORTEC is niet verantwoordelijk of aansprakelijk tegenover u
        voor verlies van gegevens die via de website worden geüpload of verzonden. </p>

    <h2>SCHADELOOSSTELLING</h2>
    <p>U stemt ermee in ORTEC en haar werknemers, onmiddellijk op verzoek, schadeloos te stellen voor alle claims,
        aansprakelijkheid, schade, kosten en uitgaven, inclusief juridische kosten, die voortvloeien uit een schending
        van deze gebruiksvoorwaarden door u of andere aansprakelijkheden die ontstaan uit uw gebruik van de Tool en / of
        deze Website.</p>

    <h2>AUTEURSRECHT</h2>
    <p>Alle rechten voorbehouden. Materialen op deze website zijn auteursrechtelijk beschermd. Niets van deze website
        mag worden gereproduceerd, opgeslagen in een database of openbaar worden gemaakt op welke manier dan ook zonder
        de voorafgaande schriftelijke toestemming van ORTEC. Deze site is niet bedoeld voor commercieel gebruik. </p>

    <h2>RECHTEN VAN ORTEC</h2>
    <p>ORTEC behoudt zich het recht voor om de Tool en de U-Prevent website (of een deel daarvan) met of zonder
        kennisgeving aan u te wijzigen of tijdelijk of definitief te verwijderen en u bevestigt dat ORTEC niet
        aansprakelijk is jegens u of een derde partij voor enige wijziging of intrekking van de Tool of U-Prevent
        website.</p>
    <p>ORTEC behoudt zich het recht voor deze gebruiksvoorwaarden te wijzigen. Dergelijke wijzigingen worden weergegeven
        op de website. Als u de Tool of U-Prevent website (of een deel daarvan) na een dergelijke wijziging blijft
        gebruiken, wordt dit beschouwd als uw aanvaarding van een dergelijke wijziging. U bent er zelf verantwoordelijk
        voor dat u op de hoogte bent van dergelijke wijzigingen die in de gebruiksvoorwaarden zijn aangebracht. </p>

    <h2>TOEPASSELIJK RECHT</h2>
    <p>Op deze gebruiksvoorwaarden is het Nederlands recht van toepassing. U stemt hierbij in met de exclusieve
        jurisdictie van de Nederlandse rechtbanken in alle geschillen die voortkomen uit of verband houden met het
        gebruik van de Tool en de U-Prevent. Gebruik van de Tool en de U-Prevent is niet toegestaan in jurisdicties
        waarin niet alle bepalingen van deze algemene voorwaarden kunnen worden nageleefd, inclusief maar niet beperkt
        tot deze paragraaf. Als wordt vastgesteld dat een deel van deze overeenkomst ongeldig of niet-afdwingbaar is
        binnen de geldende wetgeving, inclusief maar niet beperkt tot de garantiebeperkingen en
        aansprakelijkheidsbeperkingen zoals hierboven uiteengezet, dan wordt de ongeldige of niet-afdwingbare bepaling
        geacht vervangen te zijn door een geldige, afdwingbare bepaling die het best overeenkomt met de bedoeling van de
        oorspronkelijke bepaling. De rest van de overeenkomst blijft dan van kracht.</p>

    <h2>COOKIES</h2>
    <p>ORTEC gebruikt zogenaamde ‘cookies’ voor het analyseren van de gedragspatronen van bezoekers van deze site. Een
        cookie is een heel klein gegevensbestand dat op uw eigen PC wordt opgeslagen wanneer u onze website bezoekt. Als
        u niet wilt dat wij cookies gebruiken, moet u cookies uitschakelen in uw browserinstellingen of op de knop
        'cookies uit' klikken. Als u cookies accepteert, blijven deze op uw computer staan, tenzij u ze uit eigen
        beweging verwijdert. Het deactiveren van cookies kan uw gebruik van onze website en diensten beperken. </p>

    <h2>PRIVACYBELEID</h2>
    <p>Persoonlijke gegevens die u op deze website invoert worden niet bewaard door ORTEC.</p>
</div>
<div *ngIf="(lang$ | async) === 'en'" class="content">
    <p>Attention: Please read these terms carefully before using the U-Prevent calculation software (the "Tool"). Using
        the Tool indicates that you accept these terms ("Terms and Conditions").</p>

    <h2>INFORMATION ONLY</h2>
    <p>The Tool and the U-Prevent website are provided for information only. The Tool has been published with the
        intention that it will be useful, but without warranty, implied or explicit, of fitness for purpose. The Tool
        should not be considered as providing a definitive diagnosis or a recommendation to adopt any specific action or
        treatment. The information generated by the Tool is not a substitute for professional medical advice or care
        provided by a qualified doctor or other health care professional.</p>
    <p>Always check with your doctor if you have any concerns about your health, any condition or treatment. Never
        disregard professional medical advice or delay in seeking it because of information generated by the Tool or any
        information on this Website.</p>

    <h2>LIABILITY WAIVER</h2>
    <p>Use of the Tool, the results generated by the Tool and the information contained within and linked to this
        Website is at your own risk. Despite the care exercised by the authors when compiling the Tool and this website,
        errors and omissions are possible. With this in mind, ORTEC cannot accept any liability whatsoever toward any
        party.</p>
    <p>The Tool and the U-Prevent website are provided on an "as is" and "as available" basis without any representation
        or endorsement and ORTEC makes no warranties of any kind, whether express or implied, in relation to the Tool
        and/or the U-Prevent website, including but not limited to, implied warranties of satisfactory quality, fitness
        for a particular purpose, non-infringement, compatibility, security, accuracy, conditions of completeness, or
        any implied warranty arising from course of dealing or usage.</p>
    <p>ORTEC makes no warranty that the Tool and/or the U-Prevent website will meet your requirements or will be
        uninterrupted, timely, secure or error-free, that defects will be corrected, or that the Tool or Website or the
        server that makes them available are free of viruses or bugs or represents the full functionality, accuracy,
        reliability of the materials. ORTEC will not be responsible or liable to you for any loss of content or material
        uploaded or transmitted through the Website.</p>

    <h2>INDEMNITY</h2>
    <p>You agree to indemnify ORTEC and its officers, directors and employees, immediately on demand, against all
        claims, liability, damages, costs and expenses, including legal fees, arising out of any breach of these Terms
        and Conditions by you or any other liabilities arising out of your use of the Tool and/or this Website.</p>

    <h2>COPYRIGHT</h2>
    <p>All rights reserved. Materials on this Website are protected by copyright law. Nothing from this website may be
        reproduced, saved in an automated database, or published in any way or form whatsoever without the prior written
        consent of ORTEC.</p>

    <h2>THE RIGHTS OF ORTEC</h2>
    <p>ORTEC, reserves the right to modify or withdraw, temporarily or permanently, the Tool and/or this Website (or any
        part thereof) with or without notice to you and you confirm that ORTEC shall not be liable to you or any third
        party for any modification to or withdrawal of the Tool and/or this Website.</p>
    <p>ORTEC reserves the right to change the Terms and Conditions under which the Tool and Website are offered from
        time to time. Any such changes will be displayed on the Website. Your continued use of the Tool and/or the
        Website (or any part thereof) following such change shall be deemed to be your acceptance of such change. You
        should ensure that you visit the Website regularly as you are solely responsible for ensuring that you are aware
        of such changes which have been made to the Terms and Conditions.</p>

    <h2>GOVERNING LAW</h2>
    <p>These Terms and Conditions are governed by the laws of The Netherlands. You hereby consent to the exclusive
        jurisdiction of the Dutch courts in all disputes arising out of or relating to the use of the Tool and this
        Website. Use of the Tool and this Website is unauthorized in any jurisdiction that does not give effect to all
        provisions of these Terms and Conditions, including without limitation this paragraph. If any part of this
        agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to,
        the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision
        will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original
        provision and the remainder of the agreement shall continue in effect.</p>

    <h2>COOKIES</h2>
    <p>ORTEC use so-called cookies for the purposes of analyzing the behavior patterns of visitors to this site. A
        cookie is a very small data file stored on your own PC when visiting our website. If you do not wish for us to
        use cookies, then you will have to switch off cookies in your browser settings or click on the button ‘cookies
        off’. If you do accept cookies, then these will remain on your computer unless you remove them of your own
        accord. Deactivating cookies could restrict your use of our website and services.</p>

    <h2>PRIVACY POLICY</h2>
    <p>Any personal details you may enter on this website will not be stored.</p>
</div>

<div class="agree" *ngIf="showAgreeButton">
    <button (click)="agree()" class="btn btn-primary" data-cy="termsAgreeButton" translate>TOS.AGREE_BUTTON</button>
</div>

<div *ngIf="isConsentUnanswered$ | async" class="cookie-buffer" [ngStyle]="{'height': cookieHeight}"></div>