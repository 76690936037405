import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app-shared/shared.module';
import { LifeHFComponent } from './lifeHf.component';
import { toSurveyGuard } from './lifeHf.guard';

@NgModule({
    declarations: [LifeHFComponent],
    imports: [
        CommonModule,
        SharedModule,
        ReactiveFormsModule,
        RouterModule.forChild([
            {
                path: 'survey',
                canActivateChild: [toSurveyGuard],
                children: [
                    {
                        path: '',
                        component: LifeHFComponent,
                    },
                ],
            },
        ]),
    ],
})
export class LifeHFModule {}
