<div class="modal-consent">
  <div class="modal-consent__text">
    <h3 class="modal-consent__title" translate="COOKIE_CONSENT.TITLE"></h3>
    <p>
      <span translate="COOKIE_CONSENT.TEXT"></span>
      <a class="modal-consent__policy-link" routerLink="/view-terms" translate>COOKIE_CONSENT.MORE_INFO_LINK</a>
    </p>
  </div>
  <div class="d-grid gap-2">
    <button (click)="agree()" class="btn btn-primary" type="button" id="allowButton" translate>
      COOKIE_CONSENT.AGREE_BUTTON
    </button>
    <button (click)="decline()" class="btn btn-primary" type="button" id="declineButton" translate>
      COOKIE_CONSENT.DECLINE_BUTTON
    </button>
  </div>
</div>
