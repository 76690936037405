import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IsAuthenticatedUserPipe, IsVisitorUserPipe } from '@app-core/user-store/user.pipe';
import { IconsModule } from '@app-shared/icons/icons.module';
import { SharedModule } from '@app-shared/shared.module';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from '../../app-routing.module';
import { HomeNavigationComponent } from './home-button-navigation/home-navigation.component';
import { HomeComponent } from './home.component';
import { NewCalculatorsBannerComponent } from './new-calculators-banner/new-calculators-banner.component';
import { OrtecBannerComponent } from './ortec-banner/ortec-banner.component';
import { VtePredictBannerComponent } from './vte-predict-banner/vte-predict-banner.component';

@NgModule({
    declarations: [
        HomeComponent,
        HomeNavigationComponent,
        OrtecBannerComponent,
        NewCalculatorsBannerComponent,
        VtePredictBannerComponent
    ],
    imports: [
        IsAuthenticatedUserPipe,
        IsVisitorUserPipe,
        BrowserAnimationsModule,
        CommonModule,
        NgbModalModule,
        IconsModule,

        AppRoutingModule,
        SharedModule
    ]
})
export class HomeModule {
}
