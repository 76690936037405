<div *ngIf="improvement?.lowRisk">
  <div>
    <canvas class="small-chart" 
      baseChart 
      [type]="chartType" 
      [data]="lowRiskData"
      [options]="lowRiskOptions" 
      [legend]="false"
    ></canvas>
  </div>
  <div class="result">
    <span class="result__current-risk">
      <div class="result__current-risk-number">{{improvement?.lowRisk.risk | number:'1.1-1'}}%</div>
      <div><span translate>CALCULATORS.CURRENT_RISK</span> <cvrm-more-info [text]="LOW_CURRENT_RISK_INFO"></cvrm-more-info></div>
    </span>
    <span class="result__reduced-risk" >
      <div class="result__reduced-risk-number" [ngClass]="{result__positive: improvement?.lowRisk.treatmentBenefit > 0, result__negative: improvement?.lowRisk.treatmentBenefit  < 0}">{{lowRiskAbsTreatmentBenefit | number:'1.1-1'}}%</div>
      <div *ngIf="improvement?.lowRisk.treatmentBenefit >= 0"><span translate>CALCULATORS.REDUCED_RISK</span> <cvrm-more-info [text]="LOW_TREATMENT_REDUCTION_INFO"></cvrm-more-info></div>
      <div *ngIf="improvement?.lowRisk.treatmentBenefit < 0"><span translate>CALCULATORS.INCREASED_RISK</span> <cvrm-more-info [text]="LOW_TREATMENT_REDUCTION_INFO"></cvrm-more-info></div>
    </span>
    <span class="result__NNT" *ngIf="lowRiskAbsNumbersNeededToTreat">
      <div class="result__NNT-number" [ngClass]="{result__positive: improvement?.lowRisk.treatmentBenefit > 0, result__negative: improvement?.lowRisk.treatmentBenefit < 0}">{{lowRiskAbsNumbersNeededToTreat}}</div>
      <div *ngIf="improvement?.lowRisk.treatmentBenefit >= 0"><span>{{LOW_NNT_TITLE}}</span> <cvrm-more-info [text]="LOW_TREATMENT_BENEFIT_INFO"></cvrm-more-info></div>
      <div *ngIf="improvement?.lowRisk.treatmentBenefit < 0"><span>{{LOW_NNH_TITLE}}</span> <cvrm-more-info [text]="LOW_TREATMENT_BENEFIT_INFO"></cvrm-more-info></div>
    </span>
  </div>
  <hr class="graph-separation">
  <div>
    <canvas class="small-chart" 
      baseChart 
      [type]="chartType" 
      [data]="highRiskData"
      [options]="highRiskOptions" 
      [legend]="false"
    ></canvas>
  </div>
  <div class="result">
    <span class="result__current-risk">
      <div class="result__current-risk-number">{{improvement?.highRisk.risk | number:'1.1-1'}}%</div>
      <div><span translate>CALCULATORS.CURRENT_RISK</span> <cvrm-more-info [text]="HIGH_CURRENT_RISK_INFO"></cvrm-more-info></div>
    </span>
    <span class="result__reduced-risk" >
      <div class="result__reduced-risk-number" [ngClass]="{result__positive: improvement?.highRisk.treatmentBenefit > 0, result__negative: improvement?.highRisk.treatmentBenefit  < 0}">{{highRiskAbsTreatmentBenefit | number:'1.1-1'}}%</div>
      <div *ngIf="improvement?.highRisk.treatmentBenefit >= 0"><span translate>CALCULATORS.REDUCED_RISK</span> <cvrm-more-info [text]="HIGH_TREATMENT_REDUCTION_INFO"></cvrm-more-info></div>
      <div *ngIf="improvement?.highRisk.treatmentBenefit < 0"><span translate>CALCULATORS.INCREASED_RISK</span> <cvrm-more-info [text]="HIGH_TREATMENT_REDUCTION_INFO"></cvrm-more-info></div>
    </span>
    <span class="result__NNT" *ngIf="highRiskAbsNumbersNeededToTreat">
      <div class="result__NNT-number" [ngClass]="{result__positive: improvement?.highRisk.treatmentBenefit > 0, result__negative: improvement?.highRisk.treatmentBenefit < 0}">{{highRiskAbsNumbersNeededToTreat}}</div>
      <div *ngIf="improvement?.highRisk.treatmentBenefit >= 0"><span>{{HIGH_NNT_TITLE}}</span> <cvrm-more-info [text]="HIGH_TREATMENT_BENEFIT_INFO"></cvrm-more-info></div>
      <div *ngIf="improvement?.highRisk.treatmentBenefit < 0"><span>{{HIGH_NNH_TITLE}}</span> <cvrm-more-info [text]="HIGH_TREATMENT_BENEFIT_INFO"></cvrm-more-info></div>
    </span>
  </div>
</div>

