import { ErrorHandler, Injectable } from '@angular/core';
import { LoggingService } from './logging.service';

@Injectable()
export class ErrorHandlerService implements ErrorHandler{

    constructor(private readonly loggingService: LoggingService)
    {
    }

    public handleError(error: Error): void {
        this.loggingService.logException(error);
    }
}
