<div>
  <canvas class="small-chart" 
    baseChart 
    [type]="chartType" 
    [data]="data"
    [options]="options" 
    [legend]="false">
  </canvas>
</div>
<div class="result">
    <span class="result__current-risk">
      <div class="result__current-risk-number" [attr.data-cy]="'statisticsCurrentRisk' + this.type + 'Value'">{{risks?.risk | number:'1.1-1'}}%</div>
      <div>
        <span translate [attr.data-cy]="'statisticsCurrentRisk' + this.type + 'Label'"> CALCULATORS.CURRENT_RISK</span>
        <cvrm-more-info [text]="CURRENT_RISK_INFO"></cvrm-more-info></div>
    </span>
    <span class="result__reduced-risk" >
      <div class="result__reduced-risk-number" [attr.data-cy]="'statisticsReducedRisk' + this.type + 'Value'" 
        [ngClass]="{result__positive: risks?.treatmentBenefit > 0, result__negative: risks?.treatmentBenefit  < 0}">{{absTreatmentBenefit | number:'1.1-1'}}%</div>
      <div *ngIf="risks?.treatmentBenefit >= 0" >
        <span translate [attr.data-cy]="'statisticsReducedRisk' + this.type + 'Label'" >CALCULATORS.REDUCED_RISK</span> 
        <cvrm-more-info [text]="TREATMENT_REDUCTION_INFO"></cvrm-more-info></div>
      <div *ngIf="risks?.treatmentBenefit < 0">
        <span translate [attr.data-cy]="'statisticsReducedRisk' + this.type + 'Label'" >CALCULATORS.INCREASED_RISK</span> 
        <cvrm-more-info [text]="TREATMENT_REDUCTION_INFO"></cvrm-more-info></div>
    </span>
    <span class="result__NNT" *ngIf="absNumbersNeededToTreat">
      <div class="result__NNT-number" [attr.data-cy]="'statisticsNnt' + this.type + 'Value'" 
        [ngClass]="{result__positive: risks?.treatmentBenefit > 0, result__negative: risks?.treatmentBenefit < 0}">{{absNumbersNeededToTreat}}</div>
      <div *ngIf="risks?.treatmentBenefit >= 0" [attr.data-cy]="'statisticsNnt' + this.type + 'Label'"><span>
        {{NNT_TITLE}}</span> <cvrm-more-info [text]="TREATMENT_BENEFIT_INFO"></cvrm-more-info></div>
      <div *ngIf="risks?.treatmentBenefit < 0" [attr.data-cy]="'statisticsNnt' + this.type + 'Label'"><span>
        {{NNH_TITLE}}</span> <cvrm-more-info [text]="TREATMENT_BENEFIT_INFO"></cvrm-more-info></div>
    </span>
</div>
