<ng-container *ngIf="user$ | async as user">
	<ng-container *ngIf="translationLoaded$ | async">
		<div class="main-container">
			
			<app-header [user]="user"
				[site]="site$ | async"
				[isMdrCertified]="isMdrCertified$ | async"
				[availableLanguages]="availableLanguages$ | async"
				[showLifeHfStartPage]="showLifeHfStartPage$ | async">
			</app-header>
			
			<ng-container *ngIf="(showLifeHfStartPage$ | async); else showSite">
				<app-lifehf-starting-page></app-lifehf-starting-page>
			</ng-container>
			
			<ng-template #showSite>
				<div class="main" [ngClass]="{'main__nonmdr': (isMdrCertified$ | async) === false}">
					<bol-callout *ngIf="(user.type | isLoginUser) && !user.country"
						class="call-out__info"
						icon="warning"
						type="error"> 
						{{'USER_PROFILE.MISSING_COUNTRY' | translate}}
					</bol-callout>
					<bol-callout *ngIf="noAvailableLanguages$ | async"
						class="call-out__info"
						icon="warning"
						type="error"> 
						{{'LANGUAGE_NOT_SUPPORTED.COUNTRY' | translate}}
					</bol-callout>
					<bol-callout *ngIf="currentLanguageNotSupported$ | async"
						class="call-out__info"
						icon="warning"
						type="error"> 
						{{'LANGUAGE_NOT_SUPPORTED.LANG' | translate}}
					</bol-callout>
					
					<router-outlet></router-outlet>				
				</div>
			</ng-template>
		</div>

		<app-consent *ngIf="unansweredCookieConsent$ | async" id='cookie-consent' infoTitle="COOKIE_CONSENT.TITLE" 
			agreeBtnText="COOKIE_CONSENT.AGREE_BUTTON" agreeBtnId="allowButton" (agreed)="agreeCookie()"
			declineBtnText="COOKIE_CONSENT.DECLINE_BUTTON" declineBtnId="declineButton" (declined)="declineCookie()">
			<p>
				<span translate="COOKIE_CONSENT.TEXT"></span>
				<a class="consent__policy-link" routerLink="/view-terms" translate="COOKIE_CONSENT.MORE_INFO_LINK"></a>
			</p>
		</app-consent>

		<ng-container *ngIf="appVersion$ | async as appVersion">
			<app-consent *ngIf="shouldReadUpdate$ | async" id='update-consent' infoTitle="U-Prevent Update {{appVersion}}" 
				agreeBtnText="FIELDS.YES" agreeBtnId="allowUpdateButton" (agreed)="agreeReadUpdate()"
				declineBtnText="FIELDS.NO" declineBtnId="declineUpdateButton" (declined)="declineReadUpdate()">
			<p>
				<span translate="READ_UPDATE"></span>
			</p>
			</app-consent>
		</ng-container>

		<div *ngIf="!isProd" class="show-test-env-warning">
		{{ 'TEST_ENVIRONMENT_WARNING' | translate }}
		</div>
	</ng-container>
	<soca-test-border *ngIf="!isProd"></soca-test-border>
</ng-container>
