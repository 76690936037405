import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule, NgZone } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ErrorComponent } from '@app-core/error/error.component';
import { MdrCertifiedGuard } from '@app-core/guard/mdr-certified.guard';
import { HeaderComponent } from '@app-core/header/header.component';
import { InformationComponent } from '@app-core/information/information.component';
import { LabelComponent } from '@app-core/label/label.component';
import { LogoutComponent } from '@app-core/logout/logout.component';
import { PageNotFoundComponent } from '@app-core/page-not-found/page-not-found.component';
import { PrivacyStatementComponent } from '@app-core/privacy-statement/privacy-statement.component';
import { isDefaultSite } from '@app-core/privacy-statement/privacy-statement.guard';
import { SessionExpiredComponent } from '@app-core/session-expired/session-expired.component';
import { TabClosedComponent } from '@app-core/tab-closed/tab-closed.component';
import { IsAuthenticatedUserPipe, IsConnectUserPipe, IsLoginUserPipe } from '@app-core/user-store/user.pipe';
import { AuthorizeComponent } from '@app-features/authorize/authorize/authorize.component';
import { LoginComponent } from '@app-features/authorize/login/login.component';
import { UserProfileComponent } from '@app-features/authorize/user-profile/user-profile.component';
import { isLoginUserGuard } from '@app-features/authorize/user-profile/user-profile.guard';
import { CalculatorsModule } from '@app-features/calculators/calculators.module';
import { ContactModule } from '@app-features/contact/contact.module';
import { HomeComponent } from '@app-features/home/home.component';
import { HomeModule } from '@app-features/home/home.module';
import { LifeHFModule } from '@app-features/lifeHf/lifeHf.module';
import { DialogComponent } from '@app-shared/components/dialog-component/dialog.component';
import { DisableComponent } from '@app-shared/components/disable-component/disable.component';
import { StartingPageComponent } from '@app-shared/components/starting-page/starting-page.component';
import { IconsModule } from '@app-shared/icons/icons.module';
import { BroadcastTypes } from '@app-shared/services/authorization.service';
import { ErrorHandlerService } from '@app-shared/services/error-handler.service';
import { TabsService } from '@app-shared/services/tabs.service';
import { getCallbackUrl } from '@app-shared/services/url.service';
import { SharedModule } from '@app-shared/shared.module';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BolCalloutModule } from '@ortec/bolster/callout';
import { BolMenuModule } from '@ortec/bolster/menu';
import { API_SERVICE_OPTIONS, ApiServiceOptions, BroadcastService, SocaWebUiCommonNonMaterialModule } from '@ortec/soca-web-ui';
import {
    AUTHENTICATION_SERVICE_OPTIONS,
    AuthenticationService,
    IDENTITY_DATA_SERVICE_OPTIONS,
    IdentityDataServiceOptions
} from '@ortec/soca-web-ui/identity';
import { CallbacksPerMessageType } from '@ortec/soca-web-ui/lib/services/broadcast-declarations';
import { AppRoutingModule } from 'app/app-routing.module';
import { AppComponent } from 'app/app.component';
import { AppInitService } from 'app/app.initializer';
import { AuthenticationHttpInterceptor } from 'app/http-interceptors/authentication-interceptor';
import { AuthOptions } from 'auth0-js';
import { environment } from 'environments/environment';
import { EnvironmentName } from 'environments/environment.config';
import { Observable } from 'rxjs';

const authenticationServiceOptions: AuthOptions = {
    clientID: environment.requiresAuthenticationConfig.authConfig.clientID,
    domain: environment.requiresAuthenticationConfig.authConfig.domain,
    responseType: environment.requiresAuthenticationConfig.authConfig.responseType,
    redirectUri: getCallbackUrl(),
    scope: environment.requiresAuthenticationConfig.authConfig.scope
};
const apiServiceOptions: ApiServiceOptions = {
    apiUrl: environment.configuration.apiUrl,
};

const identityDataServiceOptions: IdentityDataServiceOptions = {
    apiUrl: environment.configuration.apiUrl,
    useLocalLogin: false,
    broadcastLogoutMessage: true
};

export const initializeApp = (appInitService: AppInitService) => {
    return (): Promise<unknown> => {
        return appInitService.init();
    };
};

export const HttpLoaderFactory = (httpClient: HttpClient): TranslateHttpLoader => {
    return new TranslateHttpLoader(httpClient, './assets/i18n/', `.json?v=${environment.timeStamp}`);
};

const broadcastServiceFactory = (zone: NgZone, injector: Injector): BroadcastService => {
    const callbacksPerMessageType: CallbacksPerMessageType = {};

    callbacksPerMessageType[BroadcastTypes.Login] = () => injector.get(TabsService).authenticateUser();
    callbacksPerMessageType[BroadcastTypes.Logout] = () => injector.get(TabsService).endUserSession();
    callbacksPerMessageType[BroadcastTypes.ResultTabOpen] = () => injector.get(TabsService).closeCurrentTabIfAlsoOnResultPage();
    callbacksPerMessageType[BroadcastTypes.TokenIsRefreshing] = () => injector.get(TabsService).tokenIsRefreshing();
    callbacksPerMessageType[BroadcastTypes.RescheduleRefreshToken] = () => injector.get(TabsService).rescheduleRefreshToken();

    return new BroadcastService(callbacksPerMessageType, zone);
};

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        ErrorComponent,
        PageNotFoundComponent,
        InformationComponent,
        LabelComponent,
        LogoutComponent,
        SessionExpiredComponent,
        TabClosedComponent
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        AuthenticationService,
        { provide: API_SERVICE_OPTIONS, useValue: apiServiceOptions },
        { provide: AUTHENTICATION_SERVICE_OPTIONS, useValue: authenticationServiceOptions },
        { provide: IDENTITY_DATA_SERVICE_OPTIONS, useValue: identityDataServiceOptions },
        { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppInitService, HttpClient], multi: true },
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        { provide: HTTP_INTERCEPTORS, useExisting: AuthenticationHttpInterceptor, multi: true },
        { provide: Observable, useValue: {} },
        {
            provide: BroadcastService,
            useFactory: broadcastServiceFactory,
            deps: [NgZone, Injector]
        }
    ],
    imports: [
        FontAwesomeModule,
        // Add routes to app initializer
        RouterModule.forRoot([
            {
                path: 'home',
                pathMatch: 'full',
                redirectTo: '',
            },
            {
                path: 'authenticate',
                component: LoginComponent,
            },
            {
                path: 'authorize',
                component: AuthorizeComponent,
            },
            {
                path: 'user-profile',
                component: UserProfileComponent,
                canActivate: [isLoginUserGuard]
            },
            {
                path: '',
                component: HomeComponent,
                data: {
                    title: 'NAV.HOME',
                }
            },
            {
                path: 'authorize',
                component: AuthorizeComponent,
            },
            {
                path: '',
                component: HomeComponent,
                data: {
                    title: 'NAV.HOME',
                },
            },
            {
                path: 'information',
                component: InformationComponent,
                canActivate: [MdrCertifiedGuard]
            },
            {
                path: 'error',
                component: ErrorComponent,
            },
            {
                path: 'logout',
                component: LogoutComponent,
            },
            {
                path: 'privacy-statement',
                component: PrivacyStatementComponent,
                canActivate: [isDefaultSite]
            },
            {
                path: 'session-expired',
                component: SessionExpiredComponent,
            },
            {
                path: 'tab-closed',
                component: TabClosedComponent,
            },
            {
                path: '**',
                component: PageNotFoundComponent,
            },
        ],
        { scrollPositionRestoration: 'top' }
        ),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        HttpClientModule,
        BrowserModule,
        HomeModule,
        MatMenuModule,
        BolCalloutModule,
        BolMenuModule,
        CalculatorsModule,
        ContactModule,
        LifeHFModule,
        AppRoutingModule,
        SharedModule,
        IconsModule,
        MatIconModule,
        BrowserAnimationsModule,
        SocaWebUiCommonNonMaterialModule,
        NgbModule,
        NgIdleKeepaliveModule.forRoot(),
        environment.name === EnvironmentName.Test ? AkitaNgDevtools.forRoot() : [],
        IsLoginUserPipe,
        IsConnectUserPipe,
        MatSelectModule,
        IsAuthenticatedUserPipe,
        MatButtonModule,
        DisableComponent,
        DialogComponent,
        StartingPageComponent
    ]
})
export class AppModule { }
