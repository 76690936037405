import { Component } from '@angular/core';
import { GeneralQuery } from '@app-core/general-store/general.query';
import { getSupportedLanguageList, Lang } from '@app-core/user-store/user.model';
import { UserQuery } from '@app-core/user-store/user.query';
import { VideoPlayerComponent } from '@app-shared/components/video-player/video-player.component';
import { Site } from '@app-shared/models/site-configuration-enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map, Observable } from 'rxjs';

@Component({
    selector: 'cvrm-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent {
    private readonly links: Record<string, Record<string, string>> = {
        nl: {
            UMC_UTRECHT: 'https://www.umcutrecht.nl/nl/Ziekenhuis/Afdelingen/Hart-en-vaatcentrum',
            UMC_UTRECHT_SPECIALISM: 'https://www.umcutrecht.nl/nl/Ziekenhuis/Afdelingen/Hart-en-vaatcentrum/Specialismen/Vasculaire-geneeskunde',
            HARTSTICHTING: 'https://www.hartstichting.nl/',
            ZONMW: 'https://www.zonmw.nl/nl/',
        },
        en: {
            UMC_UTRECHT: 'https://www.umcutrecht.nl/en/Research/Strategic-themes/Circulatory-Health',
            UMC_UTRECHT_SPECIALISM: 'https://www.umcutrecht.nl/en/Research/Strategic-themes/Circulatory-Health',
            HARTSTICHTING: 'https://www.hartstichting.nl/',
            ZONMW: 'https://www.zonmw.nl/en/',
        },
    };
    
    private supportedLanguages = getSupportedLanguageList();

    public readonly site$: Observable<string> = this.generalQuery.selectSite();
    public Site = Site;
    public lang$ = this.userQuery.selectLang();
    public isEnglishLang$ = this.lang$.pipe(map(lang => lang === Lang.English));
    public userType$ = this.userQuery.selectUserType();
    public gracePeriod$ = this.generalQuery.selectFeatures().pipe(map(features => features.requiresAuthentication?.gracePeriod));
    
    constructor(
        private readonly modalService: NgbModal, 
        private readonly userQuery: UserQuery,
        private readonly generalQuery: GeneralQuery
    ) { }

    public onOpenModal(videoSrc: string): void {
        const modalRef = this.modalService.open(VideoPlayerComponent, { backdrop: false });
        modalRef.componentInstance.videoSrc = `${videoSrc}-${this.userQuery.getLang()}.mp4`;
    }

    public getLink(key: string): string {
        const userLang = this.userQuery.getLang();
        const lang = this.supportedLanguages.includes(userLang) ? userLang : Lang.English;
        return this.links[lang][key];
    }
}
