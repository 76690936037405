
export enum CookieGroup {
    StrictlyNecessaryCookies = 'StrictlyNecessaryCookies',
    PerformanceCookies = 'PerformanceCookies',
    FunctionalCookies = 'FunctionalCookies',
    TargetingCookies = 'TargetingCookies'
}

export enum NoticeUrl {
    Production = 'https://privacyportalde-cdn.onetrust.com/02829fd8-b531-4ce0-b3e0-e9cfe05ed841/privacy-notices/cbb31236-3301-478f-9a73-fa3191dc759d.json',
    Development = 'https://privacyportalde-cdn.onetrust.com/02829fd8-b531-4ce0-b3e0-e9cfe05ed841/privacy-notices/draft/cbb31236-3301-478f-9a73-fa3191dc759d.json'
}

export interface OneTrust {
    // These properties are defined in the OneTrust script. The names of these properties must therefore
    // exactly match the definition in the script. Do not change these without checking the OneTrust script.
    NoticeApi: {
        Initialized: boolean;
        LoadNotices(policyUrl: Array<string>, isDefault: boolean, lang: string): void;
    };
    changeLanguage: (language: string) => void;
    ToggleInfoDisplay: () => void;
    AllowAll: () => void;
    RejectAll: () => void;
    LoadBanner: () => void;
    GetDomainData: () => any;
    OnConsentChanged: (callback: () => void) => void;
}
