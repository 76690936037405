import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserType } from '@app-core/user-store/user.model';
import { IToken } from '@app-features/authorize/auth0.model';
import { ApplicationInsights, IExceptionTelemetry } from '@microsoft/applicationinsights-web';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { ApiBaseUrl } from './url.service';

@Injectable({
    providedIn: 'root',
})
export class LoggingService {
    private appInsights: ApplicationInsights;
    private readonly LOCAL_STORAGE_UUID = 'applicationInsightsID';
    private INSTRUMENTATION_KEY: string;
    public isApplicationInsightsLoaded = new BehaviorSubject<boolean>(false);

    constructor(private readonly http: HttpClient) 
    { }

    public setInstrumentationKey(): Observable<string> {
        return this.http.get(ApiBaseUrl('/application/GetInstrumentationKey'), { responseType: 'text' }).pipe(
            tap(instrumentationKey => {
                this.INSTRUMENTATION_KEY = instrumentationKey;
            })
        );
    }

    public configureAppInsights(cookieConsent: boolean): void {
        if (this.appInsights) {
            this.appInsights.unload();

            if (!cookieConsent) {
                this.appInsights.core.getCookieMgr().purge('ai_session');
            }
        }

        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: this.INSTRUMENTATION_KEY,
                enableAutoRouteTracking: true,
                disableTelemetry: !cookieConsent,
                disableCookiesUsage: !cookieConsent,
                isStorageUseDisabled: !cookieConsent,
                cookieCfg: {
                    enabled: cookieConsent
                }
            }
        });

        this.appInsights.loadAppInsights();
        this.isApplicationInsightsLoaded.next(true);
    }

    public logException(error: Error): void {
        const exception: IExceptionTelemetry = { exception : error };

        if (this.appInsights) {
            this.appInsights.trackException(exception);
        }
    }

    public addCustomMetrics(token: IToken): void {
        const props: Record<string, string> = {};
        props["ApplicationPlatform"] = 'WEB';
        props["ApplicationName"] = 'U-Prevent';

        let storedId = window.localStorage.getItem(this.LOCAL_STORAGE_UUID);

        if (storedId == null) {
            storedId = uuidv4();
            window.localStorage.setItem(this.LOCAL_STORAGE_UUID, storedId);
        }

        if (token != null) {
            props["UserType"] = token.userType;

            if (token.userType === UserType.Login) {
                props["UserId"] = token.userId;
                storedId = token.userId;
            }

            if (token.userType === UserType.Connect) {
                props["Organization"] = token.organization;
            }
        } else {
            props["UserType"] = UserType.Visitor;
        }

        this.addCustomTelemetryProperty(props);
        this.appInsights.setAuthenticatedUserContext(storedId);
    }

    private addCustomTelemetryProperty(props: Record<string, string> = null): void
    {
        this.appInsights.addTelemetryInitializer(envelope =>
        {
            const item = envelope.baseData;
            item.properties = item.properties || {};
            if (envelope.baseType === 'PageviewData') {
                item.name = adjustPageName(item);
            }

            item.properties = { ...item.properties, ...props };
        });
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const adjustPageName = (item: Record<string, any>): string => {
    return item.uri.split('/').pop() || 'U-Prevent';
};
