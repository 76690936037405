import { Component, Input } from '@angular/core';
import { UserType } from '@app-core/user-store/user.model';


@Component({
    selector: 'app-home-navigation',
    templateUrl: './home-navigation.component.html',
    styleUrls: ['./home-navigation.component.scss']
})
export class HomeNavigationComponent {
    @Input() public userType: UserType;
    @Input() public gracePeriod: boolean;
}