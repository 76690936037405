<table class="situation-table">
    <thead>
        <tr>
            <td></td>
            <td class="value">
                <div></div>
            </td>
            <td class="unit"></td>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of internalCollection; let i = index">
            <td class="label">{{item[keyName]}} </td>
            <td class="value" [ngClass]="{
                    'too-low': keyValueIndicator !== undefined && item[keyValueIndicator] === ValueIndicator.TooLow,
                    'too-high': keyValueIndicator !== undefined && item[keyValueIndicator] === ValueIndicator.TooHigh,
                    'overridden-value': item.autoFilled === AutoFillIntake.OverriddenValue,
                    'original-value': item.autoFilled === AutoFillIntake.OriginalValue
                }">
                <img src="/assets/imputed.svg" *ngIf="isImputed(item)" width="16" (click)="showValue(i)" (mouseover)="showValue(i)" (mouseout)="hideValue()" />
                <fa-icon icon="ellipsis-h" *ngIf="isTooLong(item)" (click)="showValue(i)" (mouseover)="showValue(i)" (mouseout)="hideValue()">
                </fa-icon>
                <span *ngIf="!item.shortLabel && isAbbreviated(item) && !isImputed(item)" (click)="showValue(i)" (mouseover)="showValue(i)"
                    (mouseout)="hideValue()">
                    {{item.abbreviatedValue}}
                </span>
                <span *ngIf="!item.shortLabel && isUnabbreviated(item) && !isImputed(item)">
                    {{item.value}}
                </span>
                <span *ngIf="item.shortLabel && !isImputed(item)" (click)="showValue(i)" (mouseover)="showValue(i)"
                    (mouseout)="hideValue()">
                    <span translate>{{item.shortLabel}}</span>*
                </span>
                <span class="hovered-value" [ngClass]="{'hovered-value--displayed': displayedIndex === i}">
                    <span *ngIf="isImputed(item)" >
                       {{imputedResultText}}
                    </span>
                    <!-- if the imputed unit is %, imputed value is in decimal e.g. 0.24 instead of 24%  -->
                    <span>{{ item.imputed && (item.imputedUnit != null && item.imputedUnit !== '%') ? item.imputedValue : item.value }}</span>
                    <span *ngIf="item[keyImputedUnit]"> {{item[keyImputedUnit]}}</span>
                    <span *ngIf="item[keyImputedLowerBound]"> [{{item[keyImputedLowerBound]}}, {{item[keyImputedUpperBound]}}]</span>
                </span>
            </td>
            <td class="unit" *ngIf="keyUnit !== undefined && !item.imputed">
                {{item[keyUnit]}}
            </td>
        </tr>
    </tbody>
    <tfoot>
        <tr>
            <td></td>
            <td class="value">
                <div></div>
            </td>
            <td></td>
        </tr>
    </tfoot>
</table>