<div class="title">
  <h3>
    <span translate>RESULTS.CURRENT_SITUATION</span>
    <a href="#" (click)="toDescription($event)" class="description-link">
      <fa-icon icon="info-circle"></fa-icon>
    </a>
  </h3>
<div class="intake columns">
  <div class="column" *ngFor="let column of patient">
    <cvrm-situation-table
      *ngFor="let collection of column"
      [collection]="collection"
      [isCalculatedImputationsEnabled]="isCalculatedImputationsEnabled"
      keyUnit="unit"
      keyValueIndicator="valueIndicator">
    </cvrm-situation-table>
  </div>
</div>

<div class="printable-intake" *ngIf="patient">
  <div class="columns">
    <div class="column" *ngFor="let column of patient">
      <cvrm-situation-table-print
        *ngFor="let collection of column"
        [collection]="collection"
        keyUnit="unit"
        keyValueIndicator="valueIndicator">
      </cvrm-situation-table-print>
    </div>
  </div>
</div>
