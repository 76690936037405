import { MarkedOptions, MarkdownModule } from 'ngx-markdown';
import { SharedModule } from '@app-shared/shared.module';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule, SecurityContext } from '@angular/core';
import { NgDompurifyModule, DOMPURIFY_CONFIG, DOMPURIFY_HOOKS } from '@tinkoff/ng-dompurify';
import { ContentHostComponent } from '@app-features/content/content-host/content-host.component';
import { ContentComponent } from '@app-features/content/content.component';

const YOUTUBE_EMBED_REGEX = new RegExp('(https://www.youtube-nocookie.com/embed/[\\w|\\-]{11})');

export const markedOptionsFactory = (): MarkedOptions => {
    return {
        gfm: true,
        breaks: false,
        pedantic: false,
        smartLists: true,
        smartypants: false,
    };
};

export const sanitizeYoutubeEmbedIframes = (currentNode: Element): void=> {
    if (currentNode.nodeName !== 'IFRAME') {
        return;
    }

    const youtubeEmbedMatch = currentNode.getAttribute('src').match(YOUTUBE_EMBED_REGEX);
    if (youtubeEmbedMatch) {
        try {
            currentNode.setAttribute('src', youtubeEmbedMatch[1]);

            return;
        } catch {}
    } else {
        try {
            currentNode.parentNode.removeChild(currentNode);
        } catch (e) {}
    }
};

@NgModule({
    declarations: [ContentHostComponent, ContentComponent],
    imports: [
        CommonModule,
        SharedModule,
        NgDompurifyModule,
        MarkdownModule.forRoot({
            loader: HttpClient,
            sanitize: SecurityContext.NONE,
            markedOptions: {
                provide: MarkedOptions,
                useFactory: markedOptionsFactory,
            },
        }),
    ],
    providers: [
        {
            provide: DOMPURIFY_CONFIG,
            useValue: {
                ADD_TAGS: ['iframe'],
                ADD_ATTR: ['target']
            },
        },
        {
            provide: DOMPURIFY_HOOKS,
            useValue: [
                {
                    name: 'beforeSanitizeAttributes',
                    hook: sanitizeYoutubeEmbedIframes,
                },
            ],
        },
    ],
    exports: [ContentHostComponent]
})
export class ContentModule {}
