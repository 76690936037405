<cvrm-page-wave-background color="blue" *ngIf="user$ | async as user">
    <div class="header">
        <div class="header-actions">
            <button mat-button class="btn btn-white btn-small" (click)="downloadInformation()">
                <span class="material-icons"> download </span>
            </button>
            <button mat-button class="btn btn-red btn-small" (click)="deleteAccount()">
                <span class="material-icons"> delete </span>
            </button>
        </div>

        <div class="header-title">
            <h1 *ngIf="hasCompletedRegistration$ | async; else finishRegistration" translate>USER_PROFILE.PROFILE</h1>
            <ng-template #finishRegistration>
                <h1 translate>USER_PROFILE.FINISH_REGISTRATION</h1>
            </ng-template>
        </div>
    </div>

    <div class="container">
        <div class="form-container">
            <form class="form" [formGroup]="form" (submit)="submit()">
                <div class="group">
                    <mat-label class="group-label" translate>USER_PROFILE.PROF_FULL_NAME</mat-label>
                    <div class="group-fields">
                        <input class="form-control" matInput formControlName="professionalFullName" data-cy="professionalFullName" required>
                        <div *ngIf="form.controls.professionalFullName.invalid" class="invalid">
                            {{ 'VALIDATIONS.REQUIRED' | translate }}
                        </div>   
                    </div>
                </div>

                <div class="group">
                    <mat-label class="group-label" translate>USER_PROFILE.EMAIL</mat-label>
                    <input class="form-control group-fields-with-btn" matInput formControlName="email" data-cy="email" required>
                    <button mat-button class="btn btn-white btn-small" type="button" (click)="updateEmail()">
                        <span class="material-icons"> edit </span>
                    </button>
                </div>

                <div class="group">
                    <mat-label class="group-label" translate>USER_PROFILE.REGISTRATION_NUMBER</mat-label>
                    <div class="group-fields">
                        <input class="form-control" matInput formControlName="registrationNumber" data-cy="registrationNumber">
                    </div>
                </div>
                
                <div class="group">
                    <div class="group-label">
                        <mat-label translate>USER_PROFILE.COUNTRY</mat-label>
                        <p class="group-note" translate>COUNTRY_NOTE</p>
                    </div>

                    <div class="group-fields">
                        <mat-select class="form-control" formControlName="country" data-cy="country" required>
                            <input [placeholder]=" 'USER_PROFILE.SEARCH' | translate" class="form-control group-input-select" matInput (keyup)="filterCountryList($event.target.value)">
                            <mat-option *ngFor="let option of countryOptions" [value]="option.value">{{ option.label }}</mat-option>
                        </mat-select>
                        <div *ngIf="form.controls.country.invalid" class="invalid">
                            {{ 'VALIDATIONS.REQUIRED' | translate }}
                        </div>
                        <div *ngIf="restOfTheWorldWarning$ | async" class="invalid">
                            {{ 'LANGUAGE_NOT_SUPPORTED.ROTW_WARNING' | translate }}
                        </div>  
                        <div *ngIf="noLanguageSupport$ | async" class="invalid">
                            {{ 'LANGUAGE_NOT_SUPPORTED.COUNTRY' | translate }}
                        </div>    
                    </div>
                </div>

                <div class="group">
                    <mat-label class="group-label" translate>USER_PROFILE.LANGUAGE</mat-label>
                    <div class="group-fields">
                        <mat-select class="form-control" formControlName="preferredLanguage" data-cy="lang" required>
                            <mat-option *ngFor="let option of langOptions" [value]="option.value">{{ option.label }}</mat-option>
                        </mat-select>
                        <div *ngIf="form.controls.preferredLanguage.invalid" class="invalid">
                            {{ 'VALIDATIONS.REQUIRED' | translate }}
                        </div>
                        <div *ngIf="unsupportedLanguage$ | async" class="invalid">
                            {{ 'LANGUAGE_NOT_SUPPORTED.LANG' | translate }}
                        </div>  
                    </div>
                </div>

                <div class="group">
                    <mat-checkbox class="form-control group-fields-checkbox" formControlName="emailNotificationConsentGiven" data-cy="emailNotificationConsent">
                        <mat-label class="group-label" translate>USER_PROFILE.MARKETING_EMAIL_CONSENT</mat-label>                    
                    </mat-checkbox>
                </div>

                <div class="group">
                    <mat-checkbox class="form-control group-fields-checkbox" formControlName="privacyStatement" data-cy="privacyStatement">
                        <mat-label class="group-label" translate>USER_PROFILE.PRIVACY_STATEMENT.TEXT</mat-label>

                        <a class="privacy-statement" routerLink="/privacy-statement" target="_blank" translate>USER_PROFILE.PRIVACY_STATEMENT.LINK</a>
                    </mat-checkbox>
                </div>

                <div class="center center-submit bol-button-spinner">
                    <div class="submit-error" *ngIf="form.invalid && FORM_IS_INVALID" translate="USER_PROFILE.MISSING_DATA"></div>
                    <div class="bol-button-spinner-container" *ngIf="(saveInProgress$ | async)">
                        <bol-spinner [isComplete]="(saveIsFinalizing$ | async)"></bol-spinner>
                    </div>
                    <div>
                        <button mat-button [disabled]="(saveInProgress$ | async)" class="btn btn-white" translate="USER_PROFILE.SAVE" type="submit"></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</cvrm-page-wave-background>
<cvrm-footer-wave-background color="blue"></cvrm-footer-wave-background>