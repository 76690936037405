import { Injectable } from '@angular/core';
import { GeneralQuery } from '@app-core/general-store/general.query';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class MdrCertifiedGuard {   
    constructor(private readonly generalQuery: GeneralQuery) { }

    public canActivate(): Observable<boolean> {
        return this.generalQuery.isMdrCertified();
    }
}
