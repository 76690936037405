import { Injectable} from '@angular/core';

import { Query } from '@datorama/akita';
import { Observable, map } from 'rxjs';
import { ContentState, ContentStore, Content } from './content.store';

@Injectable({
    providedIn: 'root'
})
export class ContentQuery extends Query<ContentState> {
    constructor(protected store: ContentStore) {
        super(store);
    }

    public isNotLoaded(document: string, lang: string): boolean {
        const content = (this.getLang(lang) || {})[document];

        return content ? content.state !== 'loaded' : true;
    }

    public selectContent(document: string, lang: string): Observable<Content> {
        return this.select()
            .pipe(map((state: any) => {
                return state[lang][document] || {data: null, state: 'not found'};
            }));
    }

    private getLang(lang: string): Record<string, Content> {
        switch (lang) {
            case 'en':
                return this.getValue().en;
            case 'nl':
                return this.getValue().nl;
            default:
                return this.getValue().en;
        }
    }
}