import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable, filter, map } from 'rxjs';

import { FormlyFieldConfig } from '@ngx-formly/core';
import { PatientData, PatientDataField, PatientDataRequest, PatientModel, getDataForCalculator } from './patient-data.model';
import { PatientDataStore } from './patient-data.store';

@Injectable({ providedIn: 'root' })
export class PatientDataQuery extends Query<PatientData> {
    constructor(
        protected patientDataStore: PatientDataStore
    ) {
        super(patientDataStore);
    }

    public getPatientDataRequest(): PatientDataRequest {
        return this.getValue()?.id;
    }

    public isPrefillFeatureOn(): boolean {
        return this.getValue().prefill;
    }

    public isPreloadFeatureOn(): boolean {
        return this.getValue().id?.preload ?? false;
    }

    public isDataLoadedForCalculator(calculatorId: string): boolean {
        return this.getDataForCalculator(calculatorId) != null;
    }

    public hasPatientDataRequest(): boolean {
        return this.getPatientDataRequest() != null;
    }

    public getOriginalPatientDataField(calculatorId: string, field: string): PatientDataField {
        const patientDataFields = this.getDataForCalculator(calculatorId);
        if (!patientDataFields) {
            return null;
        }

        return { ...patientDataFields[field], overriddenValue: null };
    }

    public selectPatientData(): Observable<PatientData> {
        return this.select();
    }

    public selectDataForCalculator(calculatorId: string): Observable<Record<string, PatientDataField>> {
        return this.select().pipe(
            map(patientData =>
                patientData?.dataForCalculator.find(d => d.calculatorId === calculatorId)?.data),
        );
    }

    public selectPrefillFeature(): Observable<boolean> {
        return this.select().pipe(map(patientData => patientData.prefill));
    }

    public selectPatientDataField(calculatorId: string, field: string): Observable<PatientDataField> {
        return this.selectDataForCalculator(calculatorId).pipe(
            map(data => data != null ? data[field] : null)
        );
    }

    public getOriginalPatientModel(calculatorId: string, fields: Array<FormlyFieldConfig>): PatientModel {
        const dataForCalculator = this.getDataForCalculator(calculatorId);

        return dataForCalculator != null
            ? this.createPatientModelFromCalculatorFields(dataForCalculator, fields, true)
            : null;
    }

    public selectPatientModel(calculatorId: string, fields: Array<FormlyFieldConfig>): Observable<PatientModel> {
        return this.selectDataForCalculator(calculatorId).pipe(
            filter(d => d != null),
            map(dataForCalculator => this.createPatientModelFromCalculatorFields(dataForCalculator, fields, false))
        );
    }

    public getDataForCalculator(calculatorId: string): Record<string, PatientDataField> {
        return getDataForCalculator(this.getValue(), calculatorId);
    }

    private createPatientModelFromCalculatorFields(dataForCalculator: Record<string, PatientDataField>, fields: Array<FormlyFieldConfig>, original: boolean): PatientModel {
        const flatFields = this.flattenFormlyFields(fields);
        const model: PatientModel = {};
        flatFields
            .filter(field => dataForCalculator[field.key])
            .forEach(field => {
                if (!model[field.category]) {
                    model[field.category] = {};
                }
                if (!model[field.category][`${field.key}Group`]) {
                    model[field.category][`${field.key}Group`] = {};
                }
                if (!model[field.category][`${field.key}Group`][`${field.key}Field`]) {
                    model[field.category][`${field.key}Group`][`${field.key}Field`] = {};
                }

                const calculatorDataField = dataForCalculator[field.key];

                model[field.category][`${field.key}Group`][`${field.key}Imputed`] = !original && calculatorDataField.imputed;
                const fieldModel = model[field.category][`${field.key}Group`][`${field.key}Field`];
                if (typeof fieldModel !== 'boolean') {
                    fieldModel[field.key] = original
                        ? calculatorDataField.originalValue
                        : calculatorDataField.overriddenValue ?? calculatorDataField.originalValue;

                    if (calculatorDataField.originalUnit != null) {
                        fieldModel[`${field.key}Unit`] = original
                            ? calculatorDataField.originalUnit
                            : calculatorDataField.overriddenUnit ?? calculatorDataField.originalUnit;
                    }
                }
            });

        flatFields
            .filter(field => field.key.endsWith('Imputed'))
            .filter(field => {
                const fieldGroup = this.getGroupNameFromImputedFieldName(field.key);
                if (!fieldGroup) {
                    return false;
                }
                if (!model[field.category]) {
                    model[field.category] = {};
                }
                if (!model[field.category][fieldGroup]) {
                    model[field.category][fieldGroup] = {};
                }
                if (!model[field.category][fieldGroup][field.key]) {
                    return true;
                }

                return false;
            })
            .forEach(field => model[field.category][this.getGroupNameFromImputedFieldName(field.key)][field.key] = false);

        return model;
    }

    private getGroupNameFromImputedFieldName(fieldName: string): string {
        if (fieldName.endsWith('Imputed')) {
            return `${fieldName.substring(0, fieldName.lastIndexOf('Imputed'))}Group`;
        }

        return null;
    }

    private flattenFormlyFields(fields: Array<FormlyFieldConfig>): Array<{ category: string; key: string }> {
        return fields.reduce((array, field) => {
            array.push(...this.ungroupFormlyGroup(
                { ...field },
                field.key
            ));

            return array;
        }, []);
    }

    private ungroupFormlyGroup(field: FormlyFieldConfig, category: string | number | Array<string | number>): Array<unknown> {
        if (field.fieldGroup) {
            return field.fieldGroup
                .reduce((array, subField) => {
                    array.push(...this.ungroupFormlyGroup(subField, category));

                    return array;
                }, [])
                .map(subField => {
                    return {
                        ...subField,
                        category
                    };
                });
        }

        return [field];
    }
}
