import { AfterViewInit, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { BehaviorSubject, fromEvent, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import isEqual from 'lodash/isEqual';

@Component({
    selector: 'app-button-select',
    templateUrl: './button-select.component.html',
    styleUrls: ['./button-select.component.scss']
})
export class ButtonSelectComponent extends FieldType implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('overflowGroup', {static: true}) public overflowGroup: any;
    
    private originalValue: any;
    private readonly subscriptions = new Subscription();
    public readonly overflowing$ = new BehaviorSubject<boolean>(true);

    public isMultiSelect = false;
    public internalFields = new UntypedFormGroup({});

    constructor(private readonly zone: NgZone) {
        super();
        this.zone.runOutsideAngular(() => {
            this.subscriptions.add(fromEvent(window, 'resize')
                .pipe(
                    debounceTime(100),
                    distinctUntilChanged())
                .subscribe(_ => {
                    this.zone.run(() => {
                        this.checkOverflow();
                    });
                }
                ));
        });
    }

    public ngOnInit(): void {
        this.originalValue = this.formControl.value || false;

        const defaultValues = this.getFormValue();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.props.options.forEach((option: any) => {
            this.internalFields.addControl(option.id, new UntypedFormControl(defaultValues.includes(option.id)));
        });

        this.subscriptions.add(this.internalFields.valueChanges.subscribe(internalValues => {
            const value = Object
                .keys(internalValues)
                .filter(id => internalValues[id] === true);
            this.formControl.setValue(value);
        }));

        this.isMultiSelect = typeof this.field.type === 'string' 
            ? this.field.type.indexOf('multi') > -1
            : false;

        if (this.formControl.parent != null) {
            // in dial model the 'Antithrombotic treatment' receives 2 more options dynamically, and the check overflow does not trigger
            // see task 24085
            const parentForm = this.formControl.parent;
            if (parentForm.parent?.parent?.parent != null) {
                this.subscriptions.add(
                    parentForm.parent.parent.parent.valueChanges.pipe(
                        debounceTime(500),
                    ).subscribe(() => this.overflowing$.next(this.checkOverflow()))
                );
            }

            this.subscriptions.add(
                parentForm.parent?.valueChanges
                    .pipe(distinctUntilChanged((prev, next) => isEqual(prev, next)))
                    .subscribe(change => {
                        if (change[`${this.field.key}Imputed`] === true) {
                            if (this.isMultiSelect) {
                                Object
                                    .keys(this.internalFields.controls)
                                    .forEach(key => {
                                        this.internalFields.get(key).setValue((this.originalValue || []).includes(key));
                                    });
                            } else {
                                this.formControl.setValue(this.originalValue);
                            }
                        }
                    }));

            if (this.isMultiSelect) {
                this.subscriptions.add(parentForm.valueChanges.pipe(
                    distinctUntilChanged((prev, next) => isEqual(prev, next))
                ).subscribe(change => {
                    Object
                        .keys(this.internalFields.controls)
                        .forEach(key => {
                            this.internalFields.get(key).setValue((change[`${this.field.key}`] || []).includes(key));
                        });
                }));
            }
        }
    }

    public ngAfterViewInit(): void {
        this.overflowing$.next(this.checkOverflow());
    }
  
    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public checkOverflow(): boolean {
        return this.overflowGroup != null 
        && this.overflowGroup.nativeElement.scrollWidth > (this.overflowGroup.nativeElement.offsetWidth);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getFormValue(): Array<any> {
        const groupKey = `${this.key}Group`;
        if (!this.formState.formModel) {
            return [];
        }
        const value = Object
            .keys(this.formState.formModel)
            .map(category => this.formState.formModel[category][groupKey])
            .filter(x => x)
            .map(group => group[`${this.key}Field`][this.key as string])[0] || [];

        return Array.isArray(value) ? value : [value];
    }
}
