<div class="announcement-component">
    <div class="announcement-header">
        <div class="announcement-close">
            <button *ngIf="config.canBeClosed" mat-button matRipple class="announcement-close-button" (click)="emit(ResponseType.Cancel)" >
                <span class="material-icons">close</span>
            </button>
        </div>
        <div [ngClass]="config.titleText !== null ? 'announcement-logo' : 'announcement-logo-only'">
            <ng-container [ngSwitch]="config.site">
                <a *ngSwitchCase="Site.Default" routerLink="/">
                    <img class="announcement-logo-image logo-img" src="/assets/logo_uprevent_green2.png"/>
                </a>
                <a *ngSwitchCase="Site.VtePredict" routerLink="/">
                    <img class="announcement-logo-image logo-img" src="/assets/VTEpredict.png"/>
                </a>
                <a *ngSwitchCase="Site.LifeHf" routerLink="/">
                    <img class="announcement-logo-image logo-img" src="/assets/logo-lifehf.png"/>
                </a>
            </ng-container>
        </div>
        <div *ngIf="config.titleText" class="announcement-title">
            <h3 [innerHTML]="titleText | translate"></h3>
        </div>
    </div>

    <div class="announcement-content">
        <div class="announcement-content-announcement">
            <p [innerHTML]="config.announcementText | translate"></p>
        </div>

        <div class="announcement-content-body">
            <p [innerHTML]="config.bodyText | translate"></p>
        </div>
    </div>
    
    <div class="announcement-actions" [ngClass]="{active: config.invertButtons}">
        <button *ngIf="config.agreeBtn" mat-button (click)="emit(ResponseType.Positive)" data-cy="dialogAgree" [class]="config.agreeBtn.type">
            <span> {{ config.agreeBtn.text | translate}} </span>
            <span *ngIf="config.agreeBtn.icon" class="material-icons"> {{config.agreeBtn.icon}} </span>
        </button>

        <button *ngIf="config.declineBtn" mat-button (click)="emit(ResponseType.Negative)" data-cy="dialogAgree" [class]="config.declineBtn.type">
            <span> {{ config.declineBtn.text | translate}} </span>
            <span *ngIf="config.declineBtn.icon" class="material-icons"> {{config.declineBtn.icon}} </span>
        </button>

    </div>
</div>
