import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UserQuery } from '@app-core/user-store/user.query';
import { NoticeUrl } from '@app-shared/models/one-trust.model';
import { OneTrustService } from '@app-shared/services/onetrust.service';
import { SharedModule } from '@app-shared/shared.module';
import { combineQueries } from '@datorama/akita';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from 'environments/environment';
import { EnvironmentName } from 'environments/environment.config';

@UntilDestroy()
@Component({
    selector: 'app-privacy-statement',
    templateUrl: './privacy-statement.component.html',
    styleUrls: ['./privacy-statement.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        SharedModule
    ]
})
export class PrivacyStatementComponent implements OnInit {
    // we want pilot to mimic production in this case
    public isProduction = [EnvironmentName.Pilot, EnvironmentName.Production].includes(environment.name);

    constructor(
        private readonly userQuery: UserQuery,
        private readonly otService: OneTrustService)
    { }

    public ngOnInit(): void {
        combineQueries([
            this.userQuery.selectLang(),
            this.otService.selectOneTrust()
        ]).pipe(
            untilDestroyed(this)
        ).subscribe({
            next: ([lang, ot]) => 
            {
                if (this.isProduction) {
                    ot.NoticeApi.LoadNotices([NoticeUrl.Production], false, lang);
                } else {
                    ot.NoticeApi.LoadNotices([NoticeUrl.Development], false, lang);
                }
            }
        });
    }
}
