import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-consent',
    templateUrl: './consent.component.html',
    styleUrls: ['./consent.component.scss']
})
export class ConsentComponent {
    @Input() public infoTitle: string;
    
    @Input() public agreeBtnText: string;
    @Input() public declineBtnText: string;

    @Input() public agreeBtnId: string;
    @Input() public declineBtnId: string;

    @Output() public agreed = new EventEmitter();
    @Output() public declined = new EventEmitter();

    public agree(): void {
        this.agreed.emit();
    }

    public decline(): void {
        this.declined.emit();
    }
}