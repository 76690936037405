import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';

@Component({
    selector: 'cvrm-button-select-test-form',
    templateUrl: './button-select-test-form.component.html',
    styleUrls: ['./button-select-test-form.component.scss']
})
export class ButtonSelectTestFormComponent implements OnInit {
    @Input() public size = 3;
    @Input() public start = 0;
    @Input() public type = 'button-select';

    public idPrefix = Math.random();

    public form = new UntypedFormGroup({});
    public model = { };
    public fields: Array<FormlyFieldConfig> = [];

    public ngOnInit(): void {
        const ids = [`${this.idPrefix}bla`, `${this.idPrefix}foo`, `${this.idPrefix}bar`, `${this.idPrefix}baz`, `${this.idPrefix}fifth`, `${this.idPrefix}sixth`, `${this.idPrefix}seventh`];
        this.fields = [
            {
                key: 'email',
                type: this.type,
                props: {
                    label: 'Email address',
                    placeholder: 'Enter email',
                    required: true,
                    options: [
                        {value: ids[0], id: ids[0], label: 'Bla die we sa as;d asldk ;iasd filasj df;iasd fliasd fliasd fliasdh fliasdh flasdhfh aludshf lausd flausd fluasdh flashd flashd fljasd fljas hf'},
                        {value: ids[1], id: ids[1], label: 'Foo sdak asdl '},
                        {value: ids[2], id: ids[2], label: 'Bar  '},
                        {value: ids[3], id: ids[3], label: 'BazZZ'},
                        {value: ids[4], id: ids[4], label: 'Fifth'},
                        {value: ids[5], id: ids[5], label: 'Sixth'},
                        {value: ids[6], id: ids[6], label: 'Seventh'},
                    ].slice(this.start, this.size)
                }
            }
        ];
    }
}
