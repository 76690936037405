import { inject } from '@angular/core';
import { CanActivateChildFn, Router } from '@angular/router';
import { GeneralQuery } from '@app-core/general-store/general.query';
import { Site } from '@app-shared/models/site-configuration-enum';
import { map } from 'rxjs';

export const calculatorGuard: CanActivateChildFn = () => {
    const router = inject(Router);
    const generalQuery = inject(GeneralQuery);
    return generalQuery.selectSite().pipe(
        map(siteConfig => {
            switch (siteConfig) {
                case Site.Default:
                    return true;    
                case Site.VtePredict:
                    return router.createUrlTree(['/calculators/vtePredict']);
                case Site.LifeHf:
                    return router.createUrlTree(['/calculators/lifeHf']);
                case Site.Unknown:
                    return router.createUrlTree(['/']);
                default:
                    return false;
            }
        })
    );
};
