<div class="label">
  <div class="label-name">U-Prevent</div>
  <div class="label-details" translate>LABEL.DETAILS</div>
  <div class="label-icons">
    <div class="label-manufacturer">
      <div class="icon">
        <img src="/assets/manufacturer.png">
      </div>
      <div class="manufacturer-address">
        <div class="manufacturer-name">ORTEC B.V.</div>
        <div>Houtsingel 5</div>
        <div>2719 EA, Zoetermeer</div>
        <div>The Netherlands</div>
      </div>
    </div>
    <div class="label-medical-device">
      <div class="icon">
        <img src="/assets/medicaldevice.png">
      </div>
    </div>
  </div>
  <div class="label-information">
    <div><span translate>FOOTER.VERSION</span>: {{buildNr}} ({{buildDate | date: 'dd MMM yyyy' }})</div>
    <a [routerLink]="['/view-terms']" translate>FOOTER.TERMS_CONDITIONS</a>
  </div>
</div>
