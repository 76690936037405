import { CookieConsentModalComponent } from '@app-shared/components/cookie-consent-modal/cookie-consent-modal.component';
import { VideoPlayerComponent } from '@app-shared/components/video-player/video-player.component';
import { combineLatest, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs';

import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { GeneralQuery } from '@app-core/general-store/general.query';
import { Lang, SupportedLanguage } from '@app-core/user-store/user.model';
import { UserQuery } from '@app-core/user-store/user.query';
import { ContentQuery } from '@app-features/content/content.query';
import { ContentService } from '@app-features/content/content.service';
import { CookieGroup } from '@app-shared/models/one-trust.model';
import { OneTrustService } from '@app-shared/services/onetrust.service';

@Component({
    selector: 'cvrm-content-host',
    templateUrl: './content-host.component.html',
    styleUrls: ['./content-host.component.scss'],
    // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
    encapsulation: ViewEncapsulation.None,
})
export class ContentHostComponent {
    @Input() public fullPath: string;
    @Input() public anchor = '';
    @Output() public readonly headerChange: EventEmitter<any> = new EventEmitter();
    
    private readonly SupportedLanguages = Object.values(SupportedLanguage).map(lang => lang.toString());
    private consent: boolean;
    private cookieConsent$ = this.generalQuery.selectFeatures().pipe(
        switchMap(features => features.requiresAuthentication == null
            ? this.userQuery.selectCookieConsent().pipe(map(cookieConsent => cookieConsent ?? false))
            : this.otService.selectActiveCookieGroups().pipe(map(group => group.includes(CookieGroup.StrictlyNecessaryCookies)))),
        tap(cookieConsent => {
            this.consent = cookieConsent;
        })
    );
    private currentLang$ = this.userQuery.selectLang().pipe(
        map(lang => this.SupportedLanguages.includes(lang) ? lang : Lang.English)
    );

    public content$ = combineLatest([
        this.currentLang$,    
        this.cookieConsent$
    ]).pipe(
        filter(([lang, cookieConsent]) => lang != null && cookieConsent != null), // only continue when both submit values
        distinctUntilChanged((prev, next) => prev[0] === next[0] && prev[1] === prev[1]), // skip if values are equal to previous submit
        tap(([lang, cookieConsent]) => this.contentService.fetchContent(this.fullPath, lang, cookieConsent)), // fetch content for this language and full path
        switchMap(([lang, ]) => this.contentQuery.selectContent(this.fullPath, lang)), // listen for the content in its loading stages
        tap(() => {
            setTimeout(() => {
                // extracting the title and sub title
                const titleElement = this.elem.nativeElement.querySelector('._host-header h1');
                const subTitleElement = this.elem.nativeElement.querySelector('._host-header h2');

                const title = titleElement ? titleElement.innerHTML : '';
                const subTitle = subTitleElement ? subTitleElement.innerHTML : '';

                if (this.anchor) {
                    const anchorElement = this.elem.nativeElement.querySelector(`a[name=${this.anchor}]`);
                    toggleFaqOpenedClass(anchorElement);
                }

                this.headerChange.emit({ title, subTitle });
            });
        })
    );

    constructor(
        private readonly contentService: ContentService,
        private readonly otService: OneTrustService,
        private readonly userQuery: UserQuery,
        private readonly generalQuery: GeneralQuery,
        private readonly contentQuery: ContentQuery,
        private readonly modalService: NgbModal,
        private readonly elem: ElementRef
    ) { }

    @HostListener('click', ['$event']) public clickListener(event: MouseEvent): void {
        const target: HTMLAnchorElement = event.target as HTMLAnchorElement;

        // 1 toggle the opened class for a faq container
        toggleFaqOpenedClass(target);

        // 2 recognise a click to open the video player
        const videoSrc = getVideoSrc(target);

        if (videoSrc) {
            const modalRef = this.modalService.open(VideoPlayerComponent, { backdrop: false });
            modalRef.componentInstance.videoSrc = videoSrc;
            event.preventDefault();
        } else if (target && target.href && target.href.startsWith('https://www.youtube-nocookie.com/embed/')) {
            event.preventDefault();

            if (!this.consent) {
                this.modalService.open(CookieConsentModalComponent, { backdrop: false });
            } else {
                const modalRef = this.modalService.open(VideoPlayerComponent, { backdrop: false });
                modalRef.componentInstance.youtubeUrl = target.href;
            }
        }
    }
}

/**
 * This function will toggle the 'opened' class on any element with class name faq
 */
const toggleFaqOpenedClass = (elem: HTMLElement): void => {
    if (!elem) {
        return;
    }
    if (elem.classList.contains('_host-faq')) {
        elem.classList.toggle('opened');
    } else if (elem.parentElement) {
        toggleFaqOpenedClass(elem.parentElement);
    }
};

/**
 * This function returns the href value of
 */
const getVideoSrc = (elem: HTMLElement): string => {
    const href = elem.getAttribute('href') || '';
    const match = href.match(/.+\.mp4/);
    if (match) {
        return href;
    } else if (elem.parentElement) {
        return getVideoSrc(elem.parentElement);
    }
};
