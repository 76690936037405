import { Component, OnInit } from '@angular/core';
import { GeneralQuery } from '@app-core/general-store/general.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FieldType } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'app-imputation-switch',
    templateUrl: './imputation-switch.component.html',
    styleUrls: ['./imputation-switch.component.scss']
})
export class ImputationSwitchComponent extends FieldType implements OnInit {
    public hovered = false;
    public hoverText: string;

    constructor(
        public translateService: TranslateService,
        private readonly generalQuery: GeneralQuery
    ) { 
        super();
    }

    public ngOnInit(): void {
        this.setHoverText();

        this.translateService.onLangChange.pipe(
            tap(() => {
                this.setHoverText();
            }),
            untilDestroyed(this)
        ).subscribe();
    }

    public toggleImputation(): void {
        this.formControl.setValue(!this.formControl.value);

        if (!this.formControl.value) {
            this.leave();
        }
    }

    public over(): void {
        this.hovered = true;
    }

    public leave(): void {
        this.hovered = false;
    }
	
    private setHoverText(): void {
        if (this.generalQuery.getFeatures().calculatedImputations) {
            this.hoverText = this.translateService.instant('FIELDS.IMPUTED_HOVER_PERSONALIZED');
        }
        else {
            this.hoverText = this.translateService.instant('FIELDS.IMPUTED_HOVER');
        }
    }
}
