import {Component, OnInit} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
    selector: 'app-unit-select',
    templateUrl: './unit-select.component.html',
    styleUrls: ['./unit-select.component.scss']
})
export class UnitSelectComponent extends FieldType implements OnInit {
    public origValue: string = null;

    public ngOnInit(): void {
        this.origValue = this.formControl.value || null;
    }
}
