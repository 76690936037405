import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { interval, map, Observable, startWith, take, tap } from 'rxjs';
import { Constants } from 'app/constants';
import { UserQuery } from '@app-core/user-store/user.query';

export enum TIMEOUT_CLOSE_REASON {
    STAY,
    LOGOUT,
}

@Component({
    selector: 'cvrm-idle-timeout-modal',
    templateUrl: './idle-timeout-modal.component.html',
    styleUrls: ['./idle-timeout-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdleTimeoutModalComponent implements OnInit {

    public userType$ = this.userQuery.selectUserType();

    constructor(
        private readonly timeoutModal: NgbActiveModal,
        public timeOutTimer$: Observable<number>,
        private readonly userQuery: UserQuery
    ) {}

    public ngOnInit(): void {
        this.timeOutTimer$ = this.getTimeoutMinutes(
            Constants.setTimeoutTimeInSeconds / 60
        );
    }

    public stay(): void {
        this.timeoutModal.close(TIMEOUT_CLOSE_REASON.STAY);
    }

    public logOut(): void {
        this.timeoutModal.close(TIMEOUT_CLOSE_REASON.LOGOUT);
    }

    private getTimeoutMinutes(timeout: number): Observable<number> {
        let count = timeout;

        return interval(60000).pipe(
            startWith(timeout),
            map(() => count),
            tap(() => count = count - 1),
            take(timeout)
        );
    }
}
