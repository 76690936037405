import { Component, Input } from '@angular/core';
import { Lang } from '@app-core/user-store/user.model';

@Component({
    selector: 'cvrm-ortec-banner',
    templateUrl: './ortec-banner.component.html',
    styleUrls: ['./ortec-banner.component.scss']
})
export class OrtecBannerComponent {
    @Input() public lang: Lang;

    public readonly Lang = Lang;
}