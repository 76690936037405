import { inject } from '@angular/core';
import { CanActivateChildFn, Router } from '@angular/router';
import { UserType } from '@app-core/user-store/user.model';
import { UserQuery } from '@app-core/user-store/user.query';
import { filter, map } from 'rxjs';

export const isLoginUserGuard: CanActivateChildFn = () => {
    const router = inject(Router);
    return inject(UserQuery).selectUserType().pipe(
        filter(userType => userType != null),
        map(userType => userType === UserType.Login ? true : router.createUrlTree(['/']))
    );
};