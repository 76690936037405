import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { GeneralQuery } from '@app-core/general-store/general.query';
import { Lang } from '@app-core/user-store/user.model';
import { UserQuery } from '@app-core/user-store/user.query';
import { UserService } from '@app-core/user-store/user.service';
import { SharedModule } from '@app-shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, first, map, ReplaySubject, startWith } from 'rxjs';

@Component({
    selector: 'app-lifehf-starting-page',
    templateUrl: './starting-page.component.html',
    styleUrls: ['./starting-page.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        SharedModule,
        TranslateModule
    ]
})
export class StartingPageComponent {
    public site$ = this.generalQuery.selectSite();
    public lang$ = this.userQuery.selectLang();

    public Lang = Lang;
    public q1Answer$ = new ReplaySubject<boolean>(1);
    public q2Answer$ = new ReplaySubject<boolean>(1);
    public disableContinueBtn$ = combineLatest([
        this.q1Answer$,
        this.q2Answer$
    ]).pipe(
        startWith([null, null]),
        map(([q1Answer, q2Answer]) => q1Answer == null || q2Answer == null),
    );
    
    constructor(
        private readonly generalQuery: GeneralQuery,
        private readonly userService: UserService,
        private readonly userQuery: UserQuery
    ) {}

    public answerQ1(answer: boolean): void {
        this.q1Answer$.next(answer);
    }

    public answerQ2(answer: boolean): void {
        this.q2Answer$.next(answer);
    }

    public continue(): void {
        combineLatest([
            this.q1Answer$,
            this.q2Answer$
        ]).pipe(first()).subscribe(([q1Answer, q2Answer]) => {
            if (q1Answer && q2Answer) {
                this.userService.updateLifeHfTerms(true);
            } else {
                this.userService.updateLifeHfTerms(false);
                window.location.href = 'https://www.sciencrew.com/c/6751?title=LifeHF';
            }
        });
    }
}

