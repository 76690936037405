import { SharedModule } from '@app-shared/shared.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ContactComponent } from '@app-features/contact/contact.component';

@NgModule({
    declarations: [ContactComponent],
    imports: [
        CommonModule,
        SharedModule,
        ReactiveFormsModule,
        RouterModule.forChild([
            {
                path: 'contact',
                children: [
                    {
                        path: '',
                        component: ContactComponent,
                    },
                ],
            },
        ]),
    ],
})
export class ContactModule {}
