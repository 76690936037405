import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Site } from '@app-shared/models/site-configuration-enum';
import { TranslateModule } from '@ngx-translate/core';

export enum ButtonType {
    Regular = 'btn-regular',
    Positive = 'btn-positive',
    Danger= 'btn-danger',
}

export enum ResponseType {
    Positive,
    Negative,
    Cancel
}

export enum ButtonIcon {
    Save = 'save',
    Trash = 'delete'
}

export interface ButtonConfig {
    text: string;
    type: ButtonType;
    icon?: ButtonIcon;
}

export interface DialogConfig {
    titleText?: string;
    announcementText?: string;
    bodyText?: string;
    agreeBtn: ButtonConfig;
    declineBtn?: ButtonConfig;
    site: Site;
    canBeClosed?: boolean;
    invertButtons?: boolean;
}

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        CommonModule,
        MatIconModule,
        MatButtonModule,
        TranslateModule
    ]
})
export class DialogComponent {
    @Input() public config: DialogConfig = {
        titleText: null,
        announcementText: '',
        bodyText: '',
        agreeBtn: {
            text: '',
            type: ButtonType.Regular
        },
        site: Site.Default,
        canBeClosed: true,
        invertButtons: false
    };

    @Output() public response = new EventEmitter<ResponseType>();

    public Site = Site;
    public ButtonIcon = ButtonIcon;
    public ResponseType = ResponseType;

    public emit(responseType: ResponseType): void {
        this.response.emit(responseType);
    }
}
