<div>
    <cvrm-page-wave-background [color]="calculator.color">
        <div class="header">
            <h1>
                <span translate>CALCULATORS.SCORE_REGION</span>
                <a href="#" (click)="toDescription($event)" class="description-link">
                    <fa-icon icon="info-circle"></fa-icon>
                </a>

            </h1>
            <h3 [translate]="calculator.title" class="header__sub-title"></h3>
        </div>

        <form [formGroup]="form" (ngSubmit)="submit(model)">
            <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options"
                (modelChange)="submit($event)"></formly-form>
            <div class="selector-helper"></div>
        </form>

        <h4 translate>CALCULATORS.NEW_SCORE_CALCULATORS_TEXT</h4>

        <div class="calculator-matrix__row">
            <div class="calculator-matrix__cell-label">
                <cvrm-label-tile color="teal" label="CALCULATOR_OVERVIEW.HEALTHY_70_MINUS"
                    sublabel="CALCULATOR_OVERVIEW.HEALTHY_SUBLABEL"></cvrm-label-tile>
            </div>
            <div class="calculator-matrix__cell-calculator">
                <a routerLink="../score2">
                    <cvrm-selection-tile color="teal" label="CALCULATOR_OVERVIEW.SCORE_2">
                    </cvrm-selection-tile>
                </a>
            </div>
        </div>

        <div class="calculator-matrix__row">
            <div class="calculator-matrix__cell-label">
                <cvrm-label-tile color="teal" label="CALCULATOR_OVERVIEW.HEALTHY_70_PLUS"
                sublabel="CALCULATOR_OVERVIEW.HEALTHY_ELDERLY_SUBLABEL"></cvrm-label-tile>
            </div>
            <div class="calculator-matrix__cell-calculator">
                <a routerLink="../score2OP">
                    <cvrm-selection-tile color="teal" label="CALCULATOR_OVERVIEW.SCORE_2_OP">
                    </cvrm-selection-tile>
                </a>
            </div>
        </div>
    </cvrm-page-wave-background>
    <cvrm-footer-wave-background [color]="calculator.color"></cvrm-footer-wave-background>
</div>