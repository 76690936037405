<ng-container *ngIf="disable; else enable">
    <div class="cover" (click)="clicked()" (mouseover)="toggleBlurOnHover()" (mouseout)="toggleBlurOutHover()">
        <div class="obscure" [ngClass]="{'in-focus': inFocus, 'round': roundBorder}"></div>
        <div ngClass="disable-background-click">
            <ng-container [ngTemplateOutlet]="enable"></ng-container>
        </div>
        <a class="btn-cta center" [ngClass]="{'btn-in-focus': inFocus}">
            <span class="material-icons">lock</span>
        </a>
    </div>
</ng-container>

<ng-template #enable>
    <ng-content></ng-content>
</ng-template>