import { ChangeDetectionStrategy, Component, Input, OnChanges, ViewChild } from '@angular/core';

import { LifeExpectancy } from '@app-features/calculators/calculator.model';
import { ChartLabels, DEFAULT_CHART_OPTIONS, NEGATIVE_COLOR, NEUTRAL_COLOR, POSITIVE_COLOR, getMultilineText } from '@app-features/calculators/components/chart';
import { ChartConfiguration, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

export interface ExpectancyGraphLabels {
    additionalYearsGainTitle: string;
    additionalYearsLossTitle: string;
    additionalYearsGainInfo: string;
    additionalYearsLossInfo: string;
    cvdFreeLifeExpectancyInfo: string;
    ageExpectancyDiseaseFree: string;
}

@Component({
    selector: 'cvrm-expectancy-graph',
    templateUrl: './expectancy-graph.component.html',
    styleUrls: ['./expectancy-graph.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpectancyGraphComponent implements OnChanges {
    @ViewChild(BaseChartDirective, { static: true }) public chart: BaseChartDirective;

    @Input() public improvement: LifeExpectancy;
    @Input() public labels: ChartLabels;
    @Input() public labelsOverrides: ExpectancyGraphLabels;

    public readonly type: ChartType = 'line';
    public options: ChartOptions<'line'> = {};
    public data: ChartConfiguration<'line'>['data'];

    public ADDITIONAL_YEARS_TITLE: string;
    public ADDITIONAL_YEARS_INFO: string;
    public CVD_FREE_LIFE_EXPECTANCY_INFO: string;
    public AGE_EXPECTANCY_DISEASE_FREE: string;

    public ngOnChanges(): void {
        this.data = updateLineData(this.data, this.improvement);

        this.options = this.getChartOptions();

        if (this.labelsOverrides != null) {
            this.ADDITIONAL_YEARS_TITLE = this.improvement.lifetimeBenefit >= 0 ? this.labelsOverrides.additionalYearsGainTitle : this.labelsOverrides.additionalYearsLossTitle ;
            this.ADDITIONAL_YEARS_INFO = this.improvement.lifetimeBenefit >= 0 ? this.labelsOverrides.additionalYearsGainInfo : this.labelsOverrides.additionalYearsLossInfo; 
            this.CVD_FREE_LIFE_EXPECTANCY_INFO = this.labelsOverrides.cvdFreeLifeExpectancyInfo;
            this.AGE_EXPECTANCY_DISEASE_FREE = this.labelsOverrides.ageExpectancyDiseaseFree;
        } else {            
            this.ADDITIONAL_YEARS_TITLE = `CALCULATORS.YEARS_${this.improvement.lifetimeBenefit >= 0 ? 'GAIN' : 'LOSS'}_DISEASE_FREE`;
            this.ADDITIONAL_YEARS_INFO = `RESULTS.CVD_YEARS_${this.improvement.lifetimeBenefit >= 0 ? 'GAIN' : 'LOSS'}_INFO`;
            this.CVD_FREE_LIFE_EXPECTANCY_INFO = 'RESULTS.CVD_FREE_LIFE_EXPECTANCY_INFO';
            this.AGE_EXPECTANCY_DISEASE_FREE = 'CALCULATORS.AGE_EXPECTANCY_DISEASE_FREE';
        }
    }

    private getChartOptions(): ChartOptions<'line'> {
        return {
            ...DEFAULT_CHART_OPTIONS,
            scales: {
                x: {
                    title: {
                        display: true,
                        text: this.labels.ages
                    }
                },
                y: {
                    stacked: true,
                    ticks: {stepSize: 25},
                    title: {
                        display: true,
                        text: this.labels.percentage
                    },
                    beginAtZero: true
                }
            },
            plugins: {
                title: {
                    display: true,
                    text: getMultilineText(this.labels.title)
                },
                tooltip: { enabled: false }
            },
        };
    }
}

const updateLineData = (data: ChartConfiguration<'line'>['data'] | null | undefined, lifeExp: LifeExpectancy)
: ChartConfiguration<'line'>['data'] => {
    const untreatedRiskRange =  lifeExp.untreatedRiskRange.map(x  => x * 100);
    const positiveRiskRange = lifeExp.treatedRiskRange
        .map(x => x * 100)
        .map((x, index) => Math.max( x - untreatedRiskRange[index], 0));
    const negativeRiskRange = lifeExp.treatedRiskRange
        .map((x) => x * 100)
        .map((x, index) => Math.max(  untreatedRiskRange[index] - x, 0));
    const adjustedUntreatedRiskRange = untreatedRiskRange.map((x, index) => x - negativeRiskRange[index]);

    if (data == null)
    {
        const datasets = [
            { data: adjustedUntreatedRiskRange, borderColor: NEUTRAL_COLOR, backgroundColor: NEUTRAL_COLOR, fill: true },
            { data: positiveRiskRange, borderColor: 'transparent', backgroundColor: POSITIVE_COLOR, fill: true },
            { data: negativeRiskRange,  borderColor: 'transparent', backgroundColor: NEGATIVE_COLOR, fill: true }
        ];

        return { datasets, labels: lifeExp.ageRange };
    } else {
        // mutate current data so Chart.js animates toward the new values
        const datasets = data.datasets;
        datasets[0].data = adjustedUntreatedRiskRange;
        datasets[1].data = positiveRiskRange;
        datasets[2].data = negativeRiskRange;
        data.labels = lifeExp.ageRange;

        return data;
    }
};
