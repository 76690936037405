export interface ContactDto
{
    name: string;
    emailAddress: string; 
    subject: string;
    gdprTopic: string;
    message: string;
    lang: string;
    url: string;
}

export enum ContactSubjects {
    MedicalInquiries = "Medical inquiries",
    CommercialInquiry = "Commercial inquiry",
    GDPRInquiries = "GDPR inquiries",
    OtherQuestions = "Other questions",
    VTEPredict = "VTE-PREDICT"
}

export enum GDPRTopic {
    CorrectMyData = "Correct my data",
    DeleteMyData = "Delete my data",
    FreezeMyData = "Freeze my data",
    ExportMyData = "Export my data",
    StopProcessingMyData = "Stop processing my data",
    OtherMatters = "Other matters"
}

export enum SendStatus {
    New = "new",
    Sending = "sending",
    Success = "success",
    Error = "error"
}