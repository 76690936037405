<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<div *ngIf="(calculator$ | async) as calculator">
  <div class="no-wave-header header-{{calculator.color}}">
    <h1 [translate]="calculator.title"></h1>
  </div>
  <div class="current-situation">
    <cvrm-current-situation-overview [patient]="intake$ | async"></cvrm-current-situation-overview>
    <div class="current-situation__actions">
      <button (click)="reopenIntake()" class="btn result__reopen-intake" [attr.data-cy]="'resultReopenIntake'"
        translate>CALCULATORS.RESULT_REOPEN_INTAKE</button>
    </div>
  </div>

  <div>
    <div class="result">
      <div class="result__graph">
        <div class="result__graph--overlay result__graph--loading" *ngIf="loading$| async">
          loading...
        </div>
        <div class="result__graph--overlay result__graph--error" *ngIf="error$| async">
          <p translate>RESULTS.CALCULATOR_ERROR_1</p>
          <p translate>RESULTS.CALCULATOR_ERROR_2</p>
          <p translate>RESULTS.CALCULATOR_ERROR_3</p>
          <button (click)="reopenIntake()" class="btn result__reopen-intake"
            translate>CALCULATORS.RESULT_REOPEN_INTAKE</button>
        </div>
        <div *ngIf="graphTabs$ | async as tabs" class="graph-tab-container">
          <ng-container>
            <span class="graph-tab" 
              *ngFor="let tab of tabs"
              [ngClass]="{selected: tab.selected, 'hide-tab': (tab.key === 'fiveYearRiskWithHeartFailure' || tab.key === 'tenYearRiskWithHeartFailure' || tab.key === 'oneYearBleedingRisk' || tab.key === 'fiveYearBleedingRisk') }"
              (click)="selectTab(tab.key)" 
              translate 
              [attr.data-cy]="tab.key + 'GraphHeader'">{{tab.label}}</span>
          </ng-container>
        </div>

        <cvrm-expectancy-graph [ngClass]="{'hide-graph': hideGraph(calculator.selectedGraph !== 'lifeExpectancy')}"
          *ngIf="calculator.risks.lifeExpectancy" [improvement]="calculator.risks.lifeExpectancy"
          [labels]="expectancyLabels" [labelsOverrides]="hfOverallExpectancyGraphLabels">
        </cvrm-expectancy-graph>

        <cvrm-expectancy-graph [ngClass]="{'hide-graph': hideGraph(calculator.selectedGraph !== 'lifeExpectancySecondary')}"
          *ngIf="calculator.risks.lifeExpectancySecondary" [improvement]="calculator.risks.lifeExpectancySecondary"
          [labels]="expectancySecondaryLabels" [labelsOverrides]="hfExpectancyGraphLabels">
        </cvrm-expectancy-graph>

        <ng-container *ngIf="site$ | async as site">
          <cvrm-result-ten-years-statistics type="TWO"
            [ngClass]="{'hide-graph': hideGraph(calculator.selectedGraph !== 'twoYearRisk' && !(site === Site.LifeHf && calculator.selectedGraph === 'lifeExpectancy'))}"
            *ngIf="calculator.risks.twoYearRisk" [risks]="calculator.risks.twoYearRisk" [labels]="twoYearLabels" [labelsOverrides]="hfExpectancyLabels">
          </cvrm-result-ten-years-statistics>

          <cvrm-result-ten-years-statistics type="FIVE"
            [ngClass]="{'hide-graph': hideGraph(calculator.selectedGraph !== 'fiveYearRisk' && !(site === Site.LifeHf && calculator.selectedGraph === 'lifeExpectancy'))}"
            *ngIf="calculator.risks.fiveYearRisk" [risks]="calculator.risks.fiveYearRisk" [labels]="fiveYearLabels" [labelsOverrides]="hfExpectancyLabels">
          </cvrm-result-ten-years-statistics>

          <cvrm-result-ten-years-statistics type="TWO_SECONDARY"
            [ngClass]="{'hide-graph': hideGraph(calculator.selectedGraph !== 'twoYearRiskSecondary' && !(site === Site.LifeHf && calculator.selectedGraph === 'lifeExpectancySecondary'))}"
            *ngIf="calculator.risks.twoYearRiskSecondary" [risks]="calculator.risks.twoYearRiskSecondary" [labels]="twoYearSecondaryLabels" [labelsOverrides]="hfExpectancyLabels">
          </cvrm-result-ten-years-statistics>

          <cvrm-result-ten-years-statistics type="FIVE_SECONDARY"
            [ngClass]="{'hide-graph': hideGraph(calculator.selectedGraph !== 'fiveYearRiskSecondary' && !(site === Site.LifeHf && calculator.selectedGraph === 'lifeExpectancySecondary'))}"
            *ngIf="calculator.risks.fiveYearRiskSecondary" [risks]="calculator.risks.fiveYearRiskSecondary" [labels]="fiveYearSecondaryLabels" [labelsOverrides]="hfExpectancyLabels">
          </cvrm-result-ten-years-statistics>
        </ng-container>

        <hr class="graph-seperation" *ngIf="calculator.selectedGraph === 'fiveYearRisk' && calculator.risks.fiveYearRiskWithHeartFailure">
      
        <cvrm-result-ten-years-statistics type="FIVE_HEARTFAILURE"
          [ngClass]="{'hide-graph': hideGraph(calculator.selectedGraph !== 'fiveYearRisk')}"
          *ngIf="calculator.risks.fiveYearRiskWithHeartFailure" [risks]="calculator.risks.fiveYearRiskWithHeartFailure" [labels]="fiveYearWithHeartFailureLabels">
        </cvrm-result-ten-years-statistics>

        <cvrm-result-ten-years-statistics type="ONE_VTE"
          [ngClass]="{'hide-graph': hideGraph(calculator.selectedGraph !== 'oneYearVTERecurrenceRisk')}"
          *ngIf="calculator.risks.oneYearVTERecurrenceRisk" [risks]="calculator.risks.oneYearVTERecurrenceRisk" [labels]="oneYearVteRecurrenceLabels">
        </cvrm-result-ten-years-statistics>

        <hr class="graph-seperation" *ngIf="calculator.selectedGraph === 'oneYearVTERecurrenceRisk' && calculator.risks.oneYearBleedingRisk">

        <cvrm-result-ten-years-statistics type="ONE_BLEEDING"
          [ngClass]="{'hide-graph': hideGraph(calculator.selectedGraph !== 'oneYearVTERecurrenceRisk')}"
          *ngIf="calculator.risks.oneYearBleedingRisk" [risks]="calculator.risks.oneYearBleedingRisk" [labels]="oneYearBleedingLabels">
        </cvrm-result-ten-years-statistics>

        <cvrm-result-ten-years-statistics type="FIVE_VTE"
          [ngClass]="{'hide-graph': hideGraph(calculator.selectedGraph !== 'fiveYearVTERecurrenceRisk')}"
          *ngIf="calculator.risks.fiveYearVTERecurrenceRisk" [risks]="calculator.risks.fiveYearVTERecurrenceRisk" [labels]="fiveYearVteRecurrenceLabels">
        </cvrm-result-ten-years-statistics>

        <hr class="graph-seperation" *ngIf="calculator.selectedGraph === 'fiveYearVTERecurrenceRisk' && calculator.risks.fiveYearBleedingRisk">

        <cvrm-result-ten-years-statistics type="FIVE_BLEEDING"
          [ngClass]="{'hide-graph': hideGraph(calculator.selectedGraph !== 'fiveYearVTERecurrenceRisk')}"
          *ngIf="calculator.risks.fiveYearBleedingRisk" [risks]="calculator.risks.fiveYearBleedingRisk" [labels]="fiveYearBleedingLabels">
        </cvrm-result-ten-years-statistics>

        <cvrm-result-ten-years-statistics type="TEN"
          [ngClass]="{'hide-graph': hideGraph(calculator.selectedGraph !== 'tenYearRisk')}"
          *ngIf="calculator.risks.tenYearRisk" [risks]="calculator.risks.tenYearRisk" [labels]="tenYearLabels">
        </cvrm-result-ten-years-statistics>

        <hr class="graph-seperation" *ngIf="calculator.selectedGraph === 'tenYearRisk' && calculator.risks.tenYearRiskWithHeartFailure">

        <cvrm-result-ten-years-statistics type="TEN_HEARTFAILURE"
          [ngClass]="{'hide-graph': hideGraph(calculator.selectedGraph !== 'tenYearRisk')}"
          *ngIf="calculator.risks.tenYearRiskWithHeartFailure" [risks]="calculator.risks.tenYearRiskWithHeartFailure" [labels]="tenYearWithHeartFailureLabels">
        </cvrm-result-ten-years-statistics>

        <cvrm-result-ten-years-statistics type="LIFETIME"
          [ngClass]="{'hide-graph': hideGraph(calculator.selectedGraph !== 'lifetimeRisk')}"
          *ngIf="calculator.risks.lifetimeRisk" [risks]="calculator.risks.lifetimeRisk" [labels]="lifetimeLabels">
        </cvrm-result-ten-years-statistics>

        <cvrm-result-ten-years-statistics type="FATAL"
          [ngClass]="{'hide-graph': hideGraph(calculator.selectedGraph !== 'mortalityRisk')}"
          *ngIf="calculator.risks.mortalityRisk" [risks]="calculator.risks.mortalityRisk"
          [labels]="mortalityRiskLabels">
        </cvrm-result-ten-years-statistics>

        <cvrm-disease-risk-graph [ngClass]="{'hide-graph': hideGraph(calculator.selectedGraph !== 'diseaseRisk')}"
          *ngIf="calculator.risks.diseaseRisk" [labels]="diseaseRiskLabels"
          [improvement]="calculator.risks.diseaseRisk">
        </cvrm-disease-risk-graph>
      
        <div class="model-notification" *ngIf="calculator.id === 'dialModel'" translate="NOTES.PERSONALRISK_DIALMODEL"></div>  
      </div>
      <div class="result__treatment">
        <div class="result__treatment-options">

          <app-disable-component [disable]="countryHasNoSupportedLanguage$ | async" (interacted)="openModal()">
            <cvrm-intended-treatment-form [fields]="treatmentFields" [model]="treatmentModel"
              (treatmentChanged)="updateResult($event)">
            </cvrm-intended-treatment-form>
          </app-disable-component>
        </div>
        <button (click)="print()" *ngIf="isDesktop" class="btn result__print" [attr.data-cy]="'printButton'" translate>CALCULATORS.PRINT</button>
        <cvrm-copy-result [content]="clipboardContent$ | async" (copied)="confirmCopiedToClipboard()">
        </cvrm-copy-result>
      </div>
    </div>
  </div>
  <cvrm-footer-wave-background [color]="calculator.color"></cvrm-footer-wave-background>
  <cvrm-snackbar [content]="copySuccessMessage"></cvrm-snackbar>
</div>