import { Injectable, OnDestroy } from '@angular/core';
import { IdleTimeoutModalComponent, TIMEOUT_CLOSE_REASON } from '@app-shared/components/idle-timeout-modal/idle-timeout-modal.component';
import { AuthorizationService } from '@app-shared/services/authorization.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Constants } from 'app/constants';
import { Subscription } from 'rxjs';
import { LocalStorageService } from './local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class IdleTimeoutService implements OnDestroy {
    private readonly subscriptions = new Subscription();
    
    constructor(
        private readonly idle: Idle,
        private readonly authorizationService: AuthorizationService,
        private readonly modalService: NgbModal,
    ) {
        this.idle.setIdle(Constants.setIdleTimeInSeconds);

        this.idle.setTimeout(Constants.setTimeoutTimeInSeconds);
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        this.subscriptions.add(
            this.idle.onIdleEnd.subscribe(() => {
                this.reset();
            })
        );

        this.subscriptions.add(
            this.idle.onTimeout.subscribe(() => {
                this.modalService.dismissAll();
                this.idle.stop();
                this.logOut();
            })
        );

        this.setupTimeoutModal();
    }

    public stay(): void {
        this.reset();
    }

    public logOut(): void {
        //when idle timer kicks in we need to treat this as a session expiration
        this.authorizationService.handleRefreshTokenError(LocalStorageService.getCurrentToken());
    }

    public watch(): void {
        this.idle.watch();
    }

    public stop(): void {
        this.idle.stop();
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private reset(): void {
        this.idle.watch();
    }

    private setupTimeoutModal(): void {
        this.subscriptions.add(
            this.idle.onIdleStart.subscribe(() => {
                this.modalService
                    .open(IdleTimeoutModalComponent, { centered: true, backdrop: false })
                    .closed.subscribe((closeReason: TIMEOUT_CLOSE_REASON) => {
                        if (closeReason === TIMEOUT_CLOSE_REASON.STAY) {
                            this.watch();
                        } else if (closeReason === TIMEOUT_CLOSE_REASON.LOGOUT) {
                            this.logOut();
                        }
                    });
            })
        );
    }
}
