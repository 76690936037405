<div *ngIf="(calculator$ | async) as calculator">
  <div class="no-wave-header header-{{calculator.color}}">
    <h1 [translate]="'CALCULATORS.PERSONAL_RISK_PROFILE'"></h1>
    <h2 [translate]="calculator.title" class="no-wave-header__sub-title"></h2>
  </div>
  <div class="description">
    <cvrm-content-host [fullPath]="fullPath"></cvrm-content-host>
    <div class="consent__actions">
      <button (click)="toForm()" class="btn btn-primary" translate>CALCULATORS.OK</button>
    </div>
  </div>
  <cvrm-footer-wave-background [color]="calculator.color"></cvrm-footer-wave-background>
</div>