import { environment } from 'environments/environment';

const requiresAuth = environment.requiresAuthenticationConfig;

export const ApiBaseUrl = (route: string): string => {
    return environment.configuration.apiUrl + route;
};

export const getCallbackUrl = (): string => {
    return getUrl(requiresAuth.urls) + '/authorize';
};

export const getLogOutUrl = (): string => {
    return getUrl(requiresAuth.urls) + '/logout';
};

export const getSessionExpiredUrl = (): string => {
    return getHomeUrl() + '/session-expired';
};

export const getHomeUrl = (): string => {
    return getUrl(requiresAuth.urls);
};

const getUrl = (list: Array<string>): string => {
    const getSuffix = (url: string): string => {
        const urlSplit = url.split('.');
        return urlSplit[urlSplit.length - 1];
    };

    if (list == null) {
        throw new Error("RequiresAuthentication is not supported for this environment");
    }

    // location.host -> this returns only the domain and port number of the current URL
    const currentUrlSuffix = getSuffix(location.host);

    return list.find(x => getSuffix(x) === currentUrlSuffix) ?? list[0];
};