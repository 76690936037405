import { ChartConfiguration, ChartOptions } from "chart.js";
import { BaseRisk } from "../calculator.model";

export const NEUTRAL_COLOR = '#BFDEFB';
export const POSITIVE_COLOR = '#5AB04B';
export const NEGATIVE_COLOR = 'red';
export const IMPROVEMENT_COLOR = '#0D47A1';

export const DEFAULT_CHART_OPTIONS: ChartOptions = {
    animation: {
        duration: 500
    },
    responsive: true,
    hover: { mode: null },
    maintainAspectRatio: false,
    elements: {
        point: {
            radius: 0
        }
    },
};

export interface ChartLabels {
    title?: string;
    percentage?: string;
    ages?: string;
    current?: string;
    future?: string;
}

export const getMultilineText = (text: string): Array<string> => {
    return (text || '')
        .split(' ')
        .reduce((lines, word) => {
            const lastLine = lines[lines.length - 1];

            return lastLine.length > 50
                ? lines.concat([word])
                : lines.slice(0, -1).concat([`${lastLine} ${word}`]);
        }, ['']);
};

export const updateBarData = (data: ChartConfiguration<'bar'>['data'] | null | undefined, risks: BaseRisk)
: ChartConfiguration<'bar'>['data'] => {
    const risk = risks.treatmentBenefit > 0 ? risks.riskWithTreatment : risks.risk;
    const riskDecrease = risks.treatmentBenefit > 0 ? risks.treatmentBenefit : 0;
    const riskIncrease = Math.abs(risks.treatmentBenefit <= 0 ? risks.treatmentBenefit : 0);

    if (data == null) {
        const datasets = [
            {
                data: [risk],
                backgroundColor: NEUTRAL_COLOR,
            },
            {
                data: [riskDecrease],
                backgroundColor: POSITIVE_COLOR,
            },
            {
                data: [riskIncrease],
                backgroundColor: NEGATIVE_COLOR,
            },
        ];

        return { datasets, labels: [''] };
    } else {
        // mutate current data so Chart.js animates toward the new values
        const datasets = data.datasets;
        datasets[0].data[0] = risk;
        datasets[1].data[0] = riskDecrease;
        datasets[2].data[0] = riskIncrease;

        return data;
    }
};