import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { GeneralQuery } from '@app-core/general-store/general.query';
import { PatientModel } from '@app-features/calculators/patient-data/patient-data.model';
import { Site } from '@app-shared/models/site-configuration-enum';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Observable, Subject, Subscription, debounceTime, filter } from 'rxjs';

@Component({
    selector: 'cvrm-intended-treatment-form',
    templateUrl: './intended-treatment-form.component.html',
    styleUrls: ['./intended-treatment-form.component.scss'],
})
export class IntendedTreatmentFormComponent implements OnInit, OnDestroy {
    @Output() public readonly treatmentChanged = new EventEmitter<PatientModel>();

    @Input() public model: PatientModel = { treatment: {} };
    @Input() public fields: Array<FormlyFieldConfig>;
    @Input() public color: string;

    private readonly changeHandler = new Subject<PatientModel>();
    private readonly subscriptions = new Subscription();
    private originalModel: PatientModel;

    public readonly site$: Observable<string> = this.generalQuery.selectSite();
    public Site = Site;
    public form = new UntypedFormGroup({});
    public internalFields: Array<FormlyFieldConfig> = [];
    public options: FormlyFormOptions = {
        formState: {
            formModel: this.model,
        },
    };

    constructor(private readonly generalQuery: GeneralQuery) { }

    public ngOnInit(): void {
        this.internalFields = this.fields;
        this.options = {
            formState: {
                formModel: this.model,
            },
        };

        this.subscriptions.add(
            this.changeHandler
                .pipe(
                    debounceTime(200),
                    filter(() => this.form.status === 'VALID')
                )
                .subscribe((change) => {
                    this.treatmentChanged.emit(change);
                })
        );

        setTimeout(() => {
            this.options.updateInitialValue(this.model);
            this.originalModel = JSON.parse(JSON.stringify(this.model));
        });
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public change(data: PatientModel): void {
        this.changeHandler.next(data);
    }

    public resetModel(): void {
        this.options.resetModel(this.originalModel);
    }
}
