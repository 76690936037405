import { Component } from '@angular/core';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import { DEFAULT_CHART_OPTIONS, getMultilineText } from '@app-features/calculators/components/chart';

@Component({
    selector: 'app-test-page',
    templateUrl: './test-page.component.html',
    styleUrls: ['./test-page.component.scss']
})
export class TestPageComponent {
    public horizontalData: ChartConfiguration<'bar'>['data'] = {
        labels: [''],
        datasets: [
            { data: [8], backgroundColor: '#BFDEFB' },
            { data: [2], backgroundColor: '#5AB04B' },
            { data: [0], backgroundColor: 'red' }
        ]
    };

    public lineChartData: ChartConfiguration<'line'>['data'] = {
        labels: [56, 57, 58],
        datasets: [
            { data: [100, 98, 95],  borderColor: '#BFDEFB', backgroundColor: '#BFDEFB' },
            { data: [100, 70, 98], borderColor: 'transparent', backgroundColor: '#5AB04B' },
            { data: [80, 76, 74],  borderColor: 'transparent', backgroundColor: 'red' }
        ]
    };

    public mortalityData: ChartConfiguration<'bar'>['data'] = {
        labels: ['C', 'F'],
        datasets: [
            { data: [2, 4], label: '' , backgroundColor: '#BFDEFB' },
            { data: [3, 5], label: '' , backgroundColor: '#0D47A1' }
        ]
    };

    public horizontalOptions: ChartOptions<'bar'> = {
        ...DEFAULT_CHART_OPTIONS,
        indexAxis:'y',
        scales: {
            x: {
                beginAtZero: true, 
                max: 30,
                stacked: true,
                title: {
                    display: true,
                    text: 'Percentage'
                }
            },
            y: { stacked: true }
        },
        plugins: {
            title: {
                display: true, 
                text: getMultilineText('A de wer  wetwe dsfds g sdtrewge wgwergw rewgwer gewr sd sdohsdofi osadh iuasdhfiuasdhuasd iuasdf iuasdhf iuasd h')
            },
            tooltip: { enabled: false }
        }
    };

    public lineChartOptions: ChartOptions<'line'> = {
        ...DEFAULT_CHART_OPTIONS,
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'aassd'
                }
            },
            y: {
                stacked: true,
                ticks: {stepSize: 25},
                title: {
                    display: true,
                    text: 'ssddsdsfff'
                },
                beginAtZero: true
            }
        },
        plugins: {
            tooltip: { enabled: false }
        }
    };

    public change(): void {
        if (this.horizontalData.datasets[0].data[0] === 8) {
            this.horizontalData.datasets = [
                { data: [10] , backgroundColor: '#BFDEFB'}, { data: [0] , backgroundColor: '#BFDEFB'}, { data: [2] , backgroundColor: '#BFDEFB'}
            ];
        } else {
            this.horizontalData.datasets = [
                { data: [8] , backgroundColor: '#BFDEFB' }, { data: [2] , backgroundColor: '#BFDEFB' }, { data: [0]  , backgroundColor: '#BFDEFB' }
            ];
        }

        if (this.lineChartData.datasets[0].data[0] === 100) {
            this.lineChartData.datasets = [
                { data: [80, 98, 95],  borderColor: '#BFDEFB', backgroundColor: '#BFDEFB'},
                { data: [100, 70, 98], borderColor: 'transparent', backgroundColor: '#5AB04B' },
                { data: [80, 76, 74],  borderColor: 'transparent', backgroundColor: 'red' }
            ];
        } else {
            this.lineChartData.datasets = [
                { data: [100, 98, 95],  borderColor: '#BFDEFB', backgroundColor: '#BFDEFB'},
                { data: [100, 70, 98], borderColor: 'transparent', backgroundColor: '#5AB04B' },
                { data: [80, 76, 74],  borderColor: 'transparent', backgroundColor: 'red' }
            ];
        }
        if (this.mortalityData.datasets[0].data[0] === 2) {
            this.mortalityData.datasets = [
                {data: [4, 6], label: '' , backgroundColor: '#BFDEFB'},
                {data: [2, 6], label: '' , backgroundColor: '#0D47A1'}
            ];
        } else {
            this.mortalityData.datasets = [
                {data: [2, 4], label: '' , backgroundColor: '#BFDEFB'},
                {data: [3, 5], label: '' , backgroundColor: '#0D47A1'}
            ];
        }
    }
}
