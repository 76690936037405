<div class="consent">
    <div class="consent__text">
        <h3 class="consent__title" [translate]="infoTitle"></h3>
        <ng-content></ng-content>
    </div>
   <div class="d-grid gap-2 consent__actions">
        <button (click)="agree()" class="btn btn-primary" type="button" [id]="agreeBtnId" [attr.data-cy]="agreeBtnId" [translate]="agreeBtnText"></button>
        <button (click)="decline()" class="btn btn-primary" type="button" [id]="declineBtnId" [attr.data-cy]="declineBtnId" [translate]="declineBtnText"></button>
   </div>
</div>
