<cvrm-page-wave-background color="blue">
    <div class="starter">
        <div class="header">
            <h1>Welcome to Life HF</h1>
        </div>
    
        <div class="container">
            <div class="question">
                <label class="text" translate="MEDICAL_PROFESSIONAL_CONSENT.TITLE"></label>
                <div class="options">
                    <div class="option" [ngClass]="{'option-checked': (q1Answer$ | async) === true}" (click)="answerQ1(true)" translate="MEDICAL_PROFESSIONAL_CONSENT.AGREE_BUTTON"></div>
                    <div class="option" [ngClass]="{'option-checked': (q1Answer$ | async) === false}" (click)="answerQ1(false)" translate="MEDICAL_PROFESSIONAL_CONSENT.DECLINE_BUTTON"></div>
                </div>
            </div>
            <div class="question">
                <label class="text" translate="MEDICAL_PROFESSIONAL_CONSENT_HF.TITLE"></label>
                <div class="options">
                    <div class="option" [ngClass]="{'option-checked': (q2Answer$ | async) === true}" (click)="answerQ2(true)" translate="MEDICAL_PROFESSIONAL_CONSENT.AGREE_BUTTON"></div>
                    <div class="option" [ngClass]="{'option-checked': (q2Answer$ | async) === false}" (click)="answerQ2(false)" translate="MEDICAL_PROFESSIONAL_CONSENT.DECLINE_BUTTON"></div>
                </div>
            </div>
    
            <div class="question" *ngIf="lang$ | async as lang">
                <div class="question" [ngSwitch]="lang" >
                    <div class="text">
                        <ng-container *ngSwitchCase="Lang.EN" class="">
                            For more information, please visit us at 
                            <a style="color: white;" href="https://www.sciencrew.com/c/6751?title=UPrevent" target="_blank">ScienCrew.</a>
                        </ng-container>
        
                        <ng-container *ngSwitchCase="Lang.NL">
                            Ga naar de 
                            <a style="color: white;" href="https://www.sciencrew.com/c/6751?title=UPrevent" target="_blank">ScienCrew.</a>
                            pagina voor meer informatie.
                        </ng-container>
        
                        <ng-container *ngSwitchDefault>
                            For more information, please visit us at 
                            <a style="color: white;" href="https://www.sciencrew.com/c/6751?title=UPrevent" target="_blank">ScienCrew.</a>
                        </ng-container>
                    </div>
    
                    <div>
                        <a href="https://www.sciencrew.com/c/6751?title=UPrevent" target="_blank">
                          <img class="scien-crew" src="../../../../assets/sciencrew.png">
                        </a>
                    </div>
                </div>
            </div>
    
            <div class="center center-submit">
                <div>
                    <button mat-button [disabled]="disableContinueBtn$ | async" (click)="continue()" class="btn btn-white" type="submit" translate="IDLETIMEOUT.CONTINUEBUTTON_TEXT"></button>
                </div>
            </div>
        </div>
    </div>
</cvrm-page-wave-background>
<cvrm-footer-wave-background color="blue"></cvrm-footer-wave-background>