import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GeneralQuery } from '@app-core/general-store/general.query';
import { Calculator } from '@app-features/calculators/calculator.model';
import { CalculatorService } from '@app-features/calculators/calculator.service';
import { Patient } from '@app-features/calculators/model/formatted-intake.model';

@Component({
    selector: 'cvrm-current-situation-overview',
    templateUrl: './current-situation-overview.component.html',
    styleUrls: ['./current-situation-overview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CurrentSituationOverviewComponent implements OnInit {
    @Input() public patient: Patient;

    public calculatorName = '';
    public calculator$: Observable<Calculator>;
    public isCalculatedImputationsEnabled = this.generalQuery.getFeatures().calculatedImputations;

    constructor(        
        private readonly calculatorService: CalculatorService,
        private readonly generalQuery: GeneralQuery,
        private readonly route: ActivatedRoute,
        private readonly router: Router
    ) { }

    public ngOnInit(): void {
        this.calculatorName = this.route.snapshot.params.calculator;
        this.calculatorService.setActiveCalculator(this.calculatorName);
    }

    public toDescription(event: Event): void {
        event.preventDefault();
        this.router.navigate(['calculators', 'description', this.calculatorName]);
    }
}

