import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';

@Component({
    selector: 'cvrm-copy-result',
    templateUrl: './copy-result.component.html',
    styleUrls: ['./copy-result.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyResultComponent {
    @ViewChild('textarea', {static: true}) public textarea: any;
    @Input() public content = '';
    @Output() public copied = new EventEmitter();

    public copy(): void {
        this.textarea.nativeElement.select();
        this.textarea.nativeElement.setSelectionRange(0, 99999); /*fix for mobile devices*/

        document.execCommand('copy');

        this.copied.emit();
    }
}
