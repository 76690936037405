import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Country, Lang } from '@app-core/user-store/user.model';
import { SiteConfiguration } from '@app-shared/models/site-configuration-enum';
import { ApiBaseUrl } from '@app-shared/services/url.service';
import { Observable, first, switchMap, tap } from 'rxjs';
import { GeneralQuery } from './general.query';
import { GeneralStore } from './general.store';
import { Features, MenuItem } from './submenu.model';

@Injectable({providedIn: 'root'})
export class GeneralService {
   
    constructor(
        private readonly http: HttpClient,
        private readonly generalStore: GeneralStore,
        private readonly generalQuery: GeneralQuery
    ) { }

    public getBuildNumber(): Observable<string> {
        if (this.generalQuery.getAppVersion() != null) {
            return this.generalQuery.selectAppVersion().pipe(first());
        } else {
            return this.http.get(ApiBaseUrl('/application/GetApplicationVersion'), { responseType: 'text' }).pipe(
                tap(result => this.updateAppVersion(result)),
                switchMap(() => this.generalQuery.selectAppVersion().pipe(first()))
            );
        }
    }
    
    public updateMenu(menu: Array<MenuItem>): void {
        this.generalStore.update({ menu });
    }

    public updateAllowedLangForCountry(allowedLangForCountry: Record<Country, Array<Lang>>): void {
        this.generalStore.update({ allowedLangForCountries: allowedLangForCountry });
    }

    public updateFeatures(features: Partial<Features>): void {
        this.generalStore.update({ features });
    }

    public updateSiteConfiguration(siteConfiguration: SiteConfiguration): void {
        this.generalStore.update({ siteConfiguration });
    }

    public updateAppVersion(appVersion: string): void {
        this.generalStore.update({ appVersion });
    }
}