import {Component} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';

@Component({
    selector: 'test-pages',
    templateUrl: './test-pages.component.html',
    styleUrls: ['./test-pages.component.scss']
})
export class TestPagesComponent {
    public form = new UntypedFormGroup({});
    public model = { };
    public fields: Array<FormlyFieldConfig> = [
        {
            key: 'email',
            type: 'button-multi-select',
            props: {
                label: 'Email address',
                placeholder: 'Enter email',
                required: true,
                options: [
                    {value: 'bla', label: 'Bla die we sa as;d asldk ;iasd filasj df;iasd fliasd fliasd fliasdh fliasdh flasdhfh aludshf lausd flausd fluasdh flashd flashd fljasd fljas hf'},
                    {value: 'foo', label: 'Foo sdak asdl '},
                    {value: 'bar', label: 'Bar  '},
                ]
            }
        }
    ];

    public onSubmit(model: any): void{
        console.log(model);
    }
}
