<div class="imputation" id="{{field.key}}">
  <svg width="36" height="36" xmlns="http://www.w3.org/2000/svg"
       (click)="toggleImputation()"
       (mouseenter)="over()"
       (mouseleave)="leave()"
       class="icon">
    <!-- Created with Method Draw - http://github.com/duopixel/Method-Draw/ -->
    <g>
      <title>background</title>
      <rect *ngIf="formControl.value" fill-opacity="0" id="canvas_background" height="38" width="38" y="-1" x="-1"/>
      <g display="none" overflow="visible" y="0" x="0" height="100%" width="100%" id="canvasGrid">
        <rect fill="url(#gridpattern)" stroke-width="0" y="0" x="0" height="100%" width="100%"/>
      </g>
    </g>
    <g>
      <rect id="svg_2" height="14" width="7" y="20.113266" x="2.5" stroke-width="1.5" stroke="#7f7f7f" fill="#7f7f7f"/>
      <rect id="svg_3" height="22" width="7" y="12.11333" x="26" stroke-width="1.5" stroke="#7f7f7f" fill="#7f7f7f"/>
      <rect *ngIf="!formControl.value" id="svg_4" height="31.49975" width="9" y="3.863396" x="13" stroke-width="1.5" stroke="#333" fill="#aaa"/>
      <rect *ngIf="formControl.value" id="svg_4" height="31.49975" width="9" y="3.863396" x="13" stroke-width="1.5" stroke="#333" fill="#eee"/>

    </g>
  </svg>
  <span class="imputation__tooltip" [attr.data-cy]="'imputationTooltip'" [ngClass]="{hover: hovered && !formControl.value}">{{hoverText}}</span>
</div>

