import { IRefreshToken, IToken } from "@app-features/authorize/auth0.model";

export enum LocalStorageKey {
    cookieAgreement = 'allowCookie',
    calculatorTermsAgreed = 'termsAgreed',
    preferredLanguage = 'preferredLang',
    lastUpdateRead = 'updateNr',
    isMedicalProfessional = 'medicalProfessional',
    gracePeriodAnnouncement = 'announcement',
    lifeHfTermsAgreed = 'lifehf-terms',
    identityToken = 'ngStorage-currentUser',
    refreshToken = 'ngStorage-refreshToken',
    authenticationState = 'authenticationState'
}

export class LocalStorageService {
    public static getItem(key: LocalStorageKey): string {
        return localStorage.getItem(key);
    }

    public static getItemAs<T>(key: LocalStorageKey): T | null {
        const item = localStorage.getItem(key);
        return item == null ? null :  JSON.parse(localStorage.getItem(key));
    }

    public static setItem<T>(key: LocalStorageKey, value: T): void {
        if (typeof value === 'string') {
            localStorage.setItem(key, value);
        } else {
            localStorage.setItem(key, JSON.stringify(value));
        }
    }

    public static removeItem(key: LocalStorageKey): void {
        localStorage.removeItem(key);
    }

    public static getCurrentToken(): IToken | null {
        return this.getItemAs<IToken>(LocalStorageKey.identityToken);
    }
    
    public static getCurrentRefreshToken(): IRefreshToken | null {
        return this.getItemAs<IRefreshToken>(LocalStorageKey.refreshToken);
    }
}