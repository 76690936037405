import { Component, HostListener, OnInit, ViewChild, ViewContainerRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FieldWrapper } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, delay, distinctUntilChanged, filter, map } from 'rxjs';
import { PatientDataField, PatientFields, extractFieldFromForm } from '@app-features/calculators/patient-data/patient-data.model';
import { PatientDataQuery } from '@app-features/calculators/patient-data/patient-data.query';

@Component({
    selector: 'cvrm-group-wrapper',
    templateUrl: './group-wrapper.component.html',
    styleUrls: ['./group-wrapper.component.scss']
})
export class GroupWrapperComponent extends FieldWrapper implements OnInit, OnDestroy {
    @ViewChild('fieldComponent', { read: ViewContainerRef, static: true }) public fieldComponent: ViewContainerRef;
    private readonly subscriptions = new Subscription();
    private readonly calculatorId = this.router.url.split('?')[0].split('/').pop() === 'assist' 
        ? 'assistedChoice' 
        : this.router.url.split('?')[0].split('/').pop();

    private get formGroup() : FormGroup {
        if (this.form instanceof FormArray) {
            throw new Error('FormArray not supported');
        }

        return this.form;
    }

    public wrapperLabel = '';
    public description = '';
    public descriptionLink: Array<string> = [];
    public toolTipText: string = '';
    public descriptionLinkEnabled = false;
    public isShowDescription = false;
    public isShowInfo = false;
    public isShowDate = true;


    public isPrefillFeatureOn$ = this.patientDataQuery.selectPrefillFeature();
    public prefillDate$: Observable<string>;
    public isOverriddenDataField$: Observable<boolean>;
    public isReloadDisabled: boolean;
    public prefillInfo$: Observable<{id: string; prefilled: boolean; toolTipText: string; date: string}>;

    constructor(
        private readonly router: Router,
        private readonly translateService: TranslateService,
        private readonly patientDataQuery: PatientDataQuery,
        private readonly cdRef: ChangeDetectorRef) {
        super();
    }

    @HostListener('window:click', ['$event']) public clickOutsideListener(): void {
        this.hideDescription();
        this.hideInfo();
    }

    public ngOnInit(): void {
        this.wrapperLabel = this.props.label;
        this.description = this.props.placeholder;
        this.descriptionLink = this.props.descriptionLink;
        this.descriptionLinkEnabled = !this.props.disabled && (this.descriptionLink ? true : false);
        const patientDataField$ = this.patientDataQuery.selectPatientDataField(this.calculatorId, this.fieldKey);
    
        this.prefillInfo$ = patientDataField$.pipe(
            map(dataField => this.getPrefillInfo(this.props.type, dataField, this.fieldKey)),
        );

        this.isOverriddenDataField$ = patientDataField$.pipe(
            filter(df => df != null),
            delay(0),
            map(df => this.isFieldOverridden(df, this.formGroup)),
            distinctUntilChanged()
        );

        this.subscriptions.add(
            this.getParentValueChange(this.formControl).pipe(delay(0)).subscribe(() => {
                this.descriptionLinkEnabled = !this.props.disabled && (this.descriptionLink ? true : false);

                const field = extractFieldFromForm(this.fieldKey, this.formGroup);
                this.isReloadDisabled = field.disabled && !field.imputed;
            })
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public toggleDescription(): void {
        const isShown = this.isShowDescription;
        setTimeout(() => {
            if (isShown) {
                this.hideDescription();
            } else {
                this.showDescription();
            }

            this.cdRef.detectChanges();
        });
    }

    public toggleInfo(): void {
        const isShown = this.isShowInfo;
        setTimeout(() => {
            if (isShown) {
                this.hideInfo();
            } else {
                this.showInfo();
            }

            this.cdRef.detectChanges();
        });
    }
    public showInfo(): void {
        this.isShowInfo = true;
    }

    public hideInfo(): void {
        this.isShowInfo = false;
    }

    public resetDataToSource(): void {
        const fieldKey = this.fieldKey;
        const updatedField = this.patientDataQuery.getOriginalPatientDataField(this.calculatorId, fieldKey);
        const sharedUnitSelector = this.props.sharedUnitSelector;

        this.model[fieldKey + 'Field'][fieldKey] = updatedField.originalValue;

        if (updatedField.originalUnit != null && 
            (sharedUnitSelector == null || sharedUnitSelector === fieldKey)) {
            this.model[fieldKey + 'Field'][fieldKey + 'Unit'] = updatedField.originalUnit;
        }

        const imputedKey = Object.keys(this.formControl.value)[0];
        if(imputedKey?.includes('Imputed')) {
            const imputedValue: Record<string, boolean> = {};
            imputedValue[imputedKey] = false;
            this.formControl.patchValue(imputedValue);
        }
    
        const valueToPatch: Record<string, PatientFields> = {};
        valueToPatch[fieldKey + 'Group'] = this.model;
        this.formGroup.patchValue(valueToPatch);
    }

    public toDescription(): void {
        this.router.navigate(this.descriptionLink);
    }

    public showDescription(): void {
        this.description = this.translateService.instant(this.props.placeholder);
        this.isShowDescription = true;
    }

    public hideDescription(): void {
        this.isShowDescription = false;
    }

    public get fieldKey(): string {
        return (this.key as string).split('Group')[0];
    }

    private getPrefillInfo(type: string, dataField: PatientDataField, id: string): { id: string; prefilled: boolean; toolTipText: string; date: string } {
        let toolTipText = 'DESCRIPTIONS.DATE.MISSING';
        const date = dataField?.timestamp;
        const prefilled = dataField?.originalValue != null; 

        if (prefilled) {
            switch(type) { 
                case 'condition': { 
                    toolTipText = 'DESCRIPTIONS.DATE.CONDITION'; 
                    break; 
                }
                case 'medication': { 
                    toolTipText = 'DESCRIPTIONS.DATE.MEDICATION'; 
                    break; 
                }
                case 'observation': { 
                    toolTipText = 'DESCRIPTIONS.DATE.OBSERVATION'; 
                    break; 
                }
                case 'patient':
                default: {
                    toolTipText = 'DESCRIPTIONS.DATE.NONE';
                    break; 
                }
            }
        }

        return { id, prefilled, toolTipText, date };
    }

    private getParentValueChange(control: AbstractControl): Observable<unknown> {
        return control.parent ? this.getParentValueChange(control.parent) : control.valueChanges;
    }

    private isFieldOverridden(dataField: PatientDataField, form: FormGroup): boolean {
        if (dataField.originalValue == null) return false;

        const field = extractFieldFromForm(this.fieldKey, form);

        if (field.imputed) {
            return true;
        }

        if (field.value != null && Array.isArray(dataField.originalValue) && Array.isArray(field.value)) {
            return !this.arrayEquals(dataField.originalValue, field.value);
        }
    
        if (dataField.originalValue !== field.value ||
            (dataField.originalUnit != null && dataField.originalUnit !== field.unit)) {
            return true;
        }
        
        return false;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private arrayEquals(array1: Array<any>, array2: Array<any>): boolean {
        return (array1.length === array2.length && array1.every(x => array2.includes(x)));
    }
}
