<div class="fixed-container">
  <div class="error">
    <div class="text">
      <h2 translate>IDLETIMEOUT.REGISTERED.AFTERLOGOUT_MESSAGE</h2>
      <div class="button-container">
        <a routerLink="/authenticate" class="btn-cta" translate>TO_AUTHENTICATION</a>
      </div>
      <div class="button-container">
        <a routerLink="/" class="btn-cta" translate>404.HOME_BUTTON</a>
      </div>
    </div>
    <div class="image">
      <img src="/assets/errorImage.jpg">
    </div>
  </div>
</div>