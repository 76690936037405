import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { GeneralQuery } from '@app-core/general-store/general.query';
import { isValid, IToken } from '@app-features/authorize/auth0.model';
import { AuthorizationService } from '@app-shared/services/authorization.service';
import { LocalStorageService } from '@app-shared/services/local-storage.service';
import { ApiBaseUrl } from '@app-shared/services/url.service';
import { API_SERVICE_OPTIONS, ApiServiceOptions } from '@ortec/soca-web-ui';
import { filter, first, map, mergeMap, Observable, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthenticationHttpInterceptor implements HttpInterceptor {

    constructor(
        private readonly authorizationService: AuthorizationService,
        private readonly generalQuery: GeneralQuery,
        @Inject(API_SERVICE_OPTIONS) protected apiServiceOptions: ApiServiceOptions
    ) { }

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if ((request.url.startsWith(ApiBaseUrl('/api/')) && !request.url.endsWith('/config')) || request.url.startsWith(ApiBaseUrl('/Auth/Logout'))) {
            return this.generalQuery.selectConfigReceived().pipe(
                filter(received => received),
                switchMap(() => this.generalQuery.selectFeatures()),
                map(features => features.requiresAuthentication != null),
                first(),
                mergeMap(requireAuth => {
                    const token = LocalStorageService.getCurrentToken();
                    if (token != null && requireAuth) {
                        return isValid(token)
                            ? next.handle(addBearerToken(request, token))
                            : this.refreshTokenThenHandleRequest(request, next);
                    }
                    
                    return next.handle(request);
                }
                ));
        }

        return next.handle(request);
    }

    private refreshTokenThenHandleRequest(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return this.authorizationService.tokenIsRefreshing.pipe(
            first(),
            mergeMap(isRefreshing => isRefreshing
                ? this.authorizationService.tokenIsRefreshing.pipe(
                    filter(value => value === false),
                    map(() => LocalStorageService.getCurrentToken()))
                : this.authorizationService.loginWithRefreshToken()),
            first(),
            mergeMap(token => next.handle(addBearerToken(request, token)))
        );
    }
}

const addBearerToken = (request: HttpRequest<unknown>, token: IToken): HttpRequest<unknown> => {
    return request.clone({
        setHeaders: {
            Authorization: `Bearer ${token.token}`,
        }
    });
};