import { Environment, EnvironmentName } from "./environment.config";

export const environment: Environment = {
    name: EnvironmentName.Test,
    configuration: {
        apiUrl: 'https://uprevent-test-api.azurewebsites.net',
        messagingParentUrls: [
            'https://app-test.logiqcare.com',
            'https://pilot.logiqcare.com',
            'https://ads.umcutrecht.nl',
            'https://ads-test.umcutrecht.nl',
            'https://app.riva.care',
            'https://rivamedical.com'
        ],

        useAppInsights: true
    },

    requiresAuthenticationConfig: {
        urls: ['https://test.u-prevent.com', 'https://test.u-prevent.nl'],
        b2cConnectionName: 'U-Prevent-Test-B2C',
        b2cAuthorizeUrl: 'https://login-test.u-prevent.com/login-test.u-prevent.com/oauth2/v2.0/authorize',
        b2cLogoutUrl: 'https://login-test.u-prevent.com/login-test.u-prevent.com/oauth2/v2.0/logout',
        b2cClientId: 'b876a3a1-725f-484e-bc8c-bd94ce8b7d25',

        authConfig: {
            clientID: 'HLpzjaLIBIlEyORUmp2wIlYrhzEVujQg',
            domain: 'logiqcare-test.eu.auth0.com',
            responseType: 'code',
            scope: 'openid profile email',
        },
    },

    timeStamp: '1726704874512'
};
