import { UserType } from "../../core/user-store/user.model";

export interface Auth0ErrorResponse {
    error: string;
    error_description: string;
    state: string;
}

export interface Auth0SuccessResponse {
    code: string;
    state: string;
}

export interface TokenRequest {
    grant_type?: string;
    code?: string;
    audience?: string;
    redirect_uri?: string;
    refresh_token?: string;
    client_id?: string;
    metadata?: Record<string, string>
}

export interface TokenResponse {
    access_token?: string;
    token_type?: string;
    expires_in?: number;
    refresh_token?: string;
    error_description?: string;
    userId?: string;
}

export interface IToken {
    token: string;
    expires: string;
    userType: UserType;
    userId?: string;
    organization?: string;
}

export interface IRefreshToken {
    token: string;
    refreshMoment: string;
}

export type Auth0Response = Auth0ErrorResponse | Auth0SuccessResponse;

export const isValid = (token: IToken): boolean => {
    if (!token || !token.expires) {
        return false;
    }
    const expiresAt = JSON.parse(token.expires);
    return new Date().getTime() < expiresAt;
};