import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GeneralQuery } from '@app-core/general-store/general.query';
import { IdleTimeoutService } from '@app-shared/services/idle-timeout.service';
import { LocalStorageKey, LocalStorageService } from '@app-shared/services/local-storage.service';
import { ApiBaseUrl } from '@app-shared/services/url.service';
import { Observable, tap } from 'rxjs';
import { Lang, UpdateUserModel, User, UserType, isLoginUser, matchLanguage } from './user.model';
import { UserQuery } from './user.query';
import { UserStore } from './user.store';

@Injectable({providedIn: 'root'})
export class UserService {

    constructor(
        private readonly userStore: UserStore,
        private readonly userQuery: UserQuery,
        private readonly generalQuery: GeneralQuery,
        private readonly http: HttpClient,
        private readonly idleTimeoutService: IdleTimeoutService
    ) { }

    public hasReadUpdate(): void {
        const user = this.userQuery.getUser();
        const appVersion = this.generalQuery.getAppVersion();

        if (isLoginUser(user)) {
            this.updateUserAndStore({ lastUpdateRead: appVersion });
        } else {
            this.muteReadUpdateNotification(true);            
            this.updateLastUpdateRead(appVersion);
        }
    }

    public declinedToReadUpdate(): void {
        this.muteReadUpdateNotification(true);
    }

    public muteReadUpdateNotification(muteReadUpdateNotification: boolean): void {
        this.userStore.update({ muteReadUpdateNotification });
    }

    public updateLastUpdateRead(lastUpdateRead: string): void {
        LocalStorageService.setItem(LocalStorageKey.lastUpdateRead, lastUpdateRead);

        this.userStore.update({ lastUpdateRead });
    }

    public updateUserProfile(user: Partial<UpdateUserModel>): Observable<User> {
        return this.http.put<User>(ApiBaseUrl('/api/User/UpdateUser'), user).pipe(
            tap(u => this.userStore.update(u))
        );
    }

    public updateTermsConditions(agreeTermsConditions: boolean): void {
        LocalStorageService.setItem(LocalStorageKey.calculatorTermsAgreed, agreeTermsConditions);

        const currentUser = this.userQuery.getUser();
        if (currentUser.type === UserType.Login) {
            this.updateUserAndStore({ agreeTermsConditions });
        } else {
            this.userStore.update({agreeTermsConditions});
        }
    }

    public updateLang(lang: string | Lang): void {        
        const newLang = matchLanguage(lang);
        const currentUser = this.userQuery.getUser();

        if (currentUser.type === UserType.Login && currentUser.preferredLanguage !== newLang) {
            this.updateUserAndStore({ preferredLanguage: newLang });
        } else {
            LocalStorageService.setItem(LocalStorageKey.preferredLanguage, lang);
            this.userStore.update({ preferredLanguage: newLang });
        }
    }

    public updateCookieConsent(cookieConsent: boolean): void {
        LocalStorageService.setItem(LocalStorageKey.cookieAgreement, cookieConsent);
        this.userStore.update({ cookieConsent });
    }

    public deduceLanguage(lang: string | Lang = null): Lang {
        const storedLang = LocalStorageService.getItem(LocalStorageKey.preferredLanguage);
        const initialLang = lang?.match(/en|nl/)
            ? lang
            : location.host.endsWith('.com') ? Lang.English : Lang.Dutch;
        
        const urlParams = new URLSearchParams(window.location.search);
        const urlLang = urlParams.get('lang');
    
        return matchLanguage(urlLang ?? storedLang ?? initialLang);
    }

    public updateLifeHfTerms(agree: boolean): void {
        LocalStorageService.setItem(LocalStorageKey.lifeHfTermsAgreed, agree);
        this.userStore.update({ agreedToLifeHfTerms: agree });
    }

    public getLoginUser(): Observable<User> {
        return this.http.get<User>(ApiBaseUrl('/api/User/GetUser'));
    }

    public updateUserToLogin(user: User): void {
        this.idleTimeoutService.watch();

        this.userStore.update({ 
            ...user,
            type: UserType.Login,
            muteReadUpdateNotification: false
        });
    }

    public updateUserToConnect(parentUrl: string, organizationName: string): void {
        this.idleTimeoutService.watch();

        this.userStore.update({ 
            type: UserType.Connect,
            parentUrl,
            organizationName: organizationName,
            preferredLanguage: this.deduceLanguage(),
            muteReadUpdateNotification: false
        });
    }

    public updateUserToVisitor(): void {
        this.userStore.update({
            type: UserType.Visitor,
            preferredLanguage: this.deduceLanguage(),
            agreedToLifeHfTerms: LocalStorageService.getItemAs<boolean>(LocalStorageKey.lifeHfTermsAgreed) ?? false
        });
    }

    private updateUserAndStore(updateUser: Partial<UpdateUserModel>): void {
        this.http.put(ApiBaseUrl('/api/User/UpdateUser'), updateUser)
            .subscribe({
                next: (user: User) => this.userStore.update(user)
            });
    }
}