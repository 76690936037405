import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

import { FormGroup } from '@angular/forms';
import { CalculatorData, CompositeValue, PatientData, PatientDataField, PatientDataForCalculator, Value, extractFieldFromForm } from './patient-data.model';

export const createInitialState  = (): PatientData => {
    return {
        id: null,
        prefill: false,
        dataForCalculator: []
    };
};

@StoreConfig({name: 'patient-data'})
@Injectable({providedIn: 'root'})
export class PatientDataStore extends Store<PatientData> {
    constructor() {
        super(createInitialState());
    }

    public clearStore(): void {
        this.update({
            id: null,
            prefill: false,
            dataForCalculator: []
        });
    }

    public updateOne(calcData: CalculatorData): void {
        this.updateMany([calcData]);
    }

    public updateFromFormGroup(calculatorId: string, form: FormGroup, patientDataFields: Record<string, PatientDataField>): void {
        const data: PatientDataForCalculator = { 
            calculatorId, 
            data: this.convertFormToPatientDataFields(form, patientDataFields)
        };
        this.updatePatientData([data]);
    }

    public updateMany(list: Array<CalculatorData>): void {
        this.updatePatientData(this.convertToPatientData(list));
    }

    public setPatientDataRequest(patientId: string, originId: string, preload: boolean, token: string): void {
        this.update({
            id: {
                patientId,
                originId,
                preload,
                token
            },
            prefill: true,
            dataForCalculator: []
        });
    }

    private updatePatientData(newData: Array<PatientDataForCalculator>): void {
        const calculatorIds = newData.map(d => d.calculatorId);
        this.update(state => {
            return {
                dataForCalculator: state.dataForCalculator
                    .filter(dataEntry => !calculatorIds.includes(dataEntry.calculatorId))
                    .concat(newData)
            };
        });
    }

    private convertToPatientDataFields(
        calcFields: Record<string, CompositeValue | Value>): Record<string, PatientDataField> {
        const patientDataFields: Record<string, PatientDataField> = {};

        for (const [key, field] of Object.entries(calcFields)) {
            if (this.isCompositeValue(field)) {
                patientDataFields[key] = {
                    originalValue: field.value,
                    overriddenValue: undefined,
                    originalUnit: field.unit,
                    imputed: false,
                    timestamp: field.timestamp
                };
            } else {
                patientDataFields[key] = {
                    originalValue: field,
                    overriddenValue: undefined,
                    imputed: false
                };
            }
        }

        return patientDataFields;
    }

    private isCompositeValue(field: CompositeValue | Value): field is CompositeValue {
        return (field as CompositeValue).value !== undefined;
    }

    private convertToPatientData(list: Array<CalculatorData>): Array<PatientDataForCalculator> {
        return list.map(calcData => 
            ({
                calculatorId: calcData.name,
                data: this.convertToPatientDataFields(calcData.data)
            })
        );
    }

    private convertFormToPatientDataFields(
        form: FormGroup,
        currentFields: Record<string, PatientDataField>): Record<string, PatientDataField> {

        let patientDataFields = { ...currentFields };
        if (!patientDataFields) {
            return {};
        }

        for (const categoryKey of Object.keys(form.controls)) {
            const category = form.controls[categoryKey] as FormGroup;

            for (const groupKey of Object.keys(category.controls)) {
                const key = groupKey.replace('Group', '');
                const field = extractFieldFromForm(key, category);

                patientDataFields = this.setPatientDataField(
                    patientDataFields,
                    key,
                    field.imputed ? 'Imputed' : field.value,
                    field.unit);
            }
        }

        return patientDataFields;
    }

    private setPatientDataField(
        patientDataFields: Record<string, PatientDataField>, key: string, newValue: Value, newUnit: string): Record<string, PatientDataField> {
        const field = patientDataFields[key];

        if (!field) {
            return patientDataFields;
        }

        const newField = { ...field };

        newField.imputed = false;
        newField.overriddenValue = undefined;

        // If value != originalValue, store as overriddenValue. If they are equal, erase the overriddenValue.
        // Javascript array comparison doesn't work so we have to compare arrays manually
        if (newValue === 'Imputed') {
            newField.imputed = true;
        } else if (Array.isArray(newValue)) {
            if (Array.isArray(field.originalValue) && !this.arrayEquals(newValue, field.originalValue)) {
                newField.overriddenValue = newValue;
            }
        } else if (newValue !== field.originalValue) {
            newField.overriddenValue = newValue;
        }

        if (newField.originalUnit === newUnit) {
            newField.overriddenUnit = undefined;
        } else {
            newField.overriddenUnit = newUnit;
        }

        return { ...patientDataFields, [key]: newField };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private arrayEquals(array1: Array<any>, array2: Array<any>): boolean {
        return (array1.length === array2.length && array1.every(x => array2.includes(x)));
    }
}
