import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { SharedModule } from '@app-shared/shared.module';
import { SocaWebUiCommonModule } from '@ortec/soca-web-ui';
import { AUTHENTICATION_SERVICE_OPTIONS } from '@ortec/soca-web-ui/identity';
import { AuthOptions, WebAuth } from 'auth0-js';
import { environment } from 'environments/environment';
import { AuthorizationService } from '../../../shared/services/authorization.service';

@Component({
    selector: 'authApp-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        MatButtonModule,
        SocaWebUiCommonModule,
        MatProgressSpinnerModule
    ]
})

export class LoginComponent implements OnInit {
    private readonly auth0: WebAuth;

    constructor(@Inject(AUTHENTICATION_SERVICE_OPTIONS) authenticationServiceOptions: AuthOptions,
        private readonly route: ActivatedRoute,
        private readonly authorizationService: AuthorizationService) {
        this.auth0 = new WebAuth(authenticationServiceOptions);
    }
    
    public ngOnInit(): void {
        const state = this.randomString(32);
        this.authorizationService.setAuthState(state, { 'returnUrl': this.route.snapshot.queryParams['returnUrl'] ?? '/' });
        this.auth0.authorize({
            connection: environment.requiresAuthenticationConfig.b2cConnectionName,
            state
        });
    }

    /**
     * Generates a random string of a given length, as taken from the auth0.js sdk
     * @param length The length of the string to generate
     */
    private randomString(length: number): string {
        // eslint-disable-next-line
        const bytes = new Uint8Array(length);
        let result: Array<string> = [];
        const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~';

        const cryptoObj = window.crypto;
        if (!cryptoObj) {
            throw new Error('Your browser does not support window.crypto. Please upgrade your browser.');
        }

        const random = cryptoObj.getRandomValues(bytes);

        for (let a = 0; a < random.length; a++) {
            result = [ ...result, charset[random[a] % charset.length]];
        }

        return result.join('');
    }
}