import { FormGroup } from '@angular/forms';
import { OrArray } from '@datorama/akita';

export interface PatientData {
    id: PatientDataRequest;
    prefill: boolean;
    dataForCalculator: Array<PatientDataForCalculator>;
}

export interface PatientDataRequest {
    patientId: string;
    originId: string;
    preload: boolean;
    token: string;
}

export interface PatientDataForCalculator {
    calculatorId: string;
    data: Record<string, PatientDataField>;
}

export interface CompositeValue {
    value: Value;
    unit?: string;
    timestamp?: string; 
}

export interface CalculatorData {
    name: string;
    data: Record<string, CompositeValue | Value>;
}

export interface PatientDataField {
    originalValue: Value;
    overriddenValue: Value | null | undefined; // null represents empty value; undefined represents no override
    originalUnit?: string;
    overriddenUnit?: string;
    imputed: boolean;
    timestamp?: string;
}

export type Value = OrArray<string> | OrArray<number> | OrArray<boolean>;

export type PatientFields = Record<string, Value>;
export type PatientFieldGroups = Record<string, PatientFields | boolean>;
export type PatientFieldCategories = Record<string, PatientFieldGroups>;
export type PatientModel = Record<string, PatientFieldCategories>;

export const getDataForCalculator = (
    patientData: PatientData, calculatorId: string): Record<string, PatientDataField> | undefined => {
    return patientData
        ?.dataForCalculator
        ?.find(dataEntry => dataEntry.calculatorId === calculatorId)
        ?.data;
};

export const extractFieldFromForm = (fieldKey: string, form: FormGroup): { value: Value | null; unit: string; imputed: boolean; disabled: boolean } => {       
    const groupControls = (form.controls[fieldKey + 'Group'] as FormGroup)?.controls;
    const fieldControls = groupControls != null ? (groupControls[fieldKey + 'Field'] as FormGroup)?.controls : null;
    
    const value = fieldControls != null ? (fieldControls[fieldKey] as FormGroup)?.value : null;
    const unit = fieldControls != null ? (fieldControls[fieldKey + 'Unit'] as FormGroup)?.value : null;
    const imputed = groupControls != null ? (groupControls[fieldKey + 'Imputed'] as FormGroup)?.value ?? false : false;
    const disabled = groupControls != null ? groupControls[fieldKey + 'Field']?.disabled ?? false : false;

    return { value, unit, imputed, disabled };
};
