import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { GeneralQuery } from '@app-core/general-store/general.query';

@Component({
    selector: 'cvrm-label',
    templateUrl: './label.component.html',
    styleUrls: ['./label.component.scss']
})
export class LabelComponent implements OnInit {
    public buildNr = this.generalQuery.getAppVersion();
    public buildDate: Date;

    constructor(
        private readonly generalQuery: GeneralQuery
    ) { }

    public ngOnInit(): void {
        this.buildDate = new Date(Number(environment.timeStamp));
    }
}
