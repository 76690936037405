import { Observable } from 'rxjs';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UserQuery } from '@app-core/user-store/user.query';

@Component({
    selector: 'cvrm-vte-predict-banner',
    templateUrl: './vte-predict-banner.component.html',
    styleUrls: ['./vte-predict-banner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VtePredictBannerComponent implements OnInit {
    public lang$: Observable<string>;

    constructor(
        private readonly userQuery: UserQuery
    ) {}

    public ngOnInit(): void {
        this.lang$ = this.userQuery.selectLang();
    }
}
