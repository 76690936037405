import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { UpdateUserModel, userHasCompletedRegistration } from '@app-core/user-store/user.model';
import { UserQuery } from '@app-core/user-store/user.query';
import { UserService } from '@app-core/user-store/user.service';
import { Auth0Response } from '@app-features/authorize/auth0.model';
import { NotificationComponent } from '@app-shared/components/error-notification/error-notification.component';
import { AuthorizationService } from '@app-shared/services/authorization.service';
import { getHomeUrl } from '@app-shared/services/url.service';
import { SharedModule } from '@app-shared/shared.module';
import { BolNotificationService } from '@ortec/bolster/notification';
import { environment } from 'environments/environment';
import { first, mergeMap, of, switchMap } from 'rxjs';

const requiresAuth = environment.requiresAuthenticationConfig;

@Component({
    selector: 'app-authorize',
    templateUrl: './authorize.component.html',
    styleUrls: ['./authorize.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        SharedModule,

        MatProgressSpinnerModule
    ]
})
export class AuthorizeComponent implements OnInit {

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly router: Router,
        private readonly userService: UserService,
        private readonly userQuery: UserQuery,
        private readonly authorizationService: AuthorizationService,
        private readonly bolNotificationService: BolNotificationService
    ) {
    }
  
    public ngOnInit(): void { 
        const queryParams = this.activatedRoute.snapshot.queryParams as Auth0Response;
        const state = queryParams.state;
        const persistedState = this.authorizationService.getAuthState(state);
        this.authorizationService.removeAuthState(state);

        if ("error" in queryParams) {
            if (queryParams.error_description.includes('AADB2C90118')) {
                // This is the unique error code from Azure AD B2C when the user forgot the password (https://learn.microsoft.com/en-us/azure/active-directory-b2c/add-password-reset-policy?pivots=b2c-custom-policy#password-reset-policy-legacy)
                // We redirect the user to the password reset flow
                const redirectUrl = getHomeUrl().replace('://', '%3A%2F%2F');
                window.location.href = `${requiresAuth.b2cAuthorizeUrl}?p=B2C_1A_PASSWORDRESET&client_id=${requiresAuth.b2cClientId}&nonce=defaultNonce&redirect_uri=${redirectUrl}&scope=openid&response_type=code&prompt=login`;
            } else if (!queryParams.error_description.includes('The user has cancelled')) {
                const input = {
                    component: NotificationComponent,
                    data: { 
                        PrimaryErrorMessage: queryParams.error, 
                        SecondaryErrorMessage: queryParams.error_description,
                        StatusCode: 500
                    }
                };

                this.bolNotificationService.negative(input, 'error', '', { duration: 5000 });
            }

            this.backToHome();
        } else {
            if (!('returnUrl' in persistedState)) {
                this.authorizationService.logout();
                this.handleDataLoadError(new HttpErrorResponse({ 'status': 500 }));
                this.backToHome();
                return;
            }

            this.authorizationService.login(queryParams.code).pipe(
                switchMap(() => this.userService.getLoginUser()),
                mergeMap(user => {
                    // this should be triggered only for newly created users
                    if (user.country == null) {
                        const currentUser = this.userQuery.getUser();
                        const updatedUser: UpdateUserModel = {
                            lastUpdateRead: currentUser.lastUpdateRead,
                            agreeTermsConditions: currentUser.agreeTermsConditions
                        };

                        return this.userService.updateUserProfile(updatedUser);
                    }

                    return of(user);
                }),
                first()
            ).subscribe({
                next: (user) => {
                    this.userService.updateUserToLogin(user);

                    if (!userHasCompletedRegistration(user)) {
                        this.router.navigate(['user-profile']);
                    } else { 
                        this.router.navigate([persistedState.returnUrl]);
                    }
                },
                error: (error: HttpErrorResponse) => {
                    this.authorizationService.logout();
                    this.handleDataLoadError(error);
                    this.backToHome();
                }
            });
        }
    }

    private backToHome(): void {
        this.router.navigate(['home']);
    }

    private handleDataLoadError(httpErrorResponse: HttpErrorResponse): void {        
        const input = {
            component: NotificationComponent,
            data: { 
                PrimaryErrorMessage: "Authentication Error", 
                SecondaryErrorMessage: httpErrorResponse.message,
                StatusCode: httpErrorResponse.status
            }
        };

        this.bolNotificationService.negative(input, 'error', '', { duration: 5000 });
    }
}
