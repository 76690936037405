import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonSelectComponent } from '@app-shared/forms/form-fields/button-select/button-select.component';
import { ImputationSwitchComponent } from '@app-shared/forms/form-fields/imputation-switch/imputation-switch.component';
import { InputComponent } from '@app-shared/forms/form-fields/input/input.component';
import { SelectComponent } from '@app-shared/forms/form-fields/select/select.component';
import { SwitchComponent } from '@app-shared/forms/form-fields/switch/switch.component';
import { UnitSelectComponent } from '@app-shared/forms/form-fields/unit-select/unit-select.component';
import { ValidationMessageComponent } from '@app-shared/forms/form-validation/form-validation.component';
import { FieldWrapperComponent } from '@app-shared/forms/form-wrappers/field-wrapper/field-wrapper.component';
import { GroupWrapperComponent } from '@app-shared/forms/form-wrappers/group-wrapper/group-wrapper.component';
import { ResultColumnWrapperComponent } from '@app-shared/forms/form-wrappers/result-column-wrapper/result-column-wrapper.component';
import { ResultRowWrapperComponent } from '@app-shared/forms/form-wrappers/result-row-wrapper/result-row-wrapper.component';
import { IconsModule } from '@app-shared/icons/icons.module';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { ConfigOption, FORMLY_CONFIG, FormlyModule } from '@ngx-formly/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

const formlyValidationConfig = (translate: TranslateService): ConfigOption => {
    return {
        validationMessages: [
            {
                name: 'required',
                message: () => translate.stream('VALIDATIONS.REQUIRED')
            },
            {
                name: 'min',
                message: (_ , f) => translate.stream('VALIDATIONS.MIN', { min: f.props.min })
            },
            {
                name: 'max',
                message: (_, f) => f.props.max < f.props.min 
                    ? translate.stream('VALIDATIONS.NO_VALID_VALUE')
                    : translate.stream('VALIDATIONS.MAX', { max: f.props.max })
            }
        ],
    };
};

@NgModule({
    declarations: [
        SwitchComponent,
        FieldWrapperComponent,
        GroupWrapperComponent,
        ImputationSwitchComponent,
        InputComponent,
        SelectComponent,
        ButtonSelectComponent,
        UnitSelectComponent,
        ValidationMessageComponent,
        ResultRowWrapperComponent,
        ResultColumnWrapperComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormlyBootstrapModule,
        FormlyModule.forRoot({
            types: [
                { name: 'switch', component: SwitchComponent },
                { name: 'imputation-switch', component: ImputationSwitchComponent},
                { name: 'input', component: InputComponent },
                { name: 'number', component: InputComponent },
                { name: 'button-select', component: ButtonSelectComponent },
                { name: 'button-multi-select', component: ButtonSelectComponent },
                { name: 'button-switch', component: ButtonSelectComponent },
                { name: 'button-radio-row', component: ButtonSelectComponent },
                { name: 'select', component: SelectComponent },
                { name: 'multi-select', component: ButtonSelectComponent},
                { name: 'unit-select', component: UnitSelectComponent},
            ],
            wrappers: [
                { name: 'group-wrapper', component: GroupWrapperComponent},
                { name: 'field-wrapper', component: FieldWrapperComponent},
                { name: 'result-row-wrapper', component: ResultRowWrapperComponent},
                { name: 'result-column-wrapper', component: ResultColumnWrapperComponent},
            ],
            extras: {
                checkExpressionOn: 'changeDetectionCheck',
                lazyRender: false,
                resetFieldOnHide: false
            }
        }),
        TranslateModule,
        IconsModule
    ],
    providers: [
        // Display validation message (with translation) for InputComponent
        // Based on example in https://formly.dev/docs/examples/advanced/i18n
        // For other components, validation message is displayed by ValidationMessageComponent
        // After upgrade to formly 6, we cannot use the same approach for InputComponent,
        // since formly always creates an empty validation box when there is validation error on InputComponent
        // Feel free to replace this workaround if you find a better approach
        { provide: FORMLY_CONFIG, multi: true, useFactory: formlyValidationConfig, deps: [TranslateService] }
    ],
    exports: [
        ReactiveFormsModule,
        FormlyBootstrapModule,
        FormlyModule,
        IconsModule
    ]
})
export class FormsModule {
}
