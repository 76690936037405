import {Injectable} from '@angular/core';
import {ReplaySubject, map} from 'rxjs';

@Injectable({providedIn: 'root'})
export class GlobalClickService {
    private readonly subject = new ReplaySubject();

    constructor() {
        document.body.addEventListener('click', (event) => {
            this.subject.next(event.target);
        });
    }

    public clickEvent(elem: HTMLElement): any {
        return this.subject
            .pipe(map((clickElem: HTMLElement) => {
                return this.isEqual(elem, clickElem);
            }));
    }

    private isEqual(targetElem: any, clickedElem: any): any {
        if (targetElem === clickedElem) {
            return true;
        }
        if (clickedElem === null) {
            return false;
        }

        return this.isEqual(targetElem, clickedElem.parentElement);
    }
}
