import { Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {SnackbarContent} from './snackbar.model';

@Component({
    selector: 'cvrm-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent implements OnChanges {
    @Input() public content: SnackbarContent = {text: ''};
    public show = false;

    public ngOnChanges(changes: SimpleChanges): void {
        if (!changes.content.firstChange && !this.show) {
            this.show = true;
            setTimeout(this.hide.bind(this), 3000);
        }
    }

    public hide(): void {
        this.show = false;
    }
}
