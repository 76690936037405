import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable, of, Subscription, map} from 'rxjs';
import {GlobalClickService} from '@app-core/global-click/global-click.service';

@Component({
    selector: 'cvrm-more-info',
    templateUrl: './more-info.component.html',
    styleUrls: ['./more-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoreInfoComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    @ViewChild('icon', {static: true}) public icon: any;

    @Input() public text: any;
    @Input() public width = 400;
    public left = 0;
    public translatedText = '';
    public show = false;
    public subscriptions  = new Subscription();
    public show$: Observable<boolean> = of(this.show);
    public calculatedWith: any;

    constructor(
        private readonly translateService: TranslateService,
        private readonly globalClickService: GlobalClickService,
    ) {}

    public ngOnInit(): void {
        this.subscriptions.add(this.translateService.onLangChange
            .subscribe(() => {
                this.translatedText = this.translateService.instant(this.text || '-');
            }));
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public ngOnChanges(): void {
        setTimeout(() => {
            this.translatedText = this.translateService.instant(this.text || '-');
        });
    }

    public ngAfterViewInit(): void {
        this.show$ =
      this.globalClickService.clickEvent(this.icon.nativeElement)
          .pipe(map((clickedOnElement) => {
              this.show = clickedOnElement && !this.show;
              const maxLeft = this.icon.nativeElement.getBoundingClientRect().left;
              this.calculatedWith = Math.min(this.width, screen.availWidth);
              this.left = -Math.min((this.width / 2) + 5, maxLeft);

              const rightOverflow = maxLeft + this.left + this.calculatedWith - screen.availWidth;

              if (rightOverflow > -10) {
                  this.left = this.left - rightOverflow;
              }

              return clickedOnElement && this.show;
          }));
    }

    public toggleDescription(): void {
    }
}
