<section class="radio">
  <cvrm-button-select-test-form class="small-form" [size]="2"></cvrm-button-select-test-form>
  <cvrm-button-select-test-form class="small-form" [size]="3"></cvrm-button-select-test-form>
  <cvrm-button-select-test-form class="small-form" [size]="4"></cvrm-button-select-test-form>
  <cvrm-button-select-test-form class="small-form" [size]="5"></cvrm-button-select-test-form>
  <cvrm-button-select-test-form class="small-form" [size]="6"></cvrm-button-select-test-form>
  <cvrm-button-select-test-form class="small-form" [size]="7"></cvrm-button-select-test-form>
</section>
<section class="checkbox">
  <cvrm-button-select-test-form class="small-form" [size]="2" [type]="'button-multi-select'"></cvrm-button-select-test-form>
  <cvrm-button-select-test-form class="small-form" [size]="3" [type]="'button-multi-select'"></cvrm-button-select-test-form>
  <cvrm-button-select-test-form class="small-form" [size]="4" [type]="'button-multi-select'"></cvrm-button-select-test-form>
  <cvrm-button-select-test-form class="small-form" [size]="5" [type]="'button-multi-select'"></cvrm-button-select-test-form>
  <cvrm-button-select-test-form class="small-form" [size]="6" [type]="'button-multi-select'"></cvrm-button-select-test-form>
  <cvrm-button-select-test-form class="small-form" [size]="7" [type]="'button-multi-select'"></cvrm-button-select-test-form>
</section>

<section class="wide-radio">
  <cvrm-button-select-test-form  [size]="3"></cvrm-button-select-test-form>
  <cvrm-button-select-test-form  [size]="6" [start]="1"></cvrm-button-select-test-form>
</section>

