import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBaseUrl } from '@app-shared/services/url.service';
import { Observable } from 'rxjs';
import { ContactDto } from './contact.model';

@Injectable({ providedIn: 'root' })
export class ContactService {
    constructor(
        private readonly http: HttpClient
    ) { }

    public send(data: Partial<ContactDto>): Observable<unknown> {
        return this.http.post(ApiBaseUrl('/api/RiskCalculation/contact'), { ...data });
    }
}
