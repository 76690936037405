import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ValueIndicator } from '@app-core/smart-risk-score/models';
import { AutoFillIntake } from '@app-features/calculators/model/autofill-intake-enum.model';
import { IntakeFieldConfig } from '@app-features/calculators/model/formatted-intake.model';
import { TranslateService } from '@ngx-translate/core';
import isEqual from 'lodash/isEqual';
import { Subscription, tap } from 'rxjs';

interface Item extends IntakeFieldConfig {
    abbreviatedValue: string;
}

@Component({
    selector: 'cvrm-situation-table',
    templateUrl: './situation-table.component.html',
    styleUrls: ['./situation-table.component.scss']
})
export class SituationTableComponent implements OnInit, OnChanges, OnDestroy {
    @Input() public collection: Array<IntakeFieldConfig>;
    @Input() public keyName = 'name';
    @Input() public keyValue = 'value';
    @Input() public keyImputedUnit = 'imputedUnit';
    @Input() public keyImputedLowerBound = 'imputedLowerBound';
    @Input() public keyImputedUpperBound = 'imputedUpperBound';
    @Input() public keyUnit: string | undefined;
    @Input() public keyValueIndicator: string | undefined;
    @Input() public isCalculatedImputationsEnabled = false;
	
    private readonly subscriptions = new Subscription();

    public ValueIndicator = ValueIndicator;
    public AutoFillIntake = AutoFillIntake;
    public internalCollection: Array<Item>;
    public displayedIndex = -1;
    public maxLength = 4;
    public imputedResultText: string;

    constructor(private readonly translateService: TranslateService){}

    public ngOnInit(): void {
        this.setImputedResultText();

        this.subscriptions.add(
            this.translateService.onLangChange.pipe(
                tap(() => {
                    this.setImputedResultText();
                })
            ).subscribe()
        );
    }

    public ngOnChanges(): void {
        this.internalCollection = (this.collection || [])
            .reduce((newCollection: Array<IntakeFieldConfig>, row) => {
                if (Array.isArray(row.name) && Array.isArray(row.value)) {
                    const values = row.value;
                    row.name.forEach((name: string, index: number) => {
                        newCollection.push({ ...row, name: index === 0 ? name : `- ${name}`, value: values[index] });
                    });
                } else {
                    newCollection.push(row);
                }

                return newCollection;
            }, [])
            .map((row: any) => {
                let abbreviatedValue;
                if (isNaN(row.value)) {
                    const translatedValue = this.translateService.instant(row.value || '-');
                    abbreviatedValue = isEqual(translatedValue, row.value) ? row.value : `${translatedValue}*`;
                } else {
                    const roundedValue = String(Math.round(row.value));
                    if (String(row.value).length > this.maxLength) {
                        abbreviatedValue = (isEqual(roundedValue, String(row.value))) ? row.value : `${roundedValue}*`;
                    } else {
                        abbreviatedValue = String(row.value);
                    }
                }

                return {...row, abbreviatedValue} as Item;
            });
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public showOnHover(item: Item): boolean {
        return item.imputed || this.isTooLong(item) || this.isAbbreviated(item);
    }

    public isImputed(item: Item): boolean {
        return item.imputed;
    }

    public isTooLong(item: Item): boolean {
        return !item.imputed && (item.abbreviatedValue && item.abbreviatedValue.length > this.maxLength);
    }

    public isAbbreviated(item: Item): boolean {
        return item.abbreviatedValue ? item.abbreviatedValue.endsWith('*') : false;
    }

    public isUnabbreviated(item: Item): boolean {
        return (item.abbreviatedValue ? !item.abbreviatedValue.endsWith('*') : true) && !this.isTooLong(item);
    }

    public showValue(index: number): void {
        if (this.displayedIndex === index) {
            this.hideValue();
        } else {
            this.displayedIndex = index;
        }
    }

    public hideValue(): void {
        this.displayedIndex = -1;
    }

    private setImputedResultText(): void {
        if (this.isCalculatedImputationsEnabled) {
            this.imputedResultText = this.translateService.instant('RESULTS.IMPUTED_PERSONALIZED');
        }
        else {
            this.imputedResultText = this.translateService.instant('RESULTS.IMPUTED');
        }
    }
}
