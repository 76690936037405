<div class="icon-info"><fa-icon icon="info-circle"></fa-icon></div>
<h1 translate>INFORMATION.TECHNICAL_SPECS_TITLE</h1>
<div class="content">
  <p><span translate>INFORMATION.TECHNICAL_SPECS_TEXT</span>:</p>
  <p translate>INFORMATION.OPERATING_SYSTEMS</p>
  <ul>
    <li>Microsoft Windows 8.1 <span translate>INFORMATION.OR_LATER</span></li>
    <li>macOs 10.13 <span translate>INFORMATION.OR_LATER</span></li>
    <li>iOS 9 <span translate>INFORMATION.OR_LATER</span></li>
    <li>Android 8 <span translate>INFORMATION.OR_LATER</span></li>
  </ul>
  <p translate>INFORMATION.BROWSERS</p>
  <ul>
    <li>Firefox (<span translate>INFORMATION.LATEST_VERSION</span>)</li>
    <li>Google Chrome (<span translate>INFORMATION.LATEST_VERSION</span>)</li>
    <li>Safari (<span translate>INFORMATION.LATEST_VERSION</span>)</li>
    <li>Microsoft Edge (<span translate>INFORMATION.LATEST_VERSION</span>)</li>
    <li translate>INFORMATION.ACCEPT_COOKIES</li>
  </ul>
</div>
<cvrm-label></cvrm-label>