import { Component, Input } from '@angular/core';

@Component({
    selector: 'cvrm-selection-tile',
    templateUrl: './selection-tile.component.html',
    styleUrls: ['./selection-tile.component.scss']
})
export class SelectionTileComponent {
    @Input() public color: 'purple' | 'teal' | 'darkblue' | 'pinkpurple' | 'disabled' | 'blue';
    @Input() public label = '';

    public get dataCy(): string {
        const parts = this.label.split('.');

        return parts.length === 0 ? '' : parts[parts.length - 1];
    }
}
