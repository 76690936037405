import { Component } from '@angular/core';
import { GeneralQuery } from '@app-core/general-store/general.query';
import { Site } from '@app-shared/models/site-configuration-enum';
import { Observable } from 'rxjs';

@Component({
    selector: 'cvrm-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent {
    public readonly site$: Observable<string> = this.generalQuery.selectSite();
    public Site = Site;

    constructor(
        private readonly generalQuery: GeneralQuery
    ) { }
}
