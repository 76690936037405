<div class="footer-wave-background footer-wave-{{color}}"></div>
<div class="footer-background footer-wave-{{color}}">
	<a *ngIf="showPrivacyStatement$ | async" [routerLink]="['/privacy-statement']" class="privacy" translate data-cy="footerPrivacyStatement">FOOTER.PRIVACY_NOTICE</a>
	<br>
	<a [routerLink]="['/view-terms']" class="terms" translate data-cy="footerTerms">FOOTER.TERMS_CONDITIONS</a>
	<br>
	<a 
		[routerLink]="(isMdrCertified$ | async) ? '/information' : null" 
		[class]="(isMdrCertified$ | async) ? 'specs' : 'specs-disabled'">
		<span translate data-cy="footerVersion">FOOTER.VERSION</span>: {{appVersion$ | async}}
	</a>
	<p>© ORTEC Copyright</p>
	<p>
	<a href="https://ortec.com/en/industries/healthcare" target="_blank">
		<img src="/assets/Powered-by-ORTEC.png" class="ortec">
	</a>
	</p>
	<br>
	<ng-container *ngIf="isProduction">
		<a *ngIf="(isMdrCertified$ | async)" href="/about/mdr">
			<img src="/assets/ce-mark.png" class="ce-mark">
		</a>
	</ng-container>
</div>