export enum Site {
    Default = "default",
    VtePredict = "vtePredict",
    Unknown = "unknown",
    LifeHf = "lifeHf"
}

export interface SiteConfiguration {
    site: Site;
    mdrCertified: boolean;
}