import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-error-notification',
    templateUrl: './error-notification.component.html',
    styleUrls: ['./error-notification.component.scss']
})
export class NotificationComponent {
    @Input() public PrimaryErrorMessage: string;
    @Input() public SecondaryErrorMessage: string;
    @Input() public StatusCode: string;
}
