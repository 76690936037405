import { Component, OnInit } from '@angular/core';
import { GeneralQuery } from '@app-core/general-store/general.query';
import { CalculatorService } from '@app-features/calculators/calculator.service';
import { Site } from '@app-shared/models/site-configuration-enum';
import { Observable } from 'rxjs';

@Component({
    selector: 'cvrm-calculators-overview',
    templateUrl: './calculators-overview.component.html',
    styleUrls: ['./calculators-overview.component.scss']
})
export class CalculatorsOverviewComponent implements OnInit {
    public readonly site$: Observable<string> = this.generalQuery.selectSite();
    public Site = Site;

    constructor(
        private readonly calculatorService: CalculatorService,
        private readonly generalQuery: GeneralQuery
    ) { }

    public ngOnInit(): void {
        this.calculatorService.setActiveCalculator(null);
    }
}
