import { Injectable } from '@angular/core';
import { LocalStorageKey, LocalStorageService } from '@app-shared/services/local-storage.service';
import { Store, StoreConfig } from '@datorama/akita';
import { UserState, matchLanguage } from './user.model';

export const createInitialUserState = (): Partial<UserState> => ({
    type: null,
    preferredLanguage: matchLanguage(new URLSearchParams(window.location.search).get('lang') ?? LocalStorageService.getItem(LocalStorageKey.preferredLanguage)),
    cookieConsent: LocalStorageService.getItemAs<boolean>(LocalStorageKey.cookieAgreement), 
    lastUpdateRead: LocalStorageService.getItem(LocalStorageKey.lastUpdateRead),
    agreeTermsConditions: LocalStorageService.getItemAs<boolean>(LocalStorageKey.calculatorTermsAgreed),
    muteReadUpdateNotification: true
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user' })
export class UserStore extends Store<UserState> {
    constructor() {
        super(createInitialUserState());
    }
}