import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { UserService } from '@app-core/user-store/user.service';

@Component({
    selector: 'cvrm-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {
    public fullPath: string;
    public title: string;
    public subTitle: string;
    public anchor: string;
    
    constructor(
        private readonly route: ActivatedRoute,
        private readonly viewportScroller: ViewportScroller,
        private readonly userService: UserService
    ) {}

    public ngOnInit(): void {
        this.fullPath = this.getFullPathFromRoot(this.route.snapshot);
        this.anchor = this.route.snapshot.fragment;

        if (this.fullPath === 'about/update') {
            this.userService.hasReadUpdate();
        }
    }

    public updateHeader(event: any): void {
        this.title = event.title;
        this.subTitle = event.subTitle;
        this.viewportScroller.scrollToAnchor(this.anchor);
    }

    private getFullPathFromRoot(snapshot: ActivatedRouteSnapshot): string {
        return snapshot.pathFromRoot
            .filter(pathFromRoot => pathFromRoot.routeConfig)
            .map(pathFromRoot => pathFromRoot.routeConfig.path)
            .join('/');
    }
}
