import {Component, OnDestroy, OnInit} from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Observable, Subscription, map } from 'rxjs';

const SUFFIX = 'Future';

@Component({
    selector: 'cvrm-switch',
    templateUrl: './switch.component.html',
    styleUrls: ['./switch.component.scss']
})
export class SwitchComponent extends FieldType implements OnInit, OnDestroy {
    private readonly subscriptions = new Subscription();
    private origValue: boolean;
    
    public changedClass$: Observable<string>;

    public ngOnInit(): void {
        this.origValue = this.formControl.value || false;
        this.formControl.setValue(this.origValue);

        this.changedClass$ = this.formControl
            .valueChanges
            .pipe(map(() => (this.field.key as string).endsWith(SUFFIX) && this.origValue !== (this.formControl.value || false) ? 'changed' : ''));

        if (this.formControl.parent) {
            this.subscriptions.add(this.formControl.parent.parent.valueChanges
                .subscribe(change => change[`${this.field.key}Imputed`] === true ? this.formControl.setValue(this.origValue, {emitEvent: false}) : null));
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();

    }
}
