import { Injectable } from '@angular/core';
import { Country, Lang } from '@app-core/user-store/user.model';
import { Site, SiteConfiguration } from '@app-shared/models/site-configuration-enum';
import { Store, StoreConfig } from '@datorama/akita';
import { Features, MenuItem } from './submenu.model';

export const FEATURE_LANG_NL = 'Lang-NL';
export const FEATURE_TREATMENT_FORM = 'TreatmentForm';

export interface GeneralState {
    menu: Array<MenuItem>;
    appVersion: string;
    siteConfiguration: SiteConfiguration;
    allowedLangForCountries?: Record<Country, Array<Lang>>;
    features: Features;
}

const createInitialState = (): Partial<GeneralState> => ({
    menu: [],
    appVersion: null,
    siteConfiguration: {
        site: Site.Unknown,
        mdrCertified: false,
    },
    allowedLangForCountries: null,
    features: {
        requiresAuthentication: null,
        calculatedImputations: false,
        prefillPatientData: false,
        preventMultipleTabs: false
    }
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'general' })
export class GeneralStore extends Store<GeneralState> {
    constructor() {
        super(createInitialState());
    }
}