<span class="more-info">
  <span #icon>
    <fa-icon
      icon="info-circle" [attr.data-cy] = "'moreInfoIcon'"
      (click)="toggleDescription()"></fa-icon>
  </span>

<div *ngIf="show$ | async" [innerHTML]="translatedText" [style.width.px]="calculatedWith" [style.left.px]="left" class="more-info__text"
  [attr.data-cy]="'moreInfoText'"></div>
</span>
