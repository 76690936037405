import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { matchUserType, UserType } from "@app-core/user-store/user.model";
import { IRefreshToken, isValid, IToken, TokenResponse } from "@app-features/authorize/auth0.model";
import { UntilDestroy } from "@ngneat/until-destroy";
import { BroadcastService } from "@ortec/soca-web-ui";
import { AUTHENTICATION_SERVICE_OPTIONS, IRefreshTokenStorage } from "@ortec/soca-web-ui/identity";
import { AuthOptions, WebAuth } from "auth0-js";
import { environment } from 'environments/environment';
import jwt_decode from "jwt-decode";
import { BehaviorSubject, catchError, finalize, first, map, Observable, of, switchMap, tap } from "rxjs";
import { LocalStorageKey, LocalStorageService } from "./local-storage.service";
import { ApiBaseUrl, getHomeUrl, getLogOutUrl, getSessionExpiredUrl } from "./url.service";

const requiresAuth = environment.requiresAuthenticationConfig;
const randomIntFromInterval = (min: number, max: number): number => { 
    return Math.floor(Math.random() * (max - min + 1) + min);
};

export enum BroadcastTypes {
    Logout = 'logout',
    Login = 'login',
    ResultTabOpen = 'result-tab-open',
    TokenIsRefreshing = 'token-is-refreshing',
    RescheduleRefreshToken = 'reschedule-refresh-token'
}

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class AuthorizationService {
    private readonly auth0: WebAuth;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private currentScheduledRefreshTokenTimer: any;
    private currentScheduledRefreshToken = '';

    private readonly isAuthenticatedSubject = new BehaviorSubject<boolean>(isValid(LocalStorageService.getCurrentToken()));
    public tokenIsRefreshing = new BehaviorSubject<boolean>(false); 

    constructor(        
        private readonly http: HttpClient,
        private readonly broadcastService: BroadcastService,
        @Inject(AUTHENTICATION_SERVICE_OPTIONS) private readonly authenticationServiceOptions: AuthOptions)
    {
        this.auth0 = new WebAuth(authenticationServiceOptions);
    }

    public isAuthenticated$(): Observable<boolean> {
        return this.isAuthenticatedSubject.asObservable();
    }

    public isAuthenticated(): boolean {
        return this.isAuthenticatedSubject.value;
    }

    public login(code: string): Observable<IToken> {
        const body = new URLSearchParams();
        body.set('grant_type', 'authorization_code');
        body.set('client_id', requiresAuth.authConfig.clientID);
        body.set('code', code);
        body.set('redirect_uri', `${window.location.protocol}//${window.location.host}`);

        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http.post<TokenResponse>(ApiBaseUrl('/Token'), body.toString(), options).pipe(
            tap(() => this.broadcastService.publish({type: BroadcastTypes.Login, payload: ''})),
            map(token => this.doPostRequestItinerary(token))
        );
    }

    public authenticateAsGuest(originId: string = null): Observable<IToken> {
        const body = new URLSearchParams();
        body.set('grant_type', 'guest_token');
        body.set('audience', requiresAuth.authConfig.clientID);

        if (originId != null) {
            body.set('origin_id', originId);
        }

        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http.post<TokenResponse>(ApiBaseUrl('/Token'), body, options).pipe(
            map(token => this.doPostRequestItinerary(token))
        );
    }

    public deleteUser(): Observable<object> {
        return this.http.delete(ApiBaseUrl('/api/User/DeleteUser'));
    }

    public loginWithRefreshToken(): Observable<IToken> {
        this.broadcastService.publish({type: BroadcastTypes.TokenIsRefreshing, payload: ''});
        this.tokenIsRefreshing.next(true);

        const refreshTokenInStorage = LocalStorageService.getCurrentRefreshToken();
        
        const body = new URLSearchParams();
        body.set('grant_type', 'refresh_token');
        body.set('refresh_token', refreshTokenInStorage.token);

        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http.post<TokenResponse>(ApiBaseUrl('/Token'), body.toString(), options).pipe(
            map(token => this.doPostRequestItinerary(token)),
            finalize(() => {
                this.tokenIsRefreshing.next(false);
                this.broadcastService.publish({type: BroadcastTypes.RescheduleRefreshToken, payload: ''});
            }),
            catchError(error => {
                setTimeout(() => {
                    const token = LocalStorageService.getCurrentToken();
                    if (!isValid(token)) {
                        this.handleRefreshTokenError(token);
                    }
                }, 10000);
                
                throw error;
            }),
        );
    }

    public logout(returnUrl: string = null, federated = false): void {
        const body = new URLSearchParams();
        this.http.post(ApiBaseUrl('/Auth/Logout'), body).subscribe();

        this.clearUserSession();        
        const b2c_logout = `${requiresAuth.b2cLogoutUrl}?p=b2c_1a_signup_signin&post_logout_redirect_uri=${returnUrl ?? getHomeUrl()}`;

        this.broadcastService.publish({type: BroadcastTypes.Logout, payload: ''});

        this.auth0.logout({
            returnTo: b2c_logout,
            federated: federated,
            clientID: environment.requiresAuthenticationConfig.authConfig.clientID
        });
    }

    public clearUserSession(): void {
        this.isAuthenticatedSubject.next(false);
        LocalStorageService.removeItem(LocalStorageKey.identityToken);
        LocalStorageService.removeItem(LocalStorageKey.refreshToken);
    }

    public handleRefreshTokenError(token: IToken): void {
        if (token.userType === UserType.Login) {
            this.logout(getLogOutUrl());
        } else {
            this.clearUserSession();
            window.location.replace(getSessionExpiredUrl());
        }
    }

    public tokenIsRefreshingInOtherTab(): void {
        this.tokenIsRefreshing.next(true);
        this.clearTimer();
    }

    public tokenWasRefreshedInOtherTab(): void {
        this.tokenIsRefreshing.next(false);
        this.rescheduleRefreshToken();
    }

    public rescheduleRefreshToken(): void {
        if (isValid(LocalStorageService.getCurrentToken())) {
            this.scheduleRefreshToken(LocalStorageService.getCurrentRefreshToken());
        } else {
            this.tryLoginWithRefreshToken();
        }
    }
    
    public setAuthState(key: string, state: object): void {
        const stateDict = LocalStorageService.getItemAs<{[key: string]: object}>(LocalStorageKey.authenticationState) ?? {};
        
        LocalStorageService.setItem<{[key: string]: object}>(LocalStorageKey.authenticationState, { ...stateDict, [key]: state });
    }

    public getAuthState(key: string): object | undefined {
        const stateDict = LocalStorageService.getItemAs<{[key: string]: object}>(LocalStorageKey.authenticationState) ?? {};

        return key in stateDict ? stateDict[key] : undefined;
    }

    public removeAuthState(key: string): void {
        const stateDict = LocalStorageService.getItemAs<{[key: string]: object}>(LocalStorageKey.authenticationState) ?? {};
        
        LocalStorageService.setItem<{[key: string]: object}>(LocalStorageKey.authenticationState, Object
            .entries(stateDict)
            .filter(x => x[0] !== key)
            .reduce((sum, add) => ({ ...sum, [add[0]]: add[1] }), {}));
    }

    private doPostRequestItinerary(token: TokenResponse): IToken {
        const tokens = this.storeAccessAndRefreshToken(token);
        this.scheduleRefreshToken(tokens.refreshToken);
        this.isAuthenticatedSubject.next(true);

        return tokens.identity;
    }

    private storeAccessAndRefreshToken(response: TokenResponse): { identity: IToken, refreshToken: IRefreshToken } {
        const decoded = jwt_decode(response.access_token) as Record<string, string>;

        const currentUser: IToken = {
            userId: response.userId,
            token: response.access_token,
            userType: matchUserType(decoded['userType']),
            expires: (new Date().getTime() + response.expires_in * 1000).toString(),
            organization: decoded['organizationName']
        };

        LocalStorageService.setItem(LocalStorageKey.identityToken, currentUser);

        const refreshToken: IRefreshTokenStorage = {
            token: response.refresh_token,
            refreshMoment: (new Date().getTime() + response.expires_in * 1000).toString()
        };

        LocalStorageService.setItem(LocalStorageKey.refreshToken, currentUser);

        return { identity: currentUser, refreshToken: refreshToken };
    }

    private scheduleRefreshToken(refreshTokenInStorage: IRefreshToken): void {
        if (refreshTokenInStorage.token != null && refreshTokenInStorage.refreshMoment != null &&
            this.currentScheduledRefreshToken !== refreshTokenInStorage.token) {
            // Add some randomness to the interval to prevent all open tabs from refreshing at the same time
            // The refresh interval is now between 10 and 60 seconds before the token expires
            const timeExpirationInMs = new Date(parseInt(refreshTokenInStorage.refreshMoment, 10)).getTime() - new Date().getTime() - randomIntFromInterval(10000, 60000); 

            if (!isNaN(timeExpirationInMs) && timeExpirationInMs > 0) {
                if (this.currentScheduledRefreshTokenTimer != null) {
                    clearTimeout(this.currentScheduledRefreshTokenTimer);
                }

                this.currentScheduledRefreshTokenTimer = setTimeout(() => this.tryLoginWithRefreshToken(), timeExpirationInMs);
                this.currentScheduledRefreshToken = refreshTokenInStorage.token;
            }
        }
    }

    // the reason we are checking whether the token is refreshing is because the refresh could be triggered by multiple sources
    // the authentication interceptor could trigger a refresh and and it will reset the timer
    // and other open tabs holding multiple counter could also fire and trigger a refresh.
    // using the broadcast service we can inform the other tabs that the refresh has started and when it has completed so they can also reschedule the refresh
    private tryLoginWithRefreshToken(): void {
        this.tokenIsRefreshing.pipe(
            first(),
            switchMap(isRefreshing => isRefreshing ? of(null) : this.loginWithRefreshToken()),
            first()
        ).subscribe();
    }

    private clearTimer(): void {
        if (this.currentScheduledRefreshTokenTimer != null) {
            clearTimeout(this.currentScheduledRefreshTokenTimer);
        }
    }
}