import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRouteSnapshot } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable, first, switchMap, tap } from 'rxjs';
import { ApiBaseUrl } from '@app-shared/services/url.service';
import { CalculatorData, PatientDataField, PatientModel } from './patient-data.model';
import { PatientDataQuery } from './patient-data.query';
import { PatientDataStore } from './patient-data.store';

@Injectable({providedIn: 'root'})
export class PatientDataService {
    constructor(
        private readonly http: HttpClient,
        private readonly patientDataQuery: PatientDataQuery,
        private readonly patientDataStore: PatientDataStore
    ) { }

    public setPatientDataRequest(patientId: string, originId: string, preload: boolean, token: string): void {
        this.patientDataStore.setPatientDataRequest(patientId, originId, preload, token);
    }

    public getPatientData(calculatorId: string, fields: Array<FormlyFieldConfig>): Observable<PatientModel> {
        if (this.patientDataQuery.isDataLoadedForCalculator(calculatorId)) {
            return this.patientDataQuery.selectPatientModel(calculatorId, fields).pipe(first());
        } else {
            const params = new HttpParams()
                .set('patientId', this.patientDataQuery.getPatientDataRequest().patientId)
                .set('originId', this.patientDataQuery.getPatientDataRequest().originId)
                .set('preLoad', this.patientDataQuery.getPatientDataRequest().preload)
                .set('accessToken', this.patientDataQuery.getPatientDataRequest().token)
                .set('calculatorId', calculatorId);

            return this.http.get<Array<CalculatorData>>(ApiBaseUrl('/api/PatientData/GetData'), { params }).pipe(
                first(),
                tap((response) => {
                    this.patientDataStore.updateMany(response);
                }),
                switchMap(() => this.patientDataQuery.selectPatientModel(calculatorId, fields).pipe(first()))
            );
        }
    }

    public storePatientRequestIfPresent(route: ActivatedRouteSnapshot): void {
        const patientId = route.queryParamMap.get('patientId');
        const originId = route.queryParamMap.get('originId');
        const preload = route.queryParamMap.get('preload') === 'true';
        const token = route.queryParamMap.get('token');
        if (originId != null) {
            this.setPatientDataRequest(patientId, originId, preload, token);
        }
    }

    public storePatientDataOverrides(calculatorId: string, form: FormGroup, patientDataFields: Record<string, PatientDataField>): void {
        this.patientDataStore.updateFromFormGroup(calculatorId, form, patientDataFields);
    }

    public clearStore(): void {
        this.patientDataStore.clearStore();
    }
}
