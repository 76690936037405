import { DOCUMENT } from "@angular/common";
import { inject, Injectable, NgZone } from "@angular/core";
import { CookieGroup, OneTrust } from "@app-shared/models/one-trust.model";
import { cache } from "@ortec/soca-web-ui";
import { BehaviorSubject, filter, map, MonoTypeOperatorFunction, Observable } from "rxjs";

declare global {
    // These properties are added to the global window object by OneTrust.
    // Do not change the name of these properties without checking the OneTrust script.
    interface Window {
        OneTrust?: OneTrust;
        OnetrustActiveGroups: string;
    }
}

const DEFAULT_LANGUAGE = 'en';

const ONE_TRUST_COOKIE_SCRIPT_ID = 'one-trust-script';
const ONE_TRUST_NOTICE_SCRIPT_ID = 'otprivacy-notice-script';
const ONE_TRUST_AUTO_BLOCK_SCRIPT_ID = 'ot-autoblock-script';
const ONE_TRUST_USER_SCRIPT_ID = 'onetrust-user-script';


const cookieGroupMapping: Record<string, CookieGroup> = {
    'C0001': CookieGroup.StrictlyNecessaryCookies,
    'C0002': CookieGroup.PerformanceCookies,
    'C0003': CookieGroup.FunctionalCookies,
    'C0004': CookieGroup.TargetingCookies
};

// OneTrust documentation: https://my.onetrust.com/s/article/UUID-d8291f61-aa31-813a-ef16-3f6dec73d643?language=en_US&topicId=0TO1Q000000ssJBWAY
@Injectable({ providedIn: 'root' })
export class OneTrustService {
    private readonly document = inject(DOCUMENT);
    private readonly ngZone = inject(NgZone);
    private readonly languageSubject = new BehaviorSubject<string>(DEFAULT_LANGUAGE);

    private readonly oneTrust$: Observable<OneTrust> = observeDomNode(this.document.body, { childList: true }).pipe(
        map(() => this.window.OneTrust),
        cache()
    );
    
    private readonly activeCookieGroups$: Observable<Array<CookieGroup>> = observeProperty(this.window, 'OnetrustActiveGroups').pipe(
        filter(x => x !== undefined),
        runInsideAngularZone(this.ngZone),
        map((groups) =>
            groups
                .split(',')
                .filter((group) => group in cookieGroupMapping)
                .map((group) => cookieGroupMapping[group]),
        ),
        cache()
    );

    public initialize(isProduction: boolean): void {
        this.loadOneTrustStatementScript(isProduction);
        this.loadOneTrustCookieScript(isProduction);

        if (isProduction) {
            this.loadOneTrustAutoBlockScript();
        }
    }

    public setLanguage(language: string): void {
        this.languageSubject.next(language);
    }

    public selectActiveCookieGroups(): Observable<Array<CookieGroup>> {
        return this.activeCookieGroups$;
    }

    public selectOneTrust(): Observable<OneTrust> {
        return this.oneTrust$;
    }

    private loadOneTrustCookieScript(isProduction: boolean): void {
        const createOneTrustCookieScriptElement = (isProduction: boolean): HTMLScriptElement => {
            const oneTrustScript = this.document.createElement('script');
            oneTrustScript.id = ONE_TRUST_COOKIE_SCRIPT_ID;
            oneTrustScript.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
            oneTrustScript.setAttribute('type', 'text/javascript');
            oneTrustScript.setAttribute('charset', 'UTF-8');
            if (isProduction) {
                oneTrustScript.setAttribute('data-domain-script', '0191078c-e4a7-7c51-8fee-8aa0f264d805');
            } else {
                oneTrustScript.setAttribute('data-domain-script', '0191078c-e4a7-7c51-8fee-8aa0f264d805-test');
            }
      
            return oneTrustScript;
        };

        if (this.document.getElementById(ONE_TRUST_COOKIE_SCRIPT_ID)) {
            return;
        }
  
        const head = this.document.getElementsByTagName('head')[0];

        if (!head) {
            throw Error('Could not find head element in document.');
        }
  
        const oneTrustScript = createOneTrustCookieScriptElement(isProduction);
        head.insertBefore(oneTrustScript, head.firstChild);
    }

    private loadOneTrustStatementScript(isProduction: boolean): void {
        const createOneTrustStatementScriptElement = (isProduction: boolean): HTMLScriptElement => {
            const oneTrustScript = this.document.createElement('script');
            oneTrustScript.id = ONE_TRUST_NOTICE_SCRIPT_ID;
            oneTrustScript.src = 'https://privacyportalde-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js';
            oneTrustScript.setAttribute('type', 'text/javascript');
            oneTrustScript.setAttribute('charset', 'UTF-8');
    
            if (isProduction) {
                oneTrustScript.text = 'settings=eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vcHJpdmFjeXBvcnRhbC1kZS5vbmV0cnVzdC5jb20vcmVxdWVzdC92MS9wcml2YWN5Tm90aWNlcy9zdGF0cy92aWV3cyJ9';
            }
            
            return oneTrustScript;
        };

        if (this.document.getElementById(ONE_TRUST_NOTICE_SCRIPT_ID)) {
            return;
        }
  
        const head = this.document.getElementsByTagName('head')[0];

        if (!head) {
            throw Error('Could not find head element in document.');
        }
  
        const oneTrustScript = createOneTrustStatementScriptElement(isProduction);
        head.insertBefore(oneTrustScript, head.firstChild);
    }

    private loadOneTrustAutoBlockScript(): void {
        const createOneTrustAutoBlockScriptElement = (): HTMLScriptElement => {
            const oneTrustScript = this.document.createElement('script');
            oneTrustScript.id = ONE_TRUST_NOTICE_SCRIPT_ID;
            oneTrustScript.src = 'https://cdn.cookielaw.org/consent/018ee0db-f7fa-7f9f-ae8d-f5b57947b351/OtAutoBlock.js';
            oneTrustScript.setAttribute('type', 'text/javascript');
            oneTrustScript.setAttribute('charset', 'UTF-8');
      
            return oneTrustScript;
        };

        if (this.document.getElementById(ONE_TRUST_AUTO_BLOCK_SCRIPT_ID)) {
            return;
        }
  
        const head = this.document.getElementsByTagName('head')[0];

        if (!head) {
            throw Error('Could not find head element in document.');
        }
  
        const oneTrustScript = createOneTrustAutoBlockScriptElement();
        head.insertBefore(oneTrustScript, head.firstChild);
    }
   
    private get window(): Window {
        return this.document.defaultView;
    }

    // This function cannot be used until a public key is set in OneTrust to sign the token with
    // read this for more information: https://my.onetrust.com/s/article/UUID-69162cb7-c4a2-ac70-39a1-ca69c9340046?language=en_US&topicId=0TO1Q000000ssJBWAY
    private loadOneTrustUserScript(userId: string): void {
        const userScriptElement = (): HTMLScriptElement => {
            const oneTrustScript = this.document.createElement('script');
            oneTrustScript.id = ONE_TRUST_USER_SCRIPT_ID;
            oneTrustScript.setAttribute('type', 'text/javascript');
            oneTrustScript.setAttribute('charset', 'UTF-8');
            oneTrustScript.text = `
                var OneTrust = {
                    dataSubjectParams: {
                        id: ${userId},
                        isAnonymous: false,
                        token : {token}
                    }
                };
            `;
      
            return oneTrustScript;
        };

        const head = this.document.getElementsByTagName('head')[0];

        if (!head) {
            throw Error('Could not find head element in document.');
        }

        head.insertBefore(userScriptElement(), head.firstChild);
    }
}

function observeDomNode(target: Node, options?: MutationObserverInit | undefined): Observable<Array<MutationRecord>> {
    return new Observable((subscriber) => {
        const mutationObserver = new MutationObserver((mutations) => subscriber.next(mutations));
        mutationObserver.observe(target, options);
  
        return () => mutationObserver.disconnect();
    });
}

function runInsideAngularZone<T>(ngZone: NgZone): MonoTypeOperatorFunction<T> {
    return (source$) =>
        new Observable((subcriber) =>
            source$.subscribe({
                next: (value) => ngZone.run(() => subcriber.next(value)),
                error: (error) => ngZone.run(() => subcriber.error(error)),
                complete: () => ngZone.run(() => subcriber.complete()),
            }),
        );
}

export function observeProperty<T, K extends keyof T>(target: T, key: K): Observable<T[K]> {
    interface GetAccessorWithValueStream {
        (): T[K];
        __value$?: Observable<T[K]>;
    }
  
    const propertyDescriptor = getPropertyDescriptor(target, key);
  
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const originalGetAccessor: GetAccessorWithValueStream | undefined = propertyDescriptor?.get;
  
    // If the specified property is already observed return the value stream that was previously created for this property.
    if (originalGetAccessor?.__value$) {
        return originalGetAccessor.__value$;
    }
  
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const originalSetAccessor = propertyDescriptor?.set;
  
    const subject = new BehaviorSubject<T[K]>(target[key]);
    const value$ = subject.asObservable();
  
    const newGetAccessor: GetAccessorWithValueStream = originalGetAccessor
        ? () => originalGetAccessor.call(target)
        : () => subject.getValue();
  
    newGetAccessor.__value$ = value$;
  
    Object.defineProperty(target, key, {
        get: newGetAccessor,
        set(newValue: T[K]): void {
            if (originalSetAccessor !== undefined) {
                originalSetAccessor.call(target, newValue);
            }
  
            const nextValue = originalGetAccessor ? originalGetAccessor.call(target) : newValue;
  
            if (nextValue !== subject.getValue()) {
                subject.next(nextValue);
            }
        },
    });
  
    return value$;
}

function getPropertyDescriptor(target: unknown, key: PropertyKey): PropertyDescriptor | undefined {
    if (target === null || target === undefined) {
        return undefined;
    }
  
    const descriptor = Object.getOwnPropertyDescriptor(target, key);
  
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    return descriptor !== undefined ? descriptor : getPropertyDescriptor(Object.getPrototypeOf(target), key);
}