import { Component, Input } from '@angular/core';

@Component({
    selector: 'cvrm-page-wave-background',
    templateUrl: './page-wave-background.component.html',
    styleUrls: ['./page-wave-background.component.scss'],
})
export class PageWaveBackgroundComponent {
    @Input() public color: string;
}
