import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Route, Router } from '@angular/router';
import { GeneralService } from '@app-core/general-store/general.service';
import { Config, MenuItem } from '@app-core/general-store/submenu.model';
import { Calculator, FieldConfig } from '@app-features/calculators/calculator.model';
import { CalculatorStore } from '@app-features/calculators/calculator.store';
import { ContentComponent } from '@app-features/content/content.component';
import { LifeHFComponent } from '@app-features/lifeHf/lifeHf.component';
import { toScienCrewGuard } from '@app-features/lifeHf/lifeHf.guard';
import { Site } from '@app-shared/models/site-configuration-enum';
import { ApiBaseUrl } from '@app-shared/services/url.service';
import { map, tap } from 'rxjs';

const SYSTEM_ROUTE_PATHS = ['', 'accept-terms', 'view-terms', 'information', 'error', 'authorize', 'user-profile', 'home', 'logout', 'privacy-statement', 'session-expired', 'authenticate', 'tab-closed', '**'];

@Injectable({ providedIn: 'root' })
export class AppInitService {
    public router?: Router;

    constructor(
        private readonly generalService: GeneralService,
        private readonly http: HttpClient,
        private readonly injector: Injector,
        private readonly calculatorStore: CalculatorStore,
    ) {}

    public init(): Promise<void> {
        const router = this.injector.get(Router);

        // Do the following to access the router from Cypress tests and be able to generate a sitemap there.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const win = window as any;
        if (win.Cypress) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            win.AppInitService = this;
            this.router = router;
        }

        return new Promise<void>((resolve,) => {
            this.http
                .get(ApiBaseUrl('/api/RiskCalculation/config'), { observe: 'response' })
                .pipe(
                    tap((response) => {
                        if (typeof response.body === 'string') {
                            throw new Error('No JSON response');
                        }
                    }),
                    map((response) => response.body)
                ).subscribe({
                    next: (config: Config) => {
                        const mergedConfig: Array<Route> = mergeRouteConfigWithMenuConfig(config.router, router.config);
                        this.generalService.updateMenu(filterRoutableMenuItems(config.router, mergedConfig));

                        router.resetConfig(mergedConfig);

                        const calculators = getCalculators(config.siteConfiguration.site);
                        const transformedCalculators = calculators.map((calculator) => {
                            const transformedCalculator = { ...calculator };
                            const transformedFields = (config.calculators || []).find(
                                (configCalculator) => configCalculator.id === calculator.id
                            );
                            transformedCalculator.fields = (transformedFields || {}).fields || transformedCalculator.fields;

                            transformedCalculator.fields = checkValidity(transformedCalculator.fields);

                            return transformedCalculator;
                        });

                        this.calculatorStore.add(transformedCalculators);

                        this.generalService.updateFeatures({
                            calculatedImputations: config.features.calculatedImputations ?? false,
                            prefillPatientData: config.features.prefillPatientData ?? false,
                            preventMultipleTabs: config.features.preventMultipleTabs ?? false,
                            requiresAuthentication: config.features.requiresAuthentication
                        });

                        this.generalService.updateAllowedLangForCountry(config.allowedLangForCountries);
                        this.generalService.updateSiteConfiguration(config.siteConfiguration);

                        resolve();
                    },
                    error: () => {
                        router.navigate(['/error']);
                        // no need to show mdr certified if config is not retrieved
                        this.generalService.updateSiteConfiguration({ site: Site.Unknown, mdrCertified: true });
                        resolve();
                    }
                });
        });
    }
}

const getCalculators = (site: Site): Array<Calculator> => {
    let result: Array<{id: string, title: string, color: string}> = [];
    
    switch (site) {
        case Site.VtePredict:
            result = [
                { id: 'vtePredict', title: 'CALCULATOR_OVERVIEW.VTE_PREDICT.NAME', color: 'teal' }
            ];
            break;
        case Site.Default: 
            result = [
                { id: 'smartScore', title: 'CALCULATOR_OVERVIEW.SMART_RISK_SCORE', color: 'purple' },
                { id: 'smartReach', title: 'CALCULATOR_OVERVIEW.SMART_REACH_MODEL', color: 'purple' },
                { id: 'advanceScore', title: 'CALCULATOR_OVERVIEW.ADVANCE_RISK_SCORE', color: 'darkblue' },
                { id: 'dialModel', title: 'CALCULATOR_OVERVIEW.DIAL_MODEL', color: 'darkblue' },
                { id: 'lifeCvd', title: 'CALCULATOR_OVERVIEW.LIFE_CVD_MODEL', color: 'teal' },
                { id: 'ascvd', title: 'ASCVD', color: 'teal' },
                { id: 'scoreNL', title: 'CALCULATOR_OVERVIEW.SCORE_NL', color: 'teal' },
                { id: 'scoreEULR', title: 'CALCULATOR_OVERVIEW.SCORE_EULR', color: 'teal' },
                { id: 'scoreEUHR', title: 'CALCULATOR_OVERVIEW.SCORE_EUHR', color: 'teal' },
                { id: 'ascvdScore', title: 'CALCULATOR_OVERVIEW.ASCVD_SCORE', color: 'teal' },
                { id: 'smartScoreResearch', title: 'CALCULATOR_OVERVIEW.SMART_RISK_SCORE', color: 'purple' },
                { id: 'score2', title: 'CALCULATOR_OVERVIEW.SCORE_2', color: 'teal' },
                { id: 'score2OP', title: 'CALCULATOR_OVERVIEW.SCORE_2_OP', color: 'teal' },
            ]; 
            break;
        case Site.LifeHf:
            result = [
                { id: 'lifeHf', title: 'CALCULATOR_OVERVIEW.LIFE_HF.CALC_NAME', color: 'teal' }
            ];
            break;
        default:
            throw new Error(`Unknown site configuration: ${site}`);
    }

    return result.map((calculator) => (
        { ...calculator, 
            model: {},
            fields: [],
            treatment: {},
            risks: {},
            improvement: {
                ageRange: [],
                untreatedChance: [],
                treatedChance: [],
                chanceNow: 0,
                yearsFuture: 0,
            }            
        } as Calculator));
};


const mergeRouteConfigWithMenuConfig = (menuConfig: Array<MenuItem>, routeConfig: Array<Route>): Array<Route> => {
    return menuConfig
        .map((item) => createRoute(routeConfig, item))
        .filter((item) => item)
        .concat(routeConfig.filter((route) => SYSTEM_ROUTE_PATHS.indexOf(route.path) > -1));
};

const createRoute = (routeConfig: Array<Route>, item: MenuItem): Route => {
    const copiedRoute = copyRoute(routeConfig, item);

    return item.module !== 'ContentModule' ? copiedRoute : createContentRoute(routeConfig, item, copiedRoute);
};

const createContentRoute = (routeConfig: Array<Route>, item: MenuItem, copiedRoute: Route): Route => {
    const { module, ...route } = item;
    if (route.children) { 
        route.children = route.children.map((child) => {
            if (child.module === 'LifeHFModule') {
                return ({ ...child, component: LifeHFComponent, canActivate: [toScienCrewGuard] });
            }
            
            return (child.module !== 'ContentModule' && copiedRoute?.children) ? copyRoute(copiedRoute.children, child) as MenuItem : ({ ...child, component: ContentComponent });
        });

        return route;
    }

    return { ...route, component: ContentComponent };
};

const copyRoute = (routeConfig: Array<Route>, item: MenuItem): Route => {
    return routeConfig.find((routerItem) => routerItem.path === item.path);
};

const filterRoutableMenuItems = (menuItems: Array<MenuItem>, routes: Array<Route>): Array<MenuItem> => {
    return menuItems.filter((menuItem) => routes.map((route) => route.path).includes(menuItem.path));
};

const checkValidity = (fields: Array<FieldConfig>): Array<FieldConfig> => {
    return fields
        .map((field) => ({ ...field }))
        .map(correctUnitSelector)
        .filter(checkCategory)
        .filter(checkFieldType)
        .filter(checkExistingUnitSelector)
        .filter(checkValidExpression);
};

const correctUnitSelector = (field: FieldConfig, _index: number, self: Array<FieldConfig>): FieldConfig => {
    const unitSelectorName = 'unitSelector';
    const isFieldUnitSelector = self.find(origField => origField.unitSelector === field.id);

    if (isFieldUnitSelector && field.type !== unitSelectorName) {
        console.warn(`field ${field.id} should be of type ${unitSelectorName} instead of ${field.type}`);
        field.type = unitSelectorName;
    }

    return field;
};

const checkFieldType = (field: FieldConfig): boolean => {
    const supportedFieldTypes = ['select', 'multi-select', 'metaSelector', 'unitSelector', 'integer', 'real', 'boolean', 'radio', 'hidden'];

    if (!supportedFieldTypes.includes(field.type)) {
        console.warn(`Type ${field.type} for id: ${field.id} is not supported and removed from the calculator`);

        return false;
    }

    return true;
};

const checkCategory = (field: FieldConfig): boolean => {
    const hasNoCategory = !field.category;

    if (hasNoCategory) {
        console.warn(`${field.id} has no category`);

        return false;
    }

    return true;
};

const checkExistingUnitSelector = (field: FieldConfig, _index: number, self: Array<FieldConfig>): boolean => {
    if (field.unitSelector) {
        const unitSelector = self.find(origField => origField.id === field.unitSelector && origField.category === field.category);
        if (!unitSelector) {
            console.warn(`no matching unit selector for field ${field.id}`);
        }

        return !!unitSelector;
    } else {
        return true;
    }
};

const checkValidExpression = (field: FieldConfig, _index: number, self: Array<FieldConfig>): boolean => {
    if (field.expressions) {
        const allIds = self
            .map(origField => origField.id)
            .concat(self.filter(origField => origField.imputable).map(imputedField => `${imputedField.id}Imputed`))
            .concat(self.filter(origField => origField.units).map(unitField => `${unitField.id}Unit`));
        
        const allowedConstants = ['isFinite', 'null'];

        const invalidExpressions = Object.values(field.expressions)
            .map((expression: string) =>
                expression
                    .split(/!|=|&|\||\s|\(|\)|>|<|:|\?|\[|\]|\{|\}|\,|\+|\*|\-|\//)
                    .reduce((parts, x,) => {
                        const previousPart = parts[parts.length - 1] || '';
                        if (previousPart.startsWith('\'') && (previousPart.length === 1 || !previousPart.endsWith('\''))) {
                            parts[parts.length - 1] = previousPart + x;
                        } else {
                            parts.push(x);
                        }

                        return parts;
                    }, [])
                    .filter(x => x)
                    .filter(x => !x.match(/^[+-]?\d+(\.\d+)?$/))
                    .filter(x => !(x === 'true' || x === 'false'))
                    .filter(x => !x.match(/^'.*'$/))
                    .filter(x => !allIds.includes(x))
                    .filter(x => !allowedConstants.includes(x))
            )
            .filter((arr) => arr.length > 0);

        if (invalidExpressions.length > 0) {
            console.warn(`expressions for ${field.id} contains invalid expression part(s) ${invalidExpressions}`);

            return false;
        } else {
            return true;
        }
    } else {
        return true;
    }
};
