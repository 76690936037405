import { Observable } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserQuery } from '@app-core/user-store/user.query';
import { UserService } from '@app-core/user-store/user.service';

@Component({
    selector: 'cvrm-calculator-terms',
    templateUrl: './calculator-terms.component.html',
    styleUrls: ['./calculator-terms.component.scss'],
})
export class CalculatorTermsComponent implements OnInit {
    public lang$: Observable<string>;
    public isConsentUnanswered$ = this.userQuery.selectCookieConsentUnanswered();
    public showAgreeButton = false;
    public cookieHeight = '0px';

    constructor(
        private readonly userService: UserService,
        private readonly userQuery: UserQuery,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
    ) {}

    public ngOnInit(): void {        
        this.lang$ = this.userQuery.selectLang();
        this.showAgreeButton = this.route.snapshot.routeConfig.path === 'accept-terms';
        const cookieElement = document.getElementById('cookie-consent');
        if (cookieElement) {
            this.cookieHeight = cookieElement.offsetHeight + 'px';
        }
    }

    public agree(): void {
        this.userService.updateTermsConditions(true);
        const returnUrl = this.route.snapshot.queryParams['returnUrl'] ?? '/';

        this.router.navigate([returnUrl]);
    }
}
