import { Component, Input, OnChanges } from '@angular/core';
import { ValueIndicator } from '@app-core/smart-risk-score/models';

@Component({
    selector: 'cvrm-situation-table-print',
    templateUrl: './situation-table-print.component.html',
    styleUrls: ['./situation-table-print.component.scss']
})
export class SituationTablePrintComponent implements OnChanges {
    @Input() public collection: Array<any>;
    @Input() public keyName = 'name';
    @Input() public keyValue = 'value';
    @Input() public keyImputedUnit = 'imputedUnit';
    @Input() public keyUnit: string | undefined;
    @Input() public keyValueIndicator: string | undefined;

    public ValueIndicator = ValueIndicator;
    public internalCollection: Array<any>;
    public displayedIndex = -1;

    public ngOnChanges(): void {
        this.internalCollection = (this.collection || []).reduce((newCollection, row) => {
            if (Array.isArray(row.name)) {
                row.name.forEach((name: any, index: any) => {
                    newCollection.push({ ...row, name: index === 0 ? name : `- ${name}`, value: row.value[index] });
                });
            } else {
                newCollection.push(row);
            }

            return newCollection;
        }, []);
    }

    public showOnHover(item: any): boolean {
        return item.imputed || (item[this.keyValue] && item[this.keyValue].length > 4);
    }

    public showValue(index: any): void {
        if (this.displayedIndex === index) {
            this.hideValue();
        } else {
            this.displayedIndex = index;
        }
    }

    public hideValue(): void {
        this.displayedIndex = -1;
    }
}
