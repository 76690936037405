import { Component, Input } from '@angular/core';
import { Lang } from '@app-core/user-store/user.model';

@Component({
    selector: 'cvrm-new-calculators-banner',
    templateUrl: './new-calculators-banner.component.html',
    styleUrls: ['./new-calculators-banner.component.scss']
})
export class NewCalculatorsBannerComponent {
    @Input() public lang: Lang;

    public readonly Lang = Lang;
}
