<ng-container *ngIf="userType | isAuthenticatedUser">
  <div>
    <a routerLink="/calculators" class="btn-cta" translate="TO_CALCULATORS" data-cy="toCalculators"></a>
  </div>
</ng-container>

<ng-container *ngIf="userType | isVisitorUser">
  <div *ngIf="gracePeriod">
    <a routerLink="/calculators" class="btn-cta" translate="TO_CALCULATORS" data-cy="toCalculators"></a>
  </div>
  <div>
    <a routerLink="/authenticate" class="btn-cta" translate="TO_AUTHENTICATION" data-cy="authenticate"></a>
  </div>
</ng-container>