
  <form [formGroup]="form" >
    <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
    <button type="submit" class="btn btn-default">Submit</button>
  </form>
  <!--  <form [formGroup]="form" (ngSubmit)="onSubmit(model)" class="wide-form">
      <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
      <button type="submit" class="btn btn-default">Submit</button>
    </form>-->
  {{form.value | json}}

