import { StoreConfig, EntityStore, EntityState, ActiveState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Calculator } from './calculator.model';

export interface CalculatorsState extends EntityState<Calculator>, ActiveState {}

@StoreConfig({name: 'calculators'})
@Injectable({providedIn: 'root'})
export class CalculatorStore extends EntityStore<CalculatorsState> {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor() {
        super();
    }

    public updateSelectedGraph(key: string): void {
        this.updateActive(active => ({
            ...active, selectedGraph: key
        }));
    }
}
