import { Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { FormlyFieldProps } from '@ngx-formly/bootstrap/form-field';
import { FieldType } from '@ngx-formly/core';
import { Observable, Subscription, map } from 'rxjs';

const SUFFIX = 'Future';

@Component({
    selector: 'cvrm-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss']
})
export class InputComponent extends FieldType implements OnInit, OnDestroy {
    private readonly subscriptions = new Subscription();
    private originalValue: string = null;

    public changedClass$: Observable<string>;

    // For Firefox scroll
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    @HostListener('DOMMouseScroll', ['$event']) public onMouseWheelFirefox(event: any): void {
        if (event.srcElement.type === 'number') {
            event.srcElement.blur();
        }
    }

    public ngOnInit(): void {
        this.field.props = {
            ...this.props,
            toggleDisabled: false,
            onToggleDisable: this.onToggleDisable.bind(this)
        };

        this.originalValue = this.formControl.value || null;

        this.changedClass$ = this.formControl
            .valueChanges
            .pipe(map(() => (this.field.key as string).endsWith(SUFFIX) && this.originalValue !== this.formControl.value ? 'changed' : ''));

        if (this.formControl.parent) {
            this.subscriptions.add(this.formControl.parent.parent.valueChanges
                .subscribe(change => change[`${this.field.key}Imputed`] === true ? this.formControl.setValue(this.originalValue, {emitEvent: false}) : null));
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public onToggleDisable(props: FormlyFieldProps): void {
        this.field.props = { ...props, disabled: !props.disabled };
        if (this.field.props.disabled) {
            this.formControl.setValue(null);
        } else {
            this.formControl.setValue('');
        }
    }
}
